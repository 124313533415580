import { TokenModel } from '@/shared/domain/TokenModel'
import {
  IFeesController,
  commissionByUserGetter,
  dataToAddUserToBrand,
  dataToGenerateCommission,
  dataToGetCommissionByUser,
  dataToGetInvoices,
  dataToGetPartnerCategories,
  dataToGetUserPartners,
  dataToPayCommissionByUser,
  dataToRemoveUserFromBrand,
  partnerCategoriesGetter,
  userPartnerGetter
} from '../domain/FeesController'

export default class FeesUseCases {
  static async getUserPartners (controller: IFeesController, data: dataToGetUserPartners) : Promise<userPartnerGetter> {
    return await controller.getUserPartners(data)
  }

  static async getPartnerCategories (controller: IFeesController, data: dataToGetPartnerCategories) : Promise<partnerCategoriesGetter> {
    return await controller.getPartnerCategories(data)
  }

  static async addUserToBrand (controller: IFeesController, data: dataToAddUserToBrand) : Promise<boolean> {
    return await controller.addUserToBrand(data)
  }

  static async removeUserFromBrand (controller: IFeesController, data: dataToRemoveUserFromBrand) : Promise<boolean> {
    return await controller.removeUserFromBrand(data)
  }

  static async getFeesData (controller: IFeesController, data: TokenModel) : Promise<userPartnerGetter> {
    return await controller.getFeesData(data)
  }

  static async getInvoices (controller: IFeesController, data: dataToGetInvoices) : Promise<userPartnerGetter> {
    return await controller.getInvoices(data)
  }

  static async getCommissionByUser (controller: IFeesController, data: dataToGetCommissionByUser) : Promise<commissionByUserGetter> {
    return await controller.getCommissionByUser(data)
  }

  static async payCommissionByUser (controller: IFeesController, data: dataToPayCommissionByUser) : Promise<commissionByUserGetter> {
    return await controller.payCommissionByUser(data)
  }

  static async generateCommission (controller: IFeesController, data: dataToGenerateCommission) : Promise<commissionByUserGetter> {
    return await controller.generateCommission(data)
  }
}
