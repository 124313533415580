export default [
  {
    country: 'Afghanistan (‫افغانستان‬‎)',
    code: 'af',
    number: '93',
    mask: '### ### #### #####'
  },
  {
    country: 'Albania (Shqipëri)',
    code: 'al',
    number: '355',
    mask: '### ### #### #####'
  },
  {
    country: 'Algeria (‫الجزائر‬‎)',
    code: 'dz',
    number: '213',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'American Samoa',
    code: 'as',
    number: '1684',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Andorra',
    code: 'ad',
    number: '376',
    mask: '### ### #####'
  },
  {
    country: 'Angola',
    code: 'ao',
    number: '244',
    mask: '### ### ### #####'
  },
  {
    country: 'Anguilla',
    code: 'ai',
    number: '1264',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Antigua and Barbuda',
    code: 'ag',
    number: '1268',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Argentina',
    code: 'ar',
    number: '54',
    mask: '### ##-####-#### #####'
  },
  {
    country: 'Armenia (Հայաստան)',
    code: 'am',
    number: '374',
    mask: '### ###### #####'
  },
  {
    country: 'Aruba',
    code: 'aw',
    number: '297',
    mask: '### #### #####'
  },
  {
    country: 'Australia',
    code: 'au',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Austria (Österreich)',
    code: 'at',
    number: '43',
    mask: '#### ###### #####'
  },
  {
    country: 'Azerbaijan (Azərbaycan)',
    code: 'az',
    number: '994',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Bahamas',
    code: 'bs',
    number: '1242',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Bahrain (‫البحرين‬‎)',
    code: 'bh',
    number: '973',
    mask: '#### #### #####'
  },
  {
    country: 'Bangladesh (বাংলাদেশ)',
    code: 'bd',
    number: '880',
    mask: '#####-###### #####'
  },
  {
    country: 'Barbados',
    code: 'bb',
    number: '1246',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Belarus (Беларусь)',
    code: 'by',
    number: '375',
    mask: '# ### ###-##-## #####'
  },
  {
    country: 'Belgium (België)',
    code: 'be',
    number: '32',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Belize',
    code: 'bz',
    number: '501',
    mask: '### ### #### #####'
  },
  {
    country: 'Benin (Bénin)',
    code: 'bj',
    number: '229',
    mask: '### ### #### #####'
  },
  {
    country: 'Bermuda',
    code: 'bm',
    number: '1441',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Bhutan (འབྲུག)',
    code: 'bt',
    number: '975',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Bolivia',
    code: 'bo',
    number: '591',
    mask: '######## #####'
  },
  {
    country: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    code: 'ba',
    number: '387',
    mask: '### ### #### #####'
  },
  {
    country: 'Botswana',
    code: 'bw',
    number: '267',
    mask: '## ### ### #####'
  },
  {
    country: 'Brazil (Brasil)',
    code: 'br',
    number: '55',
    mask: '(##) #####-#### #####'
  },
  {
    country: 'British Indian Ocean Territory',
    code: 'io',
    number: '246',
    mask: '### #### #####'
  },
  {
    country: 'British Virgin Islands',
    code: 'vg',
    number: '1284',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Brunei',
    code: 'bn',
    number: '673',
    mask: '### #### #####'
  },
  {
    country: 'Bulgaria (България)',
    code: 'bg',
    number: '359',
    mask: '### ### ### #####'
  },
  {
    country: 'Burkina Faso',
    code: 'bf',
    number: '226',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Burundi (Uburundi)',
    code: 'bi',
    number: '257',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Cambodia (កម្ពុជា)',
    code: 'kh',
    number: '855',
    mask: '### ### ### #####'
  },
  {
    country: 'Cameroon (Cameroun)',
    code: 'cm',
    number: '237',
    mask: '# ## ## ## #####'
  },
  {
    country: 'Canada',
    code: 'ca',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Cape Verde (Kabu Verdi)',
    code: 'cv',
    number: '238',
    mask: '### ## ## #####'
  },
  {
    country: 'Caribbean Netherlands',
    code: 'bq',
    number: '599',
    mask: '### #### #####'
  },
  {
    country: 'Cayman Islands',
    code: 'ky',
    number: '1345',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Central African Republic (République centrafricaine)',
    code: 'cf',
    number: '236',
    mask: '## ## ## ### #####'
  },
  {
    country: 'Chad (Tchad)',
    code: 'td',
    number: '235',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Chile',
    code: 'cl',
    number: '56',
    mask: '(#) #### #### #####'
  },
  {
    country: 'China (中国)',
    code: 'cn',
    number: '86',
    mask: '### #### #### #####'
  },
  {
    country: 'Christmas Island',
    code: 'cx',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Cocos (Keeling) Islands',
    code: 'cc',
    number: '61',
    mask: '#### ### ### #####'
  },
  {
    country: 'Colombia',
    code: 'co',
    number: '57',
    mask: '### ### #### #####'
  },
  {
    country: 'Comoros (‫جزر القمر‬‎)',
    code: 'km',
    number: '269',
    mask: '### ## ## #####'
  },
  {
    country: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    code: 'cd',
    number: '243',
    mask: '#### ### ### #####'
  },
  {
    country: 'Congo (Republic) (Congo-Brazzaville)',
    code: 'cg',
    number: '242',
    mask: '## ### #### #####'
  },
  {
    country: 'Cook Islands',
    code: 'ck',
    number: '682',
    mask: '### ### #####'
  },
  {
    country: 'Costa Rica',
    code: 'cr',
    number: '506',
    mask: '#### #### #####'
  },
  {
    country: 'Côte d’Ivoire',
    code: 'ci',
    number: '225',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Croatia (Hrvatska)',
    code: 'hr',
    number: '385',
    mask: '### ### #### #####'
  },
  {
    country: 'Cuba',
    code: 'cu',
    number: '53',
    mask: '## ####### #####'
  },
  {
    country: 'Curaçao',
    code: 'cw',
    number: '599',
    mask: '# ### #### #####'
  },
  {
    country: 'Cyprus (Κύπρος)',
    code: 'cy',
    number: '357',
    mask: '## ###### #####'
  },
  {
    country: 'Czech Republic (Česká republika)',
    code: 'cz',
    number: '420',
    mask: '### ### ### #####'
  },
  {
    country: 'Denmark (Danmark)',
    code: 'dk',
    number: '45',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Djibouti',
    code: 'dj',
    number: '253',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Dominica',
    code: 'dm',
    number: '1767',
    mask: '(###) ###-#### #####'
  },
  {
    country: ' Dominican Republic (Republica Dominicana)',
    code: 'do',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Ecuador',
    code: 'ec',
    number: '593',
    mask: '### ### #### #####'
  },
  {
    country: 'Egypt (‫مصر‬‎)',
    code: 'eg',
    number: '20',
    mask: '#### ### #### #####'
  },
  {
    country: 'El Salvador',
    code: 'sv',
    number: '503',
    mask: '#### #### #####'
  },
  {
    country: 'Equatorial Guinea (Guinea Ecuatorial)',
    code: 'gq',
    number: '240',
    mask: '### ### ### #####'
  },
  {
    country: 'Eritrea',
    code: 'er',
    number: '291',
    mask: '## ### ### #####'
  },
  {
    country: 'Estonia (Eesti)',
    code: 'ee',
    number: '372',
    mask: '#### #### #####'
  },
  {
    country: 'Ethiopia',
    code: 'et',
    number: '251',
    mask: '### ### #### #####'
  },
  {
    country: 'Falkland Islands (Islas Malvinas)',
    code: 'fk',
    number: '500',
    mask: '##### #####'
  },
  {
    country: 'Faroe Islands (Føroyar)',
    code: 'fo',
    number: '298',
    mask: '###### #####'
  },
  {
    country: 'Fiji',
    code: 'fj',
    number: '679',
    mask: '### #### #####'
  },
  {
    country: 'Finland (Suomi)',
    code: 'fi',
    number: '358',
    mask: '### ####### #####'
  },
  {
    country: 'France',
    code: 'fr',
    number: '33',
    mask: '## ## ## ## ## #####'
  },
  {
    country: 'French Guiana (Guyane française)',
    code: 'gf',
    number: '594',
    mask: '#### ## ## ### #####'
  },
  {
    country: 'French Polynesia (Polynésie française)',
    code: 'pf',
    number: '689',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Gabon',
    code: 'ga',
    number: '241',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Gambia',
    code: 'gm',
    number: '220',
    mask: '### #### #####'
  },
  {
    country: 'Georgia (საქართველო)',
    code: 'ge',
    number: '995',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Germany (Deutschland)',
    code: 'de',
    number: '49',
    mask: '###### ####### #####'
  },
  {
    country: 'Ghana (Gaana)',
    code: 'gh',
    number: '233',
    mask: '### ### #### #####'
  },
  {
    country: 'Gibraltar',
    code: 'gi',
    number: '350',
    mask: '######## #####'
  },
  {
    country: 'Greece (Ελλάδα)',
    code: 'gr',
    number: '30',
    mask: '### ### #### #####'
  },
  {
    country: 'Greenland (Kalaallit Nunaat)',
    code: 'gl',
    number: '299',
    mask: '## ## ## #####'
  },
  {
    country: 'Grenada',
    code: 'gd',
    number: '1473',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Guadeloupe',
    code: 'gp',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Guam',
    code: 'gu',
    number: '1671',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Guatemala',
    code: 'gt',
    number: '502',
    mask: '#### #### #####'
  },
  {
    country: 'Guernsey',
    code: 'gg',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Guinea (Guinée)',
    code: 'gn',
    number: '224',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Guinea-Bissau (Guiné Bissau)',
    code: 'gw',
    number: '245',
    mask: '### ### ### #####'
  },
  {
    country: 'Guyana',
    code: 'gy',
    number: '592',
    mask: '### #### #####'
  },
  {
    country: 'Haiti',
    code: 'ht',
    number: '509',
    mask: '## ## #### #####'
  },
  {
    country: 'Honduras',
    code: 'hn',
    number: '504',
    mask: '####-#### #####'
  },
  {
    country: 'Hong Kong (香港)',
    code: 'hk',
    number: '852',
    mask: '#### #### #####'
  },
  {
    country: 'Hungary (Magyarország)',
    code: 'hu',
    number: '36',
    mask: '## ## ### #### #####'
  },
  {
    country: 'Iceland (Ísland)',
    code: 'is',
    number: '354',
    mask: '### #### #####'
  },
  {
    country: 'India (भारत)',
    code: 'in',
    number: '91',
    mask: '###### ##### #####'
  },
  {
    country: 'Indonesia',
    code: 'id',
    number: '62',
    mask: '####-###-### #####'
  },
  {
    country: 'Iran (‫ایران‬‎)',
    code: 'ir',
    number: '98',
    mask: '#### ### #### #####'
  },
  {
    country: 'Iraq (‫العراق‬‎)',
    code: 'iq',
    number: '964',
    mask: '#### ### #### #####'
  },
  {
    country: 'Ireland',
    code: 'ie',
    number: '353',
    mask: '### ### #### #####'
  },
  {
    country: 'Isle of Man',
    code: 'im',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Israel (‫ישראל‬‎)',
    code: 'il',
    number: '972',
    mask: '###-###-#### #####'
  },
  {
    country: 'Italy (Italia)',
    code: 'it',
    number: '39',
    mask: '### ### #### #####'
  },
  {
    country: 'Jamaica',
    code: 'jm',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Japan (日本)',
    code: 'jp',
    number: '81',
    mask: '###-####-#### #####'
  },
  {
    country: 'Jersey',
    code: 'je',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'Jordan (‫الأردن‬‎)',
    code: 'jo',
    number: '962',
    mask: '## #### #### #####'
  },
  {
    country: 'Kazakhstan (Казахстан)',
    code: 'kz',
    number: '7',
    mask: '# (###) ### #### #####'
  },
  {
    country: 'Kenya',
    code: 'ke',
    number: '254',
    mask: '#### ###### #####'
  },
  {
    country: 'Kiribati',
    code: 'ki',
    number: '686',
    mask: '######## #####'
  },
  {
    country: 'Kosovo',
    code: 'xk',
    number: '383',
    mask: '### ### ### #####'
  },
  {
    country: 'Kuwait (‫الكويت‬‎)',
    code: 'kw',
    number: '965',
    mask: '### ##### #####'
  },
  {
    country: 'Kyrgyzstan (Кыргызстан)',
    code: 'kg',
    number: '996',
    mask: '#### ### ### #####'
  },
  {
    country: 'Laos (ລາວ)',
    code: 'la',
    number: '856',
    mask: '### ## ### ### #####'
  },
  {
    country: 'Latvia (Latvija)',
    code: 'lv',
    number: '371',
    mask: '## ### ### #####'
  },
  {
    country: 'Lebanon (‫لبنان‬‎)',
    code: 'lb',
    number: '961',
    mask: '## ### ### #####'
  },
  {
    country: 'Lesotho',
    code: 'ls',
    number: '266',
    mask: '#### #### #####'
  },
  {
    country: 'Liberia',
    code: 'lr',
    number: '231',
    mask: '### ### #### #####'
  },
  {
    country: 'Libya (‫ليبيا‬‎)',
    code: 'ly',
    number: '218',
    mask: '###-####### #####'
  },
  {
    country: 'Liechtenstein',
    code: 'li',
    number: '423',
    mask: '### ### ### #####'
  },
  {
    country: 'Lithuania (Lietuva)',
    code: 'lt',
    number: '370',
    mask: '(#-###) ##### #####'
  },
  {
    country: 'Luxembourg',
    code: 'lu',
    number: '352',
    mask: '### ### ### #####'
  },
  {
    country: 'Macau (澳門)',
    code: 'mo',
    number: '853',
    mask: '#### #### #####'
  },
  {
    country: 'Macedonia (FYROM) (Македонија)',
    code: 'mk',
    number: '389',
    mask: '### ### ### #####'
  },
  {
    country: 'Madagascar (Madagasikara)',
    code: 'mg',
    number: '261',
    mask: '### ## ### ## #####'
  },
  {
    country: 'Malawi',
    code: 'mw',
    number: '265',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Malaysia',
    code: 'my',
    number: '60',
    mask: '###-### #### #####'
  },
  {
    country: 'Maldives',
    code: 'mv',
    number: '960',
    mask: '###-#### #####'
  },
  {
    country: 'Mali',
    code: 'ml',
    number: '223',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Malta',
    code: 'mt',
    number: '356',
    mask: '#### #### #####'
  },
  {
    country: 'Marshall Islands',
    code: 'mh',
    number: '692',
    mask: '###-#### #####'
  },
  {
    country: 'Martinique',
    code: 'mq',
    number: '596',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Mauritania (‫موريتانيا‬‎)',
    code: 'mr',
    number: '222',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Mauritius (Moris)',
    code: 'mu',
    number: '230',
    mask: '#### #### #####'
  },
  {
    country: 'Mayotte',
    code: 'yt',
    number: '262',
    mask: '#### ## ## ### #####'
  },
  {
    country: 'Mexico (México)',
    code: 'mx',
    number: '52',
    mask: '### ### #### #####'
  },
  {
    country: 'Micronesia',
    code: 'fm',
    number: '691',
    mask: '### #### #####'
  },
  {
    country: 'Moldova (Republica Moldova)',
    code: 'md',
    number: '373',
    mask: '#### ## ### #####'
  },
  {
    country: 'Monaco',
    code: 'mc',
    number: '377',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Mongolia (Монгол)',
    code: 'mn',
    number: '976',
    mask: '#### #### #####'
  },
  {
    country: 'Montenegro (Crna Gora)',
    code: 'me',
    number: '382',
    mask: '### ### ### #####'
  },
  {
    country: 'Poppins',
    code: 'ms',
    number: '1664',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Morocco (‫المغرب‬‎)',
    code: 'ma',
    number: '212',
    mask: '####-###### #####'
  },
  {
    country: 'Mozambique (Moçambique)',
    code: 'mz',
    number: '258',
    mask: '## ### #### #####'
  },
  {
    country: 'Myanmar (Burma) (မြန်မာ)',
    code: 'mm',
    number: '95',
    mask: '## ### #### #####'
  },
  {
    country: 'Namibia (Namibië)',
    code: 'na',
    number: '264',
    mask: '### ### #### #####'
  },
  {
    country: 'Nauru',
    code: 'nr',
    number: '674',
    mask: '### #### #####'
  },
  {
    country: 'Nepal (नेपाल)',
    code: 'np',
    number: '977',
    mask: '###-####### #####'
  },
  {
    country: 'Netherlands (Nederland)',
    code: 'nl',
    number: '31',
    mask: '## ######## #####'
  },
  {
    country: 'New Caledonia (Nouvelle-Calédonie)',
    code: 'nc',
    number: '687',
    mask: '##.##.## #####'
  },
  {
    country: 'New Zealand',
    code: 'nz',
    number: '64',
    mask: '### ### #### #####'
  },
  {
    country: 'Nicaragua',
    code: 'ni',
    number: '505',
    mask: '#### #### #####'
  },
  {
    country: 'Niger (Nijar)',
    code: 'ne',
    number: '227',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Nigeria',
    code: 'ng',
    number: '234',
    mask: '#### ### #### #####'
  },
  {
    country: 'Niue',
    code: 'nu',
    number: '683',
    mask: '### #### #####'
  },
  {
    country: 'Norfolk Island',
    code: 'nf',
    number: '672',
    mask: '# ##### #####'
  },
  {
    country: 'North Korea (조선 민주주의 인민 공화국)',
    code: 'kp',
    number: '850',
    mask: '#### ### #### #####'
  },
  {
    country: 'Northern Mariana Islands',
    code: 'mp',
    number: '1670',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Norway (Norge)',
    code: 'no',
    number: '47',
    mask: '### ## ### #####'
  },
  {
    country: 'Oman (‫عُمان‬‎)',
    code: 'om',
    number: '968',
    mask: '#### #### #####'
  },
  {
    country: 'Pakistan (‫پاکستان‬‎)',
    code: 'pk',
    number: '92',
    mask: '#### ####### #####'
  },
  {
    country: 'Palau',
    code: 'pw',
    number: '680',
    mask: '### #### #####'
  },
  {
    country: 'Palestine (‫فلسطين‬‎)',
    code: 'ps',
    number: '970',
    mask: '#### ### ### #####'
  },
  {
    country: 'Panama (Panamá)',
    code: 'pa',
    number: '507',
    mask: '####-#### #####'
  },
  {
    country: 'Papua New Guinea',
    code: 'pg',
    number: '675',
    mask: '#### #### #####'
  },
  {
    country: 'Paraguay',
    code: 'py',
    number: '595',
    mask: '#### ###### #####'
  },
  {
    country: 'Peru (Perú)',
    code: 'pe',
    number: '51',
    mask: '### ### ### #####'
  },
  {
    country: 'Philippines',
    code: 'ph',
    number: '63',
    mask: '#### ### #### #####'
  },
  {
    country: 'Poland (Polska)',
    code: 'pl',
    number: '48',
    mask: '##### ### ### #####'
  },
  {
    country: 'Portugal',
    code: 'pt',
    number: '351',
    mask: '### ### ### #####'
  },
  {
    country: 'Puerto Rico',
    code: 'pr',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Qatar (‫قطر‬‎)',
    code: 'qa',
    number: '974',
    mask: '##### #### #####'
  },
  {
    country: 'Réunion (La Réunion)',
    code: 're',
    number: '262',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Romania (România)',
    code: 'ro',
    number: '40',
    mask: '#### ### ### #####'
  },
  {
    country: 'Russia (Россия)',
    code: 'ru',
    number: '7',
    mask: '8 (###) ###-##-## #####'
  },
  {
    country: 'Rwanda',
    code: 'rw',
    number: '250',
    mask: '#### ### ### #####'
  },
  {
    country: 'Saint Barthélemy',
    code: 'bl',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Saint Helena',
    code: 'sh',
    number: '290',
    mask: '##### #####'
  },
  {
    country: 'Saint Kitts and Nevis',
    code: 'kn',
    number: '1869',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Saint Lucia',
    code: 'lc',
    number: '1758',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Saint Martin (Saint-Martin (partie française))',
    code: 'mf',
    number: '590',
    mask: '#### ## ## ## #####'
  },
  {
    country: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    code: 'pm',
    number: '508',
    mask: '### ## ## #####'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    code: 'vc',
    number: '1784',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Samoa',
    code: 'ws',
    number: '685',
    mask: '## ##### #####'
  },
  {
    country: 'San Marino',
    code: 'sm',
    number: '378',
    mask: '## ## ## ## #####'
  },
  {
    country: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    code: 'st',
    number: '239',
    mask: '### #### #####'
  },
  {
    country: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    code: 'sa',
    number: '966',
    mask: '### ### #### #####'
  },
  {
    country: 'Senegal (Sénégal)',
    code: 'sn',
    number: '221',
    mask: '## ### ## ## #####'
  },
  {
    country: 'Serbia (Србија)',
    code: 'rs',
    number: '381',
    mask: '### ### #### #####'
  },
  {
    country: 'Seychelles',
    code: 'sc',
    number: '248',
    mask: '# ### ### #####'
  },
  {
    country: 'Sierra Leone',
    code: 'sl',
    number: '232',
    mask: '(###) ###### #####'
  },
  {
    country: 'Singapore',
    code: 'sg',
    number: '65',
    mask: '#### #### #####'
  },
  {
    country: 'Sint Maarten',
    code: 'sx',
    number: '1721',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Slovakia (Slovensko)',
    code: 'sk',
    number: '421',
    mask: '#### ### ### #####'
  },
  {
    country: 'Slovenia (Slovenija)',
    code: 'si',
    number: '386',
    mask: '### ### ### #####'
  },
  {
    country: 'Solomon Islands',
    code: 'sb',
    number: '677',
    mask: '## ##### #####'
  },
  {
    country: 'Somalia (Soomaaliya)',
    code: 'so',
    number: '252',
    mask: '# ####### #####'
  },
  {
    country: 'South Africa',
    code: 'za',
    number: '27',
    mask: '### ### #### #####'
  },
  {
    country: 'South Korea (대한민국)',
    code: 'kr',
    number: '82',
    mask: '###-####-#### #####'
  },
  {
    country: 'South Sudan (‫جنوب السودان‬‎)',
    code: 'ss',
    number: '211',
    mask: '#### ### ### #####'
  },
  {
    country: 'Spain (España)',
    code: 'es',
    number: '34',
    mask: '### ## ## ## #####'
  },
  {
    country: 'Sri Lanka (ශ්‍රී ලංකාව)',
    code: 'lk',
    number: '94',
    mask: '### ### #### #####'
  },
  {
    country: 'Sudan (‫السودان‬‎)',
    code: 'sd',
    number: '249',
    mask: '### ### #### #####'
  },
  {
    country: 'Suriname',
    code: 'sr',
    number: '597',
    mask: '###-#### #####'
  },
  {
    country: 'Svalbard and Jan Mayen',
    code: 'sj',
    number: '47',
    mask: '### ## ### #####'
  },
  {
    country: 'Swaziland',
    code: 'sz',
    number: '268',
    mask: '#### #### #####'
  },
  {
    country: 'Sweden (Sverige)',
    code: 'se',
    number: '46',
    mask: '###-### ## #####'
  },
  {
    country: 'Switzerland (Schweiz)',
    code: 'ch',
    number: '41',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Syria (‫سوريا‬‎)',
    code: 'sy',
    number: '963',
    mask: '#### ### ### #####'
  },
  {
    country: 'Taiwan (台灣)',
    code: 'tw',
    number: '886',
    mask: '#### ### ### #####'
  },
  {
    country: 'Tajikistan',
    code: 'tj',
    number: '992',
    mask: '### ## #### #####'
  },
  {
    country: 'Tanzania',
    code: 'tz',
    number: '255',
    mask: '#### ### ### #####'
  },
  {
    country: 'Thailand (ไทย)',
    code: 'th',
    number: '66',
    mask: '### ### #### #####'
  },
  {
    country: 'Timor-Leste',
    code: 'tl',
    number: '670',
    mask: '#### #### #####'
  },
  {
    country: 'Togo',
    code: 'tg',
    number: '228',
    mask: '## ## ## ## #####'
  },
  {
    country: 'Tokelau',
    code: 'tk',
    number: '690',
    mask: '#### #####'
  },
  {
    country: 'Tonga',
    code: 'to',
    number: '676',
    mask: '### #### #####'
  },
  {
    country: 'Trinidad and Tobago',
    code: 'tt',
    number: '1868',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Tunisia (‫تونس‬‎)',
    code: 'tn',
    number: '216',
    mask: '## ### ### #####'
  },
  {
    country: 'Turkey (Türkiye)',
    code: 'tr',
    number: '90',
    mask: '#### ### ## ## #####'
  },
  {
    country: 'Turkmenistan',
    code: 'tm',
    number: '993',
    mask: '# ## ###### #####'
  },
  {
    country: 'Turks and Caicos Islands',
    code: 'tc',
    number: '1649',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Tuvalu',
    code: 'tv',
    number: '688',
    mask: '## #### #####'
  },
  {
    country: 'U.S. Virgin Islands',
    code: 'vi',
    number: '1340',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Uganda',
    code: 'ug',
    number: '256',
    mask: '#### ###### #####'
  },
  {
    country: 'Ukraine (Україна)',
    code: 'ua',
    number: '380',
    mask: '### ### #### #####'
  },
  {
    country: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    code: 'ae',
    number: '971',
    mask: '### ### #### #####'
  },
  {
    country: 'United Kingdom',
    code: 'gb',
    number: '44',
    mask: '##### ###### #####'
  },
  {
    country: 'United States',
    code: 'us',
    number: '1',
    mask: '(###) ###-#### #####'
  },
  {
    country: 'Uruguay',
    code: 'uy',
    number: '598',
    mask: '### ### ### #####'
  },
  {
    country: 'Uzbekistan (Oʻzbekiston)',
    code: 'uz',
    number: '998',
    mask: '# ## ### ## ## #####'
  },
  {
    country: 'Vanuatu',
    code: 'vu',
    number: '678',
    mask: '### #### #####'
  },
  {
    country: 'Vatican City (Città del Vaticano)',
    code: 'va',
    number: '39',
    mask: '### ### #### #####'
  },
  {
    country: 'Venezuela',
    code: 've',
    number: '58',
    mask: '####-####### #####'
  },
  {
    country: 'Vietnam (Việt Nam)',
    code: 'vn',
    number: '84',
    mask: '### ### ## ## #####'
  },
  {
    country: 'Wallis and Futuna (Wallis-et-Futuna)',
    code: 'wf',
    number: '681',
    mask: '## ## ## #####'
  },
  {
    country: 'Western Sahara (‫الصحراء الغربية‬‎)',
    code: 'eh',
    number: '212',
    mask: '####-###### #####'
  },
  {
    country: 'Yemen (‫اليمن‬‎)',
    code: 'ye',
    number: '967',
    mask: '#### ### ### #####'
  },
  {
    country: 'Zambia',
    code: 'zm',
    number: '260',
    mask: '### ####### #####'
  },
  {
    country: 'Zimbabwe',
    code: 'zw',
    number: '263',
    mask: '### ### #### #####'
  },
  {
    country: 'Åland Islands',
    code: 'ax',
    number: '358',
    mask: '### ####### #####'
  }
]
