









import { EventBus } from '@/eventBus'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'

export default {
  name: 'WebChannelConfig',
  methods: {
    goToConfigForm () {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.WIDGET_TESSERACT)
    }
  }
}
