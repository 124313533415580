import link from '@/utils/links'
import { mapActions, mapState, mapGetters } from 'vuex'
import { EventBus } from '@/eventBus'
import operation from '@/utils/operations'
import EmitToast from '@/utils/EmitToast'
import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import TeamMemberForm from '../TeamMemberForm/TeamMemberForm.vue'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import AuthService from '@/services/AuthService'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { dataToDeleteTeamMember, dataToTransferOwnership, dataToUpdateTeamMember, teamMemberCreate } from '../../../domain/TeamController'
import TeamUseCases from '../../../application/TeamUseCases'
import TeamController from '../../controllers/Team.controller'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import Colors from '@/utils/Colors'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
export default {
  components: {
    TeamMemberForm,
    KeybeAvatar,
    KBSwitch,
    KeybeButton,
    KeybeIcon
  },
  props: {
    updateUser: {
      type: Object
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdmin']),
    ...mapGetters('AppStore', ['isKeybeApp']),
    closerText (): string {
      return this.updateInput.isCloser ? this.$t('removeAsCloser') : this.$t('addAsCloser')
    },
    closerButtonColor (): string {
      if (this.$vuetify.theme.dark) {
        return this.updateInput.isCloser ? Colors.inputDark : Colors.kbGreen
      }
      return this.updateInput.isCloser ? Colors.inputLight : Colors.kbGreen
    },
    closerButtonTextColor (): string {
      if (this.$vuetify.theme.dark) {
        return this.updateInput.isCloser ? Colors.white : Colors.black
      }
      return this.updateInput.isCloser ? Colors.black : Colors.black
    },
    isOwner (): boolean {
      return AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
    },
    buttonColor (): string {
      return this.$vuetify.theme.dark ? Colors.inputDark : Colors.inputLight
    },
    buttonTextColor (): string {
      return this.$vuetify.theme.dark ? Colors.white : Colors.black
    },
    userPhone (): string {
      return this.updateUser.phone && this.updateUser.phone.length > 5 ? `+${this.updateUser.countryCode} ${this.updateUser.phone}` : ''
    },
    userName (): string {
      return `${this.updateUser.name || ''} ${this.updateUser.lastName || ''}`
    },
    updateDataMessages () {
      const messages = []
      if (this.user.uuid === this.updateInput.uuid) {
        if (this.phoneChanged || this.emailChanged || this.typeChanged) messages.push('dataLogoutWarning')
        if (this.typeChanged) messages.push('userTypeChangeWarning')
      }
      return messages
    },
    isLoggedIn () {
      return this.updateUser && this.user.uuid === this.updateUser.uuid
    },
    canEditUser () {
      if (this.userUpdate) {
        if (this.updateUser?.ua_role) {
          return ((this.isSuperAdmin && this.updateUser.uuid !== this.user.uuid) &&
               (this.isSuperAdmin && this.updateUser.ua_role !== AdminTypes.SUPER_ADMIN)) ||
               ((this.isAdmin && this.updateUser.uuid !== this.user.uuid) &&
               (this.isAdmin && this.updateUser.ua_role !== AdminTypes.ADMIN && this.updateUser.ua_role !== AdminTypes.SUPER_ADMIN))
        } else {
          return ((this.isSuperAdmin && this.updateUser.uuid !== this.user.uuid) &&
               (this.isSuperAdmin && this.updateUser.type !== AdminTypes.SUPER_ADMIN)) ||
               ((this.isAdmin && this.updateUser.uuid !== this.user.uuid) &&
               (this.isAdmin && this.updateUser.type !== AdminTypes.ADMIN && this.updateUser.type !== AdminTypes.SUPER_ADMIN))
        }
      } else return true
    },
    showInactiveToggle () {
      return (this.user.type === AdminTypes.SUPER_ADMIN ||
             this.user.type === AdminTypes.ADMIN) &&
             this.user.uuid !== this.updateUser.uuid
    },
    userType () {
      if (this.isOwner && this.isLoggedIn) return AdminTypes.OWNER
      return this.updateUser?.ua_role ? this.updateUser?.ua_role : this.updateUser?.type
    },
    userIcon (): string {
      switch (this.updateUser.type) {
        case AdminTypes.SUPER_ADMIN:
          return KeybeIconType.SUPERADMIN_USER
        case AdminTypes.ADMIN:
          return this.isOwner && this.isLoggedIn ? KeybeIconType.SINGLE_SPARKLE : KeybeIconType.ADMIN_USER
        case AdminTypes.COORDINATOR:
          return KeybeIconType.COORDINATOR_USER
        case AdminTypes.AGENT:
          return KeybeIconType.AGENT_USER
      }
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    this.oldValues = structuredClone(this.updateUser)
    this.updateInput = {
      name: this.updateUser.name,
      lastName: this.updateUser.lastName,
      country: this.updateUser.country,
      countryCode: this.updateUser.countryCode,
      email: this.updateUser.email,
      type: this.updateUser.ua_role,
      isSuperAdmin: this.updateUser.isSuperAdmin,
      uuid: this.updateUser.uuid,
      appUUID: this.selectedApp?.uuid || '',
      administratorId: this.updateUser.administratorId,
      phone: this.updateUser.phone,
      isCloser: this.updateUser.isCloser,
      address_country: this.updateUser?.address_country || '',
      address_city: this.updateUser?.address_city || '',
      address_province: this.updateUser?.address_province || '',
      address_zone: this.updateUser?.address_zone || '',
      birthday: this.updateUser?.birthday || ''
    }
  },
  data () {
    return {
      updateInput: {},
      oldValues: {},
      active: false,
      loading: false,
      phoneChanged: false,
      emailChanged: false,
      typeChanged: false,
      loadingDelete: false,
      teamController: new TeamController(),
      activityLogsController: new ActivityLogsController(),
      appsController: new AppsController(),
      loadingCloser: false,
      AdminTypes,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    ...mapActions('UserStore', ['logout']),
    async toggleCloser (): Promise<void> {
      this.loadingCloser = true
      this.updateInput.isCloser = this.updateInput?.isCloser ? 0 : 1
      const data: dataToUpdateTeamMember = {
        uuid: this.updateUser.uuid,
        token: this.token,
        updateInput: {
          isCloser: this.updateInput.isCloser,
          uuid: this.updateUser.uuid
        }
      }

      const response: teamMemberCreate = await TeamUseCases.updateTeamMember(this.teamController, data)
      if (response.status) {
        EventBus.$emit('toast', 'success', this.$t('adminUpdated'))
        await this.writeActivityLogCloser()
      } else {
        EmitToast.emitErrors(response)
      }

      setTimeout(() => {
        this.loadingCloser = false
      }, 600)
    },
    async update (): Promise<void> {
      try {
        this.loading = true
        let changeToOwner: boolean = false

        Object.keys(this.updateInput).forEach((key: string) => {
          if (!this.updateInput[key] && !this.updateUser[key]) delete this.updateInput[key]
        })
        if (this.updateInput.type === AdminTypes.OWNER) {
          this.updateInput.type = AdminTypes.ADMIN
          this.updateInput.userType = AdminTypes.ADMIN
          changeToOwner = true
        }
        delete this.updateInput.password
        if (!this.updateInput.name || !this.updateInput.lastName) {
          EventBus.$emit('toast', 'error', this.$t('nameRequired'))
          this.loading = false
          return
        } else if (!this.updateInput.email) {
          EventBus.$emit('toast', 'error', this.$t('invalidEmailAddress'))
          this.loading = false
          return
        } else if (!operation.validateEmail(this.updateInput.email)) {
          EventBus.$emit('toast', 'error', this.$t('invalidEmailAddress'))
          this.loading = false
          return
        }

        const data: dataToUpdateTeamMember = {
          uuid: this.updateUser.uuid,
          token: this.token,
          updateInput: this.updateInput
        }

        const response: teamMemberCreate = await TeamUseCases.updateTeamMember(this.teamController, data)

        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('adminUpdated'))
          await this.writeActivityLog()
          if (this.updateUser.uuid === this.user.uuid) {
            this.$store.commit('UserStore/SET_KEYBE_USER', response.data)
            if (this.emailChanged || this.typeChanged || this.phoneChanged) this._logout()
          }
          if (changeToOwner) {
            await this.transferOwner()
          }
          this.$emit('closeUpdate')
        } else {
          EmitToast.emitErrors(response)
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    async transferOwner (): Promise<void> {
      const data: dataToTransferOwnership = {
        userIdNew: this.updateUser.userId,
        appId: this.selectedApp?.uuid || '',
        token: this.token
      }
      const response = await TeamUseCases.transferOwnership(this.teamController, data)
      if (response.status) this._logout()
    },
    async writeActivityLogCloser (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.TEAM,
          action: ActivityActionTypeEnum.TEAM_MEMBER_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.IS_CLOSER,
            newValue: this.updateUser.isCloser ? CDPFieldsEnums.INACTIVE : CDPFieldsEnums.ACTIVE,
            oldValue: this.updateUser.isCloser ? CDPFieldsEnums.ACTIVE : CDPFieldsEnums.INACTIVE
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async writeActivityLog (): Promise<void> {
      for (let i = 0; i < Object.keys(this.updateInput).length; i++) {
        const key = Object.keys(this.updateInput)[i]
        if (
          this.updateInput[key] !== this.oldValues[key] &&
          key !== CDPFieldsEnums.PASSWORD &&
          key !== CDPFieldsEnums.CREATED_AT &&
          key !== CDPFieldsEnums.UPDATED_AT &&
          key !== CDPFieldsEnums.APP_UUID
        ) {
          const data: dataToWriteActivityLog = {
            token: this.token,
            updateInput: {
              appUUID: this.selectedApp?.uuid,
              module: ActivityLogModuleTypeEnum.TEAM,
              action: ActivityActionTypeEnum.TEAM_MEMBER_UPDATE,
              user: {
                id: this.user.uuid,
                name: this.user.name + ' ' + this.user.lastName
              },
              payload: {
                field: key,
                newValue: this.updateInput[key],
                oldValue: this.updateUser[key]
              }
            }
          }
          if (this.updateInput[key] && this.updateUser[key]) {
            await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
          }
        }
      }
    },
    async deleteMember (): Promise<void> {
      try {
        this.loadingDelete = true
        const data: dataToDeleteTeamMember = {
          uuid: this.updateUser.uuid,
          token: this.token,
          appId: this.selectedApp?.uuid || ''
        }
        const response: teamMemberCreate = await TeamUseCases.deleteTeamMember(this.teamController, data)
        if (response.status) {
          EmitToast.emitSuccess(this.$t('teamMemberDeleted'))
          setTimeout(() => {
            this.$emit('closeUpdate')
          }, 300)
        } else EmitToast.emitErrors(this.$t(response.message))
        this.loadingDelete = false
      } catch (e) {
        console.error(e)
      }
    },
    async _logout () {
      await PushNotificationsController.setOneSignalStatus({ active: false })
      await AuthService.logout()
      this.logout()
      await this.$router.push(link.signin)
    }
  }
}
