import { render, staticRenderFns } from "./TemplateComposer.vue?vue&type=template&id=08b56b34&scoped=true&"
import script from "./TemplateComposer.vue?vue&type=script&lang=ts&"
export * from "./TemplateComposer.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateComposer.vue?vue&type=style&index=0&id=08b56b34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b56b34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
