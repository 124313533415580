import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybePhone from '@/modules/DesignSystem/infrastructure/components/KeybePhone/KeybePhone.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { KeybeTextTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeTextTypeEnum'
import { citiesGetter, countriesGetter, dataToGetCities, dataToGetCountries, dataToGetStates, statesGetter, timezonesGetter } from '../ui/details/sections/Location/domain/LocationController'
import { mapState, mapActions } from 'vuex'
import LocationUseCases from '../ui/details/sections/Location/application/LocationUseCases'
import LocationController from '../ui/details/sections/Location/infrastructure/controllers/Location.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeDate from '@/modules/DesignSystem/infrastructure/components/KeybeDate/KeybeDate.vue'
import { formattedPhone } from '@/modules/DesignSystem/domain/controllers/KeybePhoneController'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import { Languages } from '@/components/shared/enums/Languages'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import UserUseCases from '@/modules/Config/GeneralUserConfiguration/application/UserUseCases'
import { dataToUpdateUser, dataToUploadImage, userGetterError, userImageUpdater } from '@/modules/Config/GeneralUserConfiguration/domain/UserController'
import UserController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/User.controller'
import { debounce } from 'lodash'
import { LoggedUser } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '../enums/icons/KeybeIconType'
import KeybeLoader from '@/shared/infrastructure/ui/Loader.vue'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import { dataToGetTermsByTeamMember } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { Country } from '../ui/details/sections/Location/domain/Location'
import { appUpdater, dataToUpdateApp } from '@/modules/Apps/domain/AppsController'
import EmitToast from '@/utils/EmitToast'
export default {
  components: {
    KeybeAvatar,
    KeybeText,
    KeybeSelect,
    KeybePhone,
    KeybeAutocomplete,
    KeybeDate,
    KBCheck,
    KeybeButton,
    KeybeIcon,
    KeybeLoader
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'language']),
    ...mapState('UserStore', ['token', 'user']),
    agreementLink (): string {
      const labelMap: {[key: string]: string} = {
        [Languages.ES]: `${process.env.VUE_APP_KEYBE_CO_URL}legales/#acuerdo-de-servicio`,
        [Languages.EN]: `${process.env.VUE_APP_KEYBE_US_URL}legal/#service-agreement`
      }
      return labelMap[this.language] || 'https://keybe.ai/legales/#acuerdo-de-servicio'
    },
    languages (): { name: string, value: string }[] {
      return [
        { name: this.$t(Languages.EN), value: Languages.EN },
        { name: this.$t(Languages.ES), value: Languages.ES },
        { name: this.$t(Languages.PT), value: Languages.PT }
      ]
    },
    disabledButton (): boolean {
      console.log(!this.brandName,
        !this.brandEmail,
        !this.brandPhone,
        !this.brandDefaultCountryCode,
        !this.brandCountry,
        !this.brandTimezone,
        !this.brandLanguage,
        !this.brandPhoto)
      const disableOwner: boolean = this.isOwner && (
        !this.brandName ||
        !this.brandEmail ||
        !this.brandPhone ||
        !this.brandDefaultCountryCode ||
        !this.brandCountry ||
        !this.brandTimezone ||
        !this.brandLanguage ||
        !this.brandPhoto
      )
      const disable: boolean = !this.name ||
      !this.lastName ||
      !this.email ||
      !this.formattedPhone ||
      !this.valid ||
      !this.selectedCountry ||
      !this.selectedState ||
      !this.selectedCity ||
      !this.zone ||
      !this.birthdate ||
      !this.terms ||
      !this.photo ||
      !this.phone

      return disable || disableOwner
    }
  },
  mounted () {
    this.loading = true
    this.isOwner = AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
    this.getCountries()
    this.getCountriesBrand()
    this.getTimezones()
    this.getCountryCodes()
    this.setInitial()
    setTimeout(() => {
      this.loading = false
    }, 600)
  },
  data () {
    return {
      KeybeTextTypeEnum,
      CDPFieldsEnums,
      KeybeIconType,
      countries: [],
      states: [],
      cities: [],
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      countrySearch: '',
      stateSearch: '',
      citySearch: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      formattedPhone: '',
      birthdate: '',
      countryCode: '',
      country: '',
      zone: '',
      photo: '',
      brandName: '',
      brandEmail: '',
      brandPhone: '',
      brandDefaultCountryCode: '',
      brandCountry: '',
      brandTimezone: '',
      brandLanguage: '',
      loadingCountriesBrand: false,
      countrySearchBrand: '',
      selectedCountryBrand: '',
      formattedPhoneBrand: '',
      brandPhoto: '',
      countriesBrand: [],
      timezones: [],
      terms: 0,
      valid: false,
      loading: false,
      loadingSave: false,
      loadingTimezones: false,
      isOwner: false,
      countryCodes: [],
      loadingCountryCodes: false,
      countryCodePage: 1,
      locationController: new LocationController(),
      userController: new UserController(),
      teamController: new TeamController(),
      appsController: new AppsController()
    }
  },
  methods: {
    ...mapActions('UserStore', ['setUserCompletionClose']),
    setInitial (): void {
      const data: dataToGetTermsByTeamMember = {
        appUUID: this.selectedApp?.uuid || '',
        user: this.user
      }
      this.name = this.user?.name || ''
      this.lastName = this.user?.lastName || ''
      this.email = this.user?.email || ''
      this.formattedPhone = `+${+this.user?.countryCode}${this.user?.phone}`
      this.phone = this.user?.phone || ''
      this.valid = true
      this.countryCode = this.user?.countryCode || ''
      this.country = this.user?.country || ''
      this.photo = this.user?.photo || ''
      this.terms = TeamUseCases.areTermsAcceptedByApp(this.teamController, data)
      this.selectedCountry = this.user?.address_country || ''
      this.selectedState = this.user?.address_province || ''
      this.selectedCity = this.user?.address_city || ''
      this.zone = this.user?.address_zone || ''
      this.birthdate = this.user?.birthday || ''
      if (this.isOwner) {
        this.brandName = this.selectedApp?.name || ''
        this.brandEmail = this.selectedApp?.email || ''
        this.brandPhone = this.selectedApp?.phone || ''
        this.brandDefaultCountryCode = this.selectedApp?.defaultCountryCode || ''
        this.brandCountry = this.selectedApp?.country || ''
        this.brandTimezone = this.selectedApp?.timeZone || ''
        this.brandLanguage = this.selectedApp?.lang || ''
        this.selectedCountryBrand = this.selectedApp?.country || ''
        this.formattedPhoneBrand = `+${this.selectedApp?.phone}`
        this.brandPhoto = this.selectedApp?.photo || ''
      }
    },
    setCountryBrandSearch: debounce(function (e: string): void {
      this.countrySearchBrand = e
      this.getCountriesBrand()
    }, 600),
    setCountrySearch: debounce(function (e: string): void {
      this.countrySearch = e
      this.getCountries()
    }, 600),
    setStateSearch: debounce(function (e: string): void {
      this.stateSearch = e
      this.getStates()
    }, 600),
    setCitySearch: debounce(function (e: string): void {
      this.citySearch = e
      this.getCities()
    }, 600),
    setCountrySelectBrand (e: string): void {
      if (e) {
        this.selectedCountryBrand = e
        this.brandCountry = e
      }
    },
    setCountrySelect (e: string): void {
      if (e) this.selectedCountry = e
      this.stateSearch = ''
      this.citySearch = ''
      this.selectedState = ''
      this.selectedCity = ''
      this.getStates()
    },
    setStateSelect (e: string): void {
      if (e) this.selectedState = e
      this.citySearch = ''
      this.selectedCity = ''
      this.getCities()
    },
    setCountryCodeSearch (e: string): void {
      if (!e) this.brandDefaultCountryCode = ''
      this.countryCodePage = 1
      this.countryCodeSearch = e
      this.getCountryCodes()
    },
    getNext (field: string): void {
      if (this.loadingCountryCodes) return
      this.countryCodePage++
      this.getCountryCodes()
    },
    async getCountryCodes (): Promise<void> {
      this.loadingCountryCodes = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid,
        query: {
          offset: this.countryCodePage,
          limit: 20,
          search: this.countryCodeSearch || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        const countries: Country[] = response?.countries
        countries.forEach((country: Country) => {
          this.countryCodes.push({
            name: country.name + `${country.phone_code.includes('+') ? ' ' : ' +'}` + country.phone_code,
            code: country.phone_code
          })
        })
      }
      this.loadingCountryCodes = false
    },
    async getTimezones (): Promise<void> {
      this.loadingTimezones = true
      const response: timezonesGetter = await LocationUseCases.getTimezones(this.locationController)
      if (response?.status) {
        const timezones: string[] = response?.timezones
        this.timezones = timezones
      }
      this.loadingTimezones = false
    },
    async getCountriesBrand (): Promise<void> {
      this.loadingCountriesBrand = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearchBrand
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countriesBrand = response?.countries
      }
      this.loadingCountriesBrand = false
    },
    async getCountries (): Promise<void> {
      this.loadingCountries = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearch
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countries = response?.countries
        this.getStates()
      }
      this.loadingCountries = false
    },
    async getStates (): Promise<void> {
      this.loadingStates = true
      this.states = []
      const variables: dataToGetStates = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        query: {
          limit: 20,
          offset: 0,
          search: this.stateSearch
        }
      }
      const response: statesGetter = await LocationUseCases.getStates(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.states = response?.states
      }
      this.loadingStates = false
    },
    async getCities (): Promise<void> {
      this.loadingCities = true
      this.cities = []

      const variables: dataToGetCities = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.selectedCountry,
        provinceCode: this.selectedState,
        query: {
          limit: 20,
          offset: 0,
          search: this.citySearch
        }
      }
      const response: citiesGetter = await LocationUseCases.getCities(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.cities = response?.cities
      }
      this.loadingCities = false
    },
    setTerms (e: boolean): void {
      this.terms = e ? 1 : 0
    },
    setPhone (e: formattedPhone): void {
      this.country = e.code
      this.countryCode = +e.number
      this.phone = e.phone
      this.valid = e.valid
    },
    setPhoneBrand (e: formattedPhone): void {
      this.brandPhone = `${e.number}${e.phone}`
    },
    openFileInput (): void {
      this.$refs.imageInput.click()
    },
    openFileInputBrand (): void {
      this.$refs.imageInputBrand.click()
    },
    async onFilePicked (event: any): Promise<void> {
      const img: any = event.target.files[0]
      URL.createObjectURL(img)
      const data: dataToUploadImage = {
        img,
        uuid: this.user?.uuid,
        appId: this.selectedApp?.uuid,
        token: this.token,
        preventUpdate: true
      }
      const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
      if (response.status) {
        this.photo = response?.data?.info?.location
      }
    },
    async onFilePickedBrand (event: any): Promise<void> {
      const img: any = event.target.files[0]
      URL.createObjectURL(img)
      const data: dataToUploadImage = {
        img,
        uuid: this.user?.uuid,
        appId: this.selectedApp?.uuid,
        token: this.token,
        preventUpdate: true
      }
      const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
      if (response.status) {
        this.brandPhoto = response?.data?.info?.location
      }
    },
    async submit (): Promise<void> {
      this.loadingSave = true
      if (this.isOwner) await this.saveBrandInfo()
      await this.saveUserInfo()
      this.loadingSave = false
    },
    async saveBrandInfo (): Promise<void> {
      try {
        const data: dataToUpdateApp = {
          app: {
            name: this.brandName,
            email: this.brandEmail,
            phone: this.brandPhone,
            defaultCountryCode: this.brandDefaultCountryCode,
            country: this.brandCountry,
            timeZone: this.brandTimezone,
            lang: this.brandLanguage,
            photo: this.brandPhoto,
            uuid: this.selectedApp?.uuid
          },
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
          this.updatedValue = {}
        }
      } catch (e) {
        console.error(e)
      }
    },
    async saveUserInfo (): Promise<void> {
      const data: dataToUpdateUser = {
        uuid: this.user?.uuid,
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          name: this.name,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          countryCode: this.countryCode,
          country: this.country,
          address_country: this.selectedCountry,
          address_province: this.selectedState,
          address_city: this.selectedCity,
          address_zone: this.zone,
          birthday: this.birthdate,
          photo: this.photo,
          accept_terms: this.terms,
          terms: this.terms
        }
      }
      const response: LoggedUser | userGetterError = await UserUseCases.updateUser(this.userController, data)

      if (response) {
        this.$emit('cancel')
      }
    },
    async close (): Promise<void> {
      try {
        if (this.isOwner) {
          this.$emit('cancel')
          return
        }
        const hasClosedCompletionInput = this.user?.userData?.find((data) => data.property === 'hasClosedCompletionInput')
        const data: dataToUpdateUser = {
          uuid: this.user?.uuid,
          token: this.token,
          updateInput: {
            appUUID: this.selectedApp?.uuid,
            extradata: []
          }
        }
        if (!hasClosedCompletionInput) {
          data.updateInput.extradata = [{
            property: 'hasClosedCompletionInput',
            value: '1'
          }]
        } else if (+hasClosedCompletionInput.value < 2) {
          data.updateInput.extradata = [{
            property: 'hasClosedCompletionInput',
            value: `${+hasClosedCompletionInput.value + 1}`
          }]
        } else return
        await UserUseCases.updateUser(this.userController, data)
        this.setUserCompletionClose(true)
        this.$emit('cancel')
      } catch (e) {
        console.error(e)
      }
    }
  }
}
