export default {
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    maxLength: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
  },
  mounted () {
    window.addEventListener('keydown', this.handleShortcuts)
  },
  data () {
    return {
      text: this.value,
      counter: 0,
      focused: false
    }
  },
  methods: {
    input (): void {
      this.counter = this.text.length
      this.$emit('input', this.text)
    },
    save (): void {
      this.$emit('save', this.text)
    },
    handleShortcuts (e: any): void {
      if (e.shiftKey && e.which === 13) {
        if (this.text) this.text = this.text + '\r\n'
      } else if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        e.stopPropagation()
        this.save()
      }
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.text = this.value
        this.counter = this.text?.length || 0
      }
    }
  },
  beforeDestroy () {
    if (window) window.addEventListener('keydown', this.handleShortcuts)
  }
}
