import CreateFormLanding from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormLanding/CreateFormLanding.vue'
import CreateFormWhatsApp from '@/modules/Config/WidgetTesseract/infrastructure/ui/CreateFormWhatsApp/CreateFormWhatsApp.vue'
import WebWidgetConfig from '@/modules/Config/WidgetTesseract/infrastructure/ui/WebWidget/WebWidgetConfig/WebWidgetConfig.vue'
import WidgetTesseractUseCases from '@/modules/Config/WidgetTesseract/application/WidgetTesseractUseCases'
import WidgetTesseractController from '@/modules/Config/WidgetTesseract/infrastructure/WidgetTesseract.controller'
import APIKeysController from '@/modules/APIKeysConfig/infrastructure/controllers/APIKeys.controller'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'
import WebWidgetService from '@/modules/configuration/domain/services/WebWidget.service'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import { EventBus } from '@/eventBus'
import APIKeysUseCases from '@/modules/APIKeysConfig/application/APIKeysUseCases'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { typeFormEnum } from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { mapState } from 'vuex'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import EmitToast from '@/utils/EmitToast'

export default {
  name: 'CreateFormConfig',
  components: {
    CreateFormLanding,
    WebWidgetConfig,
    CreateFormWhatsApp,
    KeybeSelect,
    KBSwitch,
    KeybeText,
    KeybeIcon
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    generateUrl () {
      const nameApp = this.selectedApp?.name.replace(/\s/g, '')
      return `Keybe.ai/${nameApp}`
    }
  },
  data () {
    return {
      dataTocreate: {},
      KeybeIconType,
      // types: [typeFormEnum.LandingPage, typeFormEnum.WhatsappForm, typeFormEnum.WebWidget],
      types: [typeFormEnum.LandingPage],
      selectedType: '',
      optionsType: [],
      type: '',
      title: '',
      selectedApiKey: {},
      apiKeys: [],
      widgetTesseractController: new WidgetTesseractController(),
      apiKeysController: new APIKeysController(),
      config: {},
      chatConfig: {},
      allowMessagesOutsideBusinessHours: false,
      allowMultimediaMessages: false,
      formatKey: ''
    }
  },
  async mounted () {
    this.getDataDefault()
    await this.getAPIKeys()
    await this.getConfig()
  },
  methods: {
    getDataDefault () {
      this.dataTocreate = WidgetTesseractUseCases.processDataDefault(this.widgetTesseractController)
    },
    changeType () {
      this.type = this.dataTocreate?.type
      this.setTitle()
      this.setOptionsPreview()
    },
    selectedTypeChange (type) {
      this.selectedType = type
    },
    setTitle () {
      if (this.type === typeFormEnum.LandingPage) {
        this.title = typeFormEnum.LandingPage
      }
      if (this.type === typeFormEnum.WhatsappForm) {
        this.title = typeFormEnum.WhatsappForm
      }
      if (this.type === typeFormEnum.WebWidget) {
        this.title = typeFormEnum.WebWidget
      }
    },
    setOptionsPreview () {
      if (this.type === typeFormEnum.LandingPage) {
        this.optionsType = []
        this.optionsType.push(this.$t('Desktop'), this.$t('Mobile'))
        this.selectedType = this.optionsType[0]
      }
      if (this.type === typeFormEnum.WhatsappForm) {
        this.optionsType = []
        this.optionsType.push(this.$t('Collapsed'), this.$t('Expanded'))
        this.selectedType = this.optionsType[0]
      }

      if (this.type === typeFormEnum.WebWidget) {
        this.optionsType = []
        this.optionsType.push(this.$t('Collapsed'), this.$t('Expanded'))
        this.selectedType = this.optionsType[0]
      }
    },
    async getAPIKeys () {
      try {
        this.loading = true
        const variables = {
          app: this.selectedApp?.uuid,
          token: this.token
        }
        const response = await APIKeysUseCases.getAPIKeys(this.apiKeysController, variables)
        if (response?.status) {
          Object.entries(response?.data).forEach(data => {
            const [key, value] = data
            if (key !== 'status') {
              this.apiKeys.push({ text: value.key, value: value, formatName: value?.key + ' - ' + value?.user?.name })
            }
          })
          if (this.apiKeys.length > 0) {
            this.dataTocreate.token = this.token
          }
        } else {
          EmitToast.emitErrors(response)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    selectApiKey (event) {
      this.dataTocreate.apiKey = event
    },
    async getConfig () {
      const chatConfig = await ConversationsConfigController.getConfig()
      const config = WebWidgetService.buildWebWidgetConfig(chatConfig)
      this.config = config.widgetWebSetup
      this.chatConfig = chatConfig
      this.allowMessagesOutsideBusinessHours = !chatConfig?.workingHours?.blockMessageComposer || false
      this.allowMultimediaMessages = !config.disableMultimedia || false
    },
    goBackToChannels (): void {
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.NEW_CHANNELS)
    },
    updateFormFieldColor (value) {
      this.dataTocreate.landingConfig.desktop.formFieldColor = value
    },
    updateFormTextColor (value) {
      this.dataTocreate.landingConfig.desktop.formTextColor = value
    },
    updateFormButtonColor (value) {
      this.dataTocreate.landingConfig.desktop.formButtonColor = value
    },
    updateFormBackgroundColor (value) {
      this.dataTocreate.landingConfig.desktop.formBackgroundColor = value
    },
    updateFormButtonText (value) {
      this.dataTocreate.landingConfig.desktop.formButtonText = value
    },
    updateFormLabelColor (value) {
      this.dataTocreate.landingConfig.desktop.formLabelColor = value
    },
    updateFormFields (value) {
      this.dataTocreate.formFields = value
    },
    updateContainerId (value) {
      value = value.replace(/\s/g, '')
      this.dataTocreate.landingConfig.desktop.containerId = value
    }
  }
}
