import BillingService from '@/modules/consumptions/application/service/BillingService'
// import moment from 'moment'
import { DateTime } from 'luxon'
import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import { mapGetters, mapState } from 'vuex'

import KBDateRangeMenu from '@/shared/infrastructure/ui/KBDateRangeMenu.vue'

import ProgressBillingCard from '@/modules/consumptions/infrastructure/ui/components/ProgressBillingCard/ProgressBillingCard.vue'
import GraphExpensesUSD from '@/modules/consumptions/infrastructure/ui/components/GraphExpensesUSD/GraphExpensesUSD.vue'
import GraphBillingUsage from '@/modules/consumptions/infrastructure/ui/components/GraphBillingUsage/GraphBillingUsage.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { dataToCreditCardsMethod } from '@/modules/consumptions/domain/PlanController'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import PaymentController from '../../../controllers/Payment.controller'
export default {
  name: 'BillingUsage',
  components: {
    KBDateRangeMenu,
    GraphExpensesUSD,
    ProgressBillingCard,
    GraphBillingUsage,
    KBButton
  },
  props: ['totalConsumption', 'dateString', 'treeMapData', 'optionBarChart'],
  data () {
    return {
      bikyPlanBooking: null,
      keybePlanBooking: null,
      keybePlan: null,
      bikyPlan: null,
      ConsumptionsTabs,
      dateSelector: '',
      dataBilling: null,
      planController: new PaymentController()
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapState('UserStore', ['token', 'isSuperAdmin']),
    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    },
    generalDueDate () {
      const { dueDate } = this.dataBilling
      const nuevaFecha = DateTime.fromISO(dueDate, { zone: 'utc' })
      return nuevaFecha.toLocaleString(DateTime.DATE_HUGE)
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    alertPayment () {
      if (this.dataBilling) {
        if (this.dataBilling?.current === this.dataBilling?.total) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    titleBiky (): string {
      const titles: {
        [key: string]: string
      } = {
        essential: `Biky - ${this.$t('essential')}`,
        cortex: `Biky - ${this.$t('cortex')}`,
        tesseract: `Biky - ${this.$t('tesseract')}`,
        'tesseract-plus': `Biky - ${this.$t('tesseract-plus')}`,
        'tesseract plus': `Biky - ${this.$t('tesseract-plus')}`
      }

      return titles[this.bikyPlan]
    },
    titleBikyBooking (): string {
      const titles: {
        [key: string]: string
      } = {
        essential: `Biky - ${this.$t('essential')}`,
        cortex: `Biky - ${this.$t('cortex')}`,
        tesseract: `Biky - ${this.$t('tesseract')}`,
        'tesseract-plus': `Biky - ${this.$t('tesseract-plus')}`,
        'tesseract plus': `Biky - ${this.$t('tesseract-plus')}`
      }

      return titles[this.bikyPlanBooking]
    }
  },
  methods: {
    // formatDate (date) {
    //  return moment(date).format('ll')
    // },
    async getActivePlan () {
      try {
        const data: dataToCreditCardsMethod = {
          token: this.token,
          appUUID: this.appUUID
        }
        const response = await PlanUseCases.getActiveBikyPlan(this.planController, data)
        if (response.status) {
          if (response?.data) {
            if (response.data.active === null || response.data.active === undefined) {
              this.keybePlan = 'On Demand'
            }
            const { active, booking } = response.data
            this.bikyPlan = active.tierLabel ? active.tierLabel.toLowerCase() : ''
            this.keybePlan = active.plan ? this.$t(`${active.plan.toLowerCase()}.title`) : 'On Demand'
            this.bikyPlanBooking = booking.tierLabel ? booking.tierLabel.toLowerCase() : ''
            this.keybePlanBooking = booking.plan ? this.$t(`${booking.plan.toLowerCase()}.title`) : 'On Demand'
          }
        } else {
          this.keybePlan = 'On Demand'
        }
      } catch (error) {
      }
    },
    async getPackage () {
      try {
        const invoices = await BillingService.getPackage()
        if (invoices?.data?.statusCode === 404) {
          this.dataBilling = null
          return
        }
        if (invoices) {
          this.dataBilling = invoices.data
        }
      } catch (error) {
        this.dataBilling = null
        console.error(error)
      }
    }
  },
  mounted () {
    this.getActivePlan()
    this.changeOption = null
    this.getPackage()
    this.dateSelector = this.dateString
  }
}
