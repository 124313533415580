











































































































































































































































import ConsumptionsSidebar from './ConsumptionsSidebar'
export default ConsumptionsSidebar
