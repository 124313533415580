import { dataToGetCards, dataToCreateCard, dataToUpdateCard, ICardsController, cardsGetter, dataToGetDeleteCard, dataToGetCardsFromContact, cardsContactGetter, dataToMoveContacts, dataToGetEndReaseon, dataToCheckCardExpiration, EndReadonGetter } from '../domain/models/CardsController'

export default class CardsUseCases {
  static async getCards (controller: ICardsController, data: dataToGetCards) : Promise<cardsGetter> {
    return await controller.getCards(data)
  }

  static async getCardsFromContact (controller: ICardsController, data: dataToGetCardsFromContact) : Promise<cardsContactGetter> {
    return await controller.getCardsFromContact(data)
  }

  static async getCard (controller: ICardsController, data: dataToGetDeleteCard) {
    return await controller.getCard(data)
  }

  static async createCard (controller: ICardsController, data: dataToCreateCard) {
    return await controller.createCard(data)
  }

  static async updateCard (controller: ICardsController, data: dataToUpdateCard) {
    return await controller.updateCard(data)
  }

  static async deleteCard (controller: ICardsController, data: dataToGetDeleteCard) {
    return await controller.deleteCard(data)
  }

  static async moveContacts (controller: ICardsController, data: dataToMoveContacts) {
    return controller.moveContacts(data)
  }

  static async getTerminationReason (controller: ICardsController, data: dataToGetEndReaseon) : Promise<EndReadonGetter> {
    return controller.getTerminationReason(data)
  }

  static isCardExpired (controller: ICardsController, data: dataToCheckCardExpiration) {
    return controller.isCardExpired(data)
  }
}
