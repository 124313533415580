import ApexCharts from 'apexcharts'
export default {
  name: 'GraphBillingUsage',
  props: ['optionBarChart'],
  components: {},
  data: function () {
    return {
      stackedColumsGraph: null
    }
  },
  computed: {
  },
  watch: {
    optionBarChart (val) {
      this.buildBarChartGraph(val)
    }
  },
  methods: {
    getBarChartGraphData (rawData) {
      return {
        series: rawData.series,
        // colors: [Colors.verdeBird, Colors.kbGreen, Colors.rojoBird, Colors.simpleGrey, Colors.gauchoBlue, Colors.morningCoffee],
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: false
        },
        chart: {
          type: 'bar',
          height: 400,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: rawData.xAxis,
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        }
      }
    },
    buildBarChartGraph (rawData) {
      if (this.stackedColumsGraph) {
        this.stackedColumsGraph.destroy()
      }

      const options = this.getBarChartGraphData(rawData)

      this.stackedColumsGraph = new ApexCharts(document.querySelector('#ConsumptionsGraph__graph--stack'), options)
      this.stackedColumsGraph.render()
    }
  },
  mounted () {
    this.buildBarChartGraph(this.optionBarChart)
  }
}
