import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'
import {
  TemplateButtonTypesEnum,
  TemplateCategoryEnum,
  TemplateHeaderFormatEnum
} from '@/modules/createTemplates/domain/Template'
import { EventBus } from '@/eventBus'
import TemplateController from '../../Template.controller'
import TemplateUseCases from '@/modules/createTemplates/application/TemplateUseCases'
import { VEmojiPicker } from 'v-emoji-picker'

import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSelect from '@/shared/infrastructure/ui/inputs/select/KBSelect.vue'
import FilePicker from '@/shared/infrastructure/ui/FilePicker/FilePicker.vue'
import TemplateTextEditor from '../TemplateTextEditor/TemplateTextEditor.vue'
import { mapState } from 'vuex'
import { TemplateData, dataToCreateTemplate, templateCreated } from '@/modules/createTemplates/domain/TemplateController'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  name: 'NewTemplateForm',
  components: {
    KBText,
    KeybeIcon,
    KBSelect,
    FilePicker,
    VEmojiPicker,
    TemplateTextEditor
  },
  props: {
    userHost: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      Colors,
      openEmojiPicker: false,
      buttonHovered: '',
      KeybeIconType,
      name: '',
      title: '',
      text: '',
      media: '',
      mediaType: '',
      buttons: [],
      TemplateButtonTypesEnum,
      showInputtextExample: false,
      headerLocation: '',
      bodyLocation: '',
      selectedTemplateCategory: TemplateCategoryEnum.MARKETING,
      buttonActions: [
        {
          value: TemplateButtonTypesEnum.URL,
          text: this.$t('openUrl')
        },
        {
          value: TemplateButtonTypesEnum.QUICK_REPLY,
          text: this.$t('sendText')
        }
      ],
      selectedLanguage: 'es',
      languages: [
        {
          value: 'es',
          text: this.$t('spanish')
        },
        {
          value: 'en',
          text: this.$t('english')
        }
      ],
      headerTypes: [
        {
          value: 'none',
          text: this.$t('none')
        },
        {
          value: TemplateHeaderFormatEnum.TEXT,
          text: this.$t(CDPFieldsEnums.TEXT)
        },
        {
          value: TemplateHeaderFormatEnum.IMAGE,
          text: this.$t('image')
        },
        {
          value: TemplateHeaderFormatEnum.VIDEO,
          text: this.$t('video')
        }
        // {
        //   value: TemplateHeaderFormatEnum.DOCUMENT,
        //   text: this.$t('document')
        // }
      ],
      categoryTypes: [
        {
          value: TemplateCategoryEnum.MARKETING,
          text: this.$t('marketing')
        },
        {
          value: TemplateCategoryEnum.UTILITY,
          text: this.$t('utility')
        }
      ],
      selectedHeaderType: 'none',
      templateController: new TemplateController(),
      activityLogsController: new ActivityLogsController(),
      TemplateHeaderFormatEnum,
      showEmojiPicker: true,
      loading: false,
      CDPFieldsEnums
    }
  },
  methods: {
    copyButton () {
      if (!this.text) {
        EventBus.$emit('toast', 'error', this.$t('copiedToClipboardEmpty'))
        return
      }
      navigator.clipboard.writeText(this.text).then(() => {
        EventBus.$emit('toast', 'success', this.$t('copiedToClipboard'))
      }).catch(err => {
        console.error('Error al copiar el texto: ', err)
      })
    },
    addButton () {
      this.buttons.push({
        title: '',
        url: '',
        action: TemplateButtonTypesEnum.URL
      })
    },
    removeButton (index: number) {
      this.buttons.splice(index, 1)
    },
    onSelectEmoji (emoji) {
      const textarea = this.$refs.textarea
      if (!textarea) return

      // get caret position/selection
      const val = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      const newValue = val.substring(0, start) + emoji.data + val.substring(end)
      this.text = newValue
    },
    onFileUpload (url: string): void {
      const urlParts = url.split('?')
      this.media = urlParts[0]

      const extension = url.split('.').pop()

      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
        this.mediaType = 'image'
      }
      if (extension === 'mp4') {
        this.mediaType = 'video'
      }
      if (extension === 'pdf') {
        this.mediaType = 'document'
      }
    },
    formValidations (): boolean {
      if (!this.name) {
        EventBus.$emit('toast', 'error', this.$t('nameIsRequired'))
        return false
      }

      const isValidName = this.validateName(this.name)
      if (!isValidName) {
        return false
      }
      this.name = this.name.replace(/\s/g, '_').toLowerCase()

      if (!this.title && this.selectedHeaderType === 'TEXT') {
        EventBus.$emit('toast', 'error', this.$t('headerIsRequired'))
        return false
      }

      if (!this.text) {
        EventBus.$emit('toast', 'error', this.$t('textIsRequired'))
        return false
      }

      const minLength: number = 20
      const maxLength: number = 1024

      if (this.text.length < minLength || this.text.length > maxLength) {
        EventBus.$emit('toast', 'error', this.$t('textLengthError'))
        return false
      }

      if (this.buttons.length > 0) {
        for (const button of this.buttons) {
          if (!button.title) {
            EventBus.$emit('toast', 'error', this.$t('buttonTitleIsRequired'))
            return false
          }
          if (!button.url && button.action === 'openUrl') {
            EventBus.$emit('toast', 'error', this.$t('buttonUrlIsRequired'))
            return false
          }
        }
      }
      return true
    },
    validateName (name: string) {
      const regex = /^[a-zA-Z0-9_\s]+$/

      if (!name.match(regex)) {
        EventBus.$emit('toast', 'error', this.$t('nameInvalidCharacters'))
        return false
      }

      return true
    },
    matchTextWithEditableParams (text: string): string[] | null {
      const textToTest = this.$t('editableParam')
      const regex: RegExp = new RegExp(textToTest, 'g')
      return text.match(regex)
    },
    parseTextWithEditableParams (text: string): string {
      const params: string[] | null = this.matchTextWithEditableParams(text)

      if (!params) return text

      let newText = text

      for (let i = 0; i < params.length; i++) {
        newText = newText.replace(`[${params[i]}]`, `{{${i + 1}}}`)
      }

      return newText
    },
    getBodyTextParams (text: string): string[] {
      const params: string[] | null = this.matchTextWithEditableParams(text)
      if (params) {
        const strings: string[] = params.filter((param: any) => typeof param === 'string')
        return strings
      }
      return []
    },
    handleIdChange (newId: string) {
      if (newId === 'headerTextEditor') {
        this.headerLocation = newId
      }
      if (newId === 'bodyTextEditor') {
        this.bodyLocation = newId
      }
    },
    async submit () {
      const isValid = this.formValidations()
      if (!isValid) return

      const components = []

      if (this.title !== 'none' && this.selectedHeaderType !== 'none') {
        let example: { [key: string]: any } = {}
        if (
          this.selectedHeaderType === 'TEXT' &&
          this.headerLocation === 'headerTextEditor'
        ) {
          example = { header_text: ['exampleHeader'] }
        }

        const validTypes: string[] = ['IMAGE', 'VIDEO', 'DOCUMENT']

        if (validTypes.includes(this.selectedHeaderType)) {
          example = { header_handle: [this.media] }
        }

        const header: { [key: string]: any } =
          TemplateUseCases.buildTemplateHeader(this.templateController, {
            format: this.selectedHeaderType,
            text: this.parseTextWithEditableParams(this.title),
            example
          })

        if (this.headerLocation === '' && this.selectedHeaderType === 'TEXT') {
          delete header.example
        }

        components.push(header)
      }

      const body: { [key: string]: any } = TemplateUseCases.buildTemplateBody(
        this.templateController,
        {
          text: this.parseTextWithEditableParams(this.text),
          example: {
            body_text: [this.getBodyTextParams(this.text)]
          }
        }
      )

      if (this.bodyLocation === '') {
        delete body.example
      }

      components.push(body)

      if (this.buttons.length > 0) {
        const buttonsComponent = []
        for (const button of this.buttons) {
          const buttonComponent = TemplateUseCases.buildTemplateButton(
            this.templateController,
            {
              type: button.action,
              text: button.title,
              url:
                button.action === TemplateButtonTypesEnum.URL
                  ? button.url
                  : undefined
            }
          )
          buttonsComponent.push(buttonComponent)
        }
        const buttons = TemplateUseCases.buildTemplateButtons(
          this.templateController,
          {
            buttons: buttonsComponent
          }
        )
        components.push(buttons)
      }

      const templateData: TemplateData = {
        name: this.name,
        language: this.selectedLanguage,
        components,
        category: this.selectedTemplateCategory
      }

      const data: dataToCreateTemplate = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        userHost: this.userHost,
        templateData
      }

      this.loading = true

      const created: templateCreated = await TemplateUseCases.createTemplate(
        this.templateController,
        data
      )

      if (!created.status) {
        this.loading = false
        EventBus.$emit('toast', 'error', this.$t('errorCreatingTemplate') + ':' + created.message)
        return
      }

      this.loading = false
      this.writeActivityLog()
      this.$emit('create')
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.TEMPLATE_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user'])
  }
}
