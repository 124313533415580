import { mapState } from 'vuex'
import ProductController from '@/modules/catalog/infrastructure/controllers/Product.controller'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import CategoryUseCases from '@/modules/catalog/application/CategoryUseCases'
import CategoryController from '@/modules/catalog/infrastructure/controllers/Category.controller'
import { Product } from '@/modules/catalog/domain/models/Product'
import { dataToCreateCatalog, dataToUpdateCatalog } from '@/modules/microsites/domain/CatalogController'
import CatalogUseCases from '@/modules/microsites/application/CatalogUseCases'
import { Catalog } from '@/modules/microsites/domain/Catalog'
import CatalogController from '@/modules/microsites/infrastructure/controllers/Catalog.controller'

// components
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import CatalogProduct from '../CatalogProduct/CatalogProduct.vue'
import CatalogProductList from '@/modules/catalog/infrastructure/ui/CatalogProductList/CatalogProductList.vue'
import CatalogCreateProduct from '../CatalogCreateProduct/CatalogCreateProduct.vue'
import CatalogUpdateProduct from '@/modules/catalog/infrastructure/ui/CatalogUpdateProduct/CatalogUpdateProduct.vue'
import AppUUIDsService from '@/services/AppUUIDsService'
import { dataToSyncWithShopify, dataToGetProducts, Paginator } from '@/modules/catalog/domain/models/ProductsController'
import EmitToast from '@/utils/EmitToast'
import Colors from '@/utils/Colors'
import { debounce } from 'lodash'
import Categories from '@/modules/microsites/infrastructure/ui/components/Categories/Categories.vue'
import { FilterQuerys } from '@/components/conversations/enums/FilterQuerys'
import { Category } from '@/modules/catalog/domain/models/Category'
export default {
  components: {
    KeybeButton,
    KBSearch,
    CatalogProduct,
    CatalogProductList,
    CatalogCreateProduct,
    CatalogUpdateProduct,
    Categories
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'isUserReadonly'])
  },
  mounted () {
    this.getCatalog()
  },
  data () {
    return {
      colors: Colors,
      products: [],
      categories: [],
      renderKey: 0,
      loading: false,
      update: false,
      create: false,
      limit: 10,
      page: 1,
      selectedCategory: {},
      productController: new ProductController(),
      categoryController: new CategoryController(),
      catalogController: new CatalogController(),
      selectedProduct: null,
      shopifyDomain: 'bikehouse-co',
      apiKey: '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',
      hasNext: false,
      hasPrev: false,
      loadingNext: false,
      loadingPrev: false,
      showCatalog: true,
      getUncategorized: false,
      search: '',
      Colors
    }
  },
  methods: {
    hasShopifyCatalog (): boolean {
      return this.selectedApp?.uuid === AppUUIDsService.appBikeHouse()
    },
    openUpdate (product) {
      if (this.isUserReadonly) return
      this.selectedProduct = product
      this.update = true
    },
    openCreateCategory (category) {
      if (this.isUserReadonly) return
      this.selectedCategory = category
      this.create = true
    },
    getNextPage () {
      this.page++
      this.getProducts(Paginator.NEXT)
    },
    getPrevPage () {
      this.page--
      this.getProducts(Paginator.PREV)
    },
    setSearch: debounce(function (e: string) {
      this.search = e
      this.getProducts('')
    }, 600),
    setCategory (category: Category): void {
      this.getUncategorized = false
      if (!category || Object.keys(category).length === 0) return
      if (category.id === FilterQuerys.ALL) {
        this.selectedCategory = {}
      } else if (category.id === 'withoutCategory') {
        this.getUncategorized = true
      } else this.selectedCategory = category
      this.getProducts()
    },
    async getProducts (get: Paginator): Promise<void> {
      const data: dataToGetProducts = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        limit: this.limit,
        get: get,
        products: this.products
      }

      if (this.getUncategorized) {
        data.getUncategorized = true
      }

      if (data.get === Paginator.NEXT) {
        this.loadingNext = true
      } else if (data.get === Paginator.PREV) {
        this.loadingPrev = true
      }

      if (Object.keys(this.selectedCategory).length > 0) {
        data.category = this.selectedCategory.category
        this.$emit('start-loading')
      }

      if (this.search) data.product = this.search
      const response = await ProductUseCases.getProducts(this.productController, data)
      this.products = response.products
      this.hasNext = response.hasNext
      this.hasPrev = response.hasPrev

      this.loadingNext = false
      this.loadingPrev = false
      this.$emit('stop-loading')
    },
    async getCatalog () {
      try {
        this.loading = true
        this.$emit('start-loading')
        await this.getProducts('')
        this.categories = await CategoryUseCases.getCategories(this.categoryController, {
          token: this.token,
          appUUID: this.selectedApp?.uuid
        })
        this.categories.unshift({ category: this.$t('allCategories'), id: FilterQuerys.ALL })
        this.categories.push({ category: this.$t('withoutCategory'), id: 'withoutCategory' })

        if (this.categories.length > 0 && this.products.length > 0) {
          this.products = this.products.map((product: { category: string[] | string }) => {
            if (!product.category) {
              product.category = [this.$t('withoutCategory')]
            }
            if (!Array.isArray(product.category)) {
              product.category = [product.category]
            }
            if (product.category.length === 0) {
              product.category = [this.$t('withoutCategory')]
            }
            return product
          })

          this.categories.forEach((category: { totalProducts: number, category: string }) => {
            category.totalProducts = 0
            for (const product of this.products) {
              if (product.category.includes(category.category)) {
                category.totalProducts++
              }
            }
          })
        }
        this.loading = false
        this.$emit('stop-loading')
      } catch (e) {
        console.error(e)
        this.loading = false
        this.$emit('stop-loading')
      }
    },
    closeCreate (): void {
      this.create = false
      this.getCatalog()
      this.update = false
      this.selectedProduct = null
    },
    async catalogModified (): Promise<void> {
      await this.getCatalog()
      const productsId: string[] = this.products.map((product: Product) => product.id)
      const catalog: Catalog = await CatalogUseCases.getCatalog(this.catalogController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })
      const validCatalog = Object.keys(catalog).length > 0

      if (validCatalog) {
        const data: dataToUpdateCatalog = {
          token: this.token,
          data: {
            appUUID: this.selectedApp?.uuid,
            microsite: catalog?.microsite || false,
            whatsapp: catalog?.whatsapp || false,
            products: productsId,
            published: productsId.length > 0 || false,
            id: catalog?.id
          }
        }

        await CatalogUseCases.updateCatalog(this.catalogController, data)
      } else {
        const data: dataToCreateCatalog = {
          token: this.token,
          data: {
            appUUID: this.selectedApp?.uuid,
            microsite: catalog?.microsite || false,
            whatsapp: catalog?.whatsapp || false,
            products: productsId
          }
        }

        await CatalogUseCases.createCatalog(this.catalogController, data)
      }

      this.create = false
      this.update = false
      this.selectedProduct = null
    },
    async syncWithShopify (): Promise<void> {
      this.loading = true

      const data: dataToSyncWithShopify = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        shopifyDomain: this.shopifyDomain,
        apiKey: this.apiKey,
        updateIfExists: true,
        onlyActive: true
      }

      const response = await ProductUseCases.syncWithShopify(this.productController, data)

      if (response.status) {
        return await this.getCatalog()
      } else {
        this.loading = false
        EmitToast.emitErrors(response.message || 'Error syncing with Shopify')
      }
    }
  },
  beforeDestroy () {
    ProductUseCases.abortAll(this.productController)
  },
  watch: {
    selectedApp: {
      handler () {
        this.getCatalog()
      }
    }
  }
}
