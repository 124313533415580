









































































































import CatalogConfig from './CatalogConfig'
export default CatalogConfig
