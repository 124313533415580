export default {
  name: 'ScreenDesktop',
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  computed: {
    typographyStyle () {
      return `font-family: ${this.dataTocreate.landingConfig.desktop.typography};`
    },
    bubbleStyle () {
      return `background: ${this.dataTocreate.landingConfig.desktop.formFieldColor}; ${this.typographyStyle}`
    },
    buttonStyle () {
      return `background-color: ${this.dataTocreate.landingConfig.desktop.formButtonColor}; color: ${this.dataTocreate.landingConfig.desktop.formTextColor}; ${this.typographyStyle}`
    },
    titleStyle () {
      return `color: ${this.dataTocreate.landingConfig.desktop.titleColor}; text-align: ${this.dataTocreate.landingConfig.desktop.titleTextAlign}; ${this.typographyStyle}`
    },
    subtitleStyle () {
      return `color: ${this.dataTocreate.landingConfig.desktop.messageColor}; ${this.typographyStyle}`
    },
    chatContainerStyle () {
      return `background: ${this.dataTocreate.landingConfig.desktop.formBackgroundColor}; ${this.typographyStyle}`
    }
  }
}
