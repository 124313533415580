import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/shared/domain/colors/Color'

// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    hideCheck: {
      type: Boolean
    },
    hideDelete: {
      type: Boolean
    },
    deleting: {
      type: Boolean
    },
    isForm: {
      type: Boolean
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors
    }
  },
  computed: {
    productImages () {
      return this.product?.images[0]?.url ? this.product?.images[0]?.url : this.product?.images[0]
    },
    productSku () {
      return this.product?.sku || ''
    },
    productId () {
      return this.product?.id || ''
    },
    productName () {
      return this.product?.product || ''
    },
    productDescription () {
      return this.product?.description || ''
    },
    productOption1 () {
      return this.product?.option1
    },
    productOption1Label () {
      return this.product?.option1?.label || ''
    },
    productOption1Items () {
      return this.product?.option1?.items || []
    },
    productOption2 () {
      return this.product?.option2
    },
    productOption2Label () {
      return this.product?.option2?.label || ''
    },
    productOption2Items () {
      return this.product?.option2?.items || []
    },
    productOption3 () {
      return this.product?.option3
    },
    productOption3Label () {
      return this.product?.option3?.label || ''
    },
    productOption3Items () {
      return this.product?.option3?.items || []
    },
    productCategory () {
      return this.product?.category.toString() || []
    }
  },
  components: {
    // KBCheck,
    KeybeIcon
  }
}
