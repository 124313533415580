import CortexIcon from '@/shared/infrastructure/ui/icons/SvgComponents/CortexIcon.vue'
import EssentialIcon from '@/shared/infrastructure/ui/icons/SvgComponents/EssentialIcon.vue'
import TesseractIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TesseractIcon.vue'
import TesseractPlusIcon from '@/shared/infrastructure/ui/icons/SvgComponents/TesseractPlusIcon.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'

export default {
  name: 'BillingPlansBikyCards',
  components: {
    EssentialIcon,
    CortexIcon,
    TesseractIcon,
    TesseractPlusIcon,
    KBButton
  },
  props: {
    dataCard: {
      type: Object,
      required: true
    },
    info: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    seeMore () {
      this.$emit('seeMore', this.codeBiky)
    }
  },
  computed: {
    titleBiky (): string {
      const titles: {
        [key: string]: string
      } = {
        essential: `${this.$t('essential')}`,
        cortex: `${this.$t('cortex')}`,
        tesseract: `${this.$t('tesseract')}`,
        'tesseract-plus': `${this.$t('tesseract-plus')}`,
        'tesseract plus': `${this.$t('tesseract-plus')}`
      }

      return titles[this.dataCard.code]
    },
    codeBiky (): string {
      return this.dataCard.code
    }
  },
  mounted () {
  }
}
