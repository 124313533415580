import {
  AccoundDetailsResponse,
  GetIGBusinessAccountResponse,
  GetIGConversationsResponse,
  GetPageAccessTokenResponse,
  GetUserAccountsResponse,
  LoginParams,
  PermissionsResponse,
  ProfilePictureResponse,
  SDKResponse,
  StatusResponse,
  SubscribeToWebhooksResponse
} from '@/modules/FacebookConnection/domain/models/Facebook'
import axios from 'axios'
import store from '@/store'

export default class FacebookService {
  static baseUrl: string = process.env.VUE_APP_CONVERSATIONS_API_URL

  static existsSDK (): boolean {
    const scriptElement = document.getElementById('facebook-jssdk')
    return !!scriptElement
  }

  static async injectSDK (): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        window.fbAsyncInit = () => {
          resolve()
        }

        const scriptElement = document.createElement('script')
        scriptElement.id = 'facebook-jssdk'
        scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js'
        const firstScript = document.getElementsByTagName('script')[0]
        firstScript.parentNode.insertBefore(scriptElement, firstScript)
      } catch (error) {
        console.error("Couldn't inject the Facebook SDK", error)
        reject(new Error('Error inyectando el SDK de Facebook'))
      }
    })
  }

  static initializeSDK (appId: string): void {
    try {
      // eslint-disable-next-line
      FB.init({
        version: 'v19.0',
        appId
      })
    } catch (error) {
      console.error("Couldn't initialize the Facebook SDK", error)
      throw new Error('Error inicializando el SDK de Facebook')
    }
  }

  static async login (params: LoginParams): Promise<StatusResponse> {
    try {
      return await new Promise<StatusResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.login(
          (response: SDKResponse<StatusResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          },
          params
        )
      })
    } catch (error) {
      console.error('An error occurred during login', error)
      throw new Error('Error durante proceso de inicio de sesión')
    }
  }

  static async getPermissions (accessToken: string): Promise<PermissionsResponse> {
    try {
      return await new Promise<PermissionsResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/me/permissions?accessToken=${accessToken}`,
          (response: SDKResponse<PermissionsResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting permissions', error)
      throw new Error('Error consultando los permisos')
    }
  }

  static async makeLongLiveRequest ({
    companyId,
    appId,
    platform,
    shortLivedAccessToken
  }: {
    companyId: string;
    appId: string;
    platform: string;
    shortLivedAccessToken: string;
  }): Promise<string> {
    const url = `${FacebookService.baseUrl}/configurations/company/${companyId}/app/${appId}/channels/${platform}/long-lived-access-token?accessToken=${shortLivedAccessToken}`
    const response = await axios.get(url)
    if (response.data.data.error) {
      throw new Error(response.data.message)
    }
    return response.data.data.item
  }

  static getPlatform (platform: string): string {
    const platforms = {
      facebookMessenger: 'facebook-messenger',
      instagram: 'instagram'
    }
    const _platform = platforms[platform]
    if (!_platform) {
      throw new Error('Invalid platform')
    }

    return _platform
  }

  static async getLongLivedAccessToken (platform: string, shortLivedAccessToken: string): Promise<string> {
    try {
      const _platform = this.getPlatform(platform)
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      return await this.makeLongLiveRequest({
        companyId,
        appId,
        platform: _platform,
        shortLivedAccessToken
      })
    } catch (error) {
      console.error('Error getting long lived access token', error)
      throw new Error('Error generando token de acceso de larga vida')
    }
  }

  static async getLongLivedAccessTokenByPathParams (
    platform: string,
    shortLivedAccessToken: string,
    appId: string,
    companyId: string
  ): Promise<string> {
    try {
      const _platform = this.getPlatform(platform)
      return await this.makeLongLiveRequest({
        companyId,
        appId,
        platform: _platform,
        shortLivedAccessToken
      })
    } catch (error) {
      console.error('Error getting long lived access token', error)
      throw new Error('Error generando token de acceso de larga vida')
    }
  }

  static async getUserAccounts (accessToken: string): Promise<GetUserAccountsResponse> {
    try {
      return await new Promise<GetUserAccountsResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `me/accounts?access_token=${accessToken}`,
          (response: SDKResponse<GetUserAccountsResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting user account', error)
      throw new Error('Error obteniendo las cuentas')
    }
  }

  static async getIGBusinessAccount (
    pageId: string,
    accessToken: string
  ): Promise<GetIGBusinessAccountResponse> {
    try {
      return await new Promise<GetIGBusinessAccountResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/${pageId}?fields=instagram_business_account&access_token=${accessToken}`,
          (response: SDKResponse<GetIGBusinessAccountResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting the Instagram Business Account', error)
      throw new Error('Error obteniendo la cuenta de Instagram Business')
    }
  }

  static async getIGConversations (
    pageId: string,
    accessToken: string
  ): Promise<GetIGConversationsResponse> {
    try {
      return await new Promise<GetIGConversationsResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/${pageId}/conversations?platform=instagram&access_token=${accessToken}`,
          (response: SDKResponse<GetIGConversationsResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting the Instagram conversations', error)
      throw error
    }
  }

  static async getPageAccessToken (
    pageId: string,
    accessToken: string
  ): Promise<GetPageAccessTokenResponse> {
    try {
      return await new Promise<GetPageAccessTokenResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/${pageId}?fields=access_token&access_token=${accessToken}`,
          (response: SDKResponse<GetPageAccessTokenResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting the page access token', error)
      throw new Error('Error obteniendo el token de acceso de la página')
    }
  }

  static async subscribeToWebhooks (
    pageId: string,
    webhooks: string[],
    accessToken: string
  ): Promise<void> {
    try {
      const subscribedFields = webhooks.join(',')
      await new Promise<void>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/${pageId}/subscribed_apps`,
          'post',
          {
            access_token: accessToken,
            subscribed_fields: subscribedFields
          },
          (response: SDKResponse<SubscribeToWebhooksResponse>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve()
            }
          }
        )
      })
    } catch (error) {
      console.error('Error subscribing to webhooks', error)
      throw new Error('Error durante suscripción a webhooks')
    }
  }

  static async logout (): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        // eslint-disable-next-line
        FB.logout((response: SDKResponse<StatusResponse>) => {
          if ('error' in response) {
            reject(new Error(response.error.message))
          } else {
            resolve()
          }
        })
      })
    } catch (error) {
      console.error('Error logging out', error)
      throw new Error('Error cerrando sesión')
    }
  }

  static async getAccountDetails (accessToken: string): Promise<AccoundDetailsResponse> {
    try {
      return await new Promise<AccoundDetailsResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          '/me',
          {
            accessToken: accessToken,
            fields: 'first_name, last_name, email, id'
          },
          (response: SDKResponse<any>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting permissions', error)
      throw new Error('Error consultando los permisos')
    }
  }

  static async getProfilePicture (accessToken: string, id: string): Promise<ProfilePictureResponse> {
    try {
      return await new Promise<ProfilePictureResponse>((resolve, reject) => {
        // eslint-disable-next-line
        FB.api(
          `/${id}/picture`,
          {
            accessToken: accessToken,
            type: 'large',
            redirect: false
          },
          (response: SDKResponse<any>) => {
            if ('error' in response) {
              reject(new Error(response.error.message))
            } else {
              resolve(response)
            }
          }
        )
      })
    } catch (error) {
      console.error('Error getting permissions', error)
      throw new Error('Error consultando los permisos')
    }
  }
}
