import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import { User } from '@/components/models/User'
import store from '@/store'
import axios from 'axios'

export const UserStore = {
  namespaced: true,
  state: {
    analyticsTimeRange: 30,
    firstRender: true,
    isLoading: false,
    isLoggedIn: false,
    token: '',
    phone: '',
    country: '',
    v4Token: '',
    user: {},
    companies: [],
    search: '',
    selectedApp: {},
    status: false,
    selectedCompany: null,
    hideInstagramDialog: false,
    selectedPeopleHeaders: [],
    selectedPeopleHeadersCDP: [],
    allPeopleHeaders: [],
    personalizedTags: [],
    personalizedExtradata: [],
    orderedExtradata: [],
    orderedTags: [],
    activeExtradata: [],
    activeTags: [],
    orderedActiveExtradata: [],
    orderedActiveTags: [],
    changePassword: false,
    showPopUpVideo: true,
    isOwner: false,
    userExtradata: [],
    deviceToken: '',
    showFormPop: true,
    darkMode: '',
    orderedDetailPanels: [],
    selectedUserApp: {},
    isUserReadonly: false,
    closedUserCompletion: false
  },
  mutations: {
    SET_USER_READ_ONLY (state, isReadonly: boolean) {
      state.isUserReadonly = isReadonly
    },
    SET_KEYBE_USER (state, user: User) {
      state.user = user
    },
    SET_TOKEN (state, token: string) {
      state.token = token
    },
    SET_V4TOKEN (state, token: string) {
      state.v4Token = token
    },
    SET_PHONE (state, phone: string) {
      state.phone = phone
    },
    SET_COUNTRY (state, country: string) {
      state.country = country
    },
    CHANGE_KEYBE_AUTH (state, isLoggedIn: boolean) {
      state.isLoggedIn = isLoggedIn
    },
    CLEAR_USER_STATE (state) {
      state.isLoggedIn = false
      state.token = ''
      state.v4Token = ''
      state.user = {}
      state.companies = []
      state.search = ''
      state.selectedApp = {}
      state.status = false
      state.selectedCompany = null
      state.userExtradata = []
      state.deviceToken = ''
      state.selectedUserApp = {}
      state.closedUserCompletion = false
    },
    SET_LOADING (state, status: boolean) {
      state.isLoading = status
    },
    SET_FIRST_RENDER (state, status: boolean) {
      state.firstRender = status
    },
    HIDE_INSTAGRAM_NOTIFICATION (state, status) {
      state.hideInstagramDialog = status
    },
    SET_METRICS_DAYS_PERIOD (state, days: number) {
      state.analyticsTimeRange = days
    },
    SET_SELECTED_HEADERS (state, headers) {
      state.selectedPeopleHeaders = headers
    },
    SET_SELECTED_HEADERS_CDP (state, headers) {
      state.selectedPeopleHeadersCDP = headers
    },
    SET_HEADERS (state, headers) {
      state.allPeopleHeaders = headers
    },
    SET_ACTIVE_EXTRADATA (state, activeExtradata) {
      state.activeExtradata = activeExtradata
    },
    SET_ACTIVE_TAGS (state, activeTags) {
      state.activeTags = activeTags
    },
    SET_PERSONALIZED_TAGS (state, personalizedTags) {
      state.personalizedTags = personalizedTags
    },
    SET_ORDERED_EXTRADATA (state, ordered) {
      state.orderedExtradata = ordered
    },
    SET_ORDERED_TAGS (state, ordered) {
      state.orderedTags = ordered
    },
    SET_ORDERED_ACTIVE_EXTRADATA (state, ordered) {
      state.orderedActiveExtradata = ordered
    },
    SET_ORDERED_ACTIVE_TAGS (state, ordered) {
      state.orderedActiveTags = ordered
    },
    SET_CHANGE_PASSWORD (state, status) {
      state.changePassword = status
    },
    SET_SHOW_POP_UP_VIDEO (state, show) {
      state.showPopUpVideo = show
    },
    SET_IS_OWNER (state, status) {
      state.isOwner = status
    },
    SET_USER_EXTRADATA (state, extradata) {
      state.userExtradata = extradata
    },
    SET_DEVICE_TOKEN (state, token) {
      state.deviceToken = token
    },
    SET_SHOW_FORM (state, value) {
      state.showFormPop = value
    },
    SET_DARK_MODE (state, mode) {
      state.darkMode = mode
    },
    SET_ORDERED_DETAILS_PANELS (state, panels) {
      state.orderedDetailPanels = panels
    },
    SET_SELECTED_USER_APP (state, app) {
      state.selectedUserApp = app
    },
    SET_USER_COMPLETION_CLOSE (state, status) {
      state.closedUserCompletion = status
    }
  },
  actions: {
    setUserCompletionClose (context, status: boolean) {
      context.commit('SET_USER_COMPLETION_CLOSE', status)
    },
    setIsLoading ({ commit }, status: boolean) {
      commit('SET_LOADING', status)
    },
    async totalUsers (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/totalusers',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.apps.rows[0].total
      } catch (e) {
        console.error(e)
      }
    },
    logout (context) {
      context.commit('SET_KEYBE_USER', {})
      context.commit('SET_TOKEN', '')
      context.commit('CHANGE_KEYBE_AUTH', false)
      context.commit('CLEAR_USER_STATE')
      context.dispatch('ConversationStore/clearConversationState', true, {
        root: true
      })
      context.dispatch('CompanyStore/clearCompanyState', true, { root: true })
      context.dispatch('AppStore/clearAppState', true, { root: true })
    },
    async getAppList (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/app/totalusers',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.apps.rows
      } catch (e) {
        console.error(e)
      }
    },
    async totalCampaigns (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/total/campaign/users/type',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.users.rows
      } catch (e) {
        console.error(e)
      }
    },
    async totalChannels (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/total/conversations/channel?periodDays=5000',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.conversations.rows
      } catch (e) {
        console.error(e)
      }
    },
    setUser (context, user: User) {
      context.commit('SET_KEYBE_USER', user)
    },
    setUserExtradata (context, extradata) {
      const newDialogExists = extradata.filter(
        (extra) => extra.property === 'Accept terms feb 2023'
      )
      if (newDialogExists.length > 0) {
      } else {
        store.dispatch('ConversationStore/setWebinarPopup', true)
        store.dispatch('ConversationStore/setShowTemporalWebinarPopUp', true)
      }
      context.commit('SET_USER_EXTRADATA', extradata)
    },
    setDeviceToken (context, token) {
      context.commit('SET_DEVICE_TOKEN', token)
    },
    setSelectedUserApp (context, app) {
      context.commit('SET_SELECTED_USER_APP', app)
    }
  },
  getters: {
    getSelectedUserApp (state) {
      return state.selectedUserApp
    },
    getUserApps (state: { [key: string]: any }) {
      return state.user?.userApps
    },
    isAdmin (state: { [key: string]: any }) {
      const app = state.selectedUserApp
      if (app) {
        return app.role === AdminTypes.ADMIN
      }

      return state.user?.type === AdminTypes.ADMIN
    },
    isSuperAdmin (state: { [key: string]: any }) {
      return state.user?.isSuperAdmin === 1
    },
    isAgent (state: { [key: string]: any }) {
      const app = state.selectedUserApp
      if (app) {
        return app.role === AdminTypes.AGENT
      }

      return state.user?.type === AdminTypes.AGENT
    },
    isCoordinator (state: { [key: string]: any }) {
      const app = state.selectedUserApp
      if (app) {
        return app.role === AdminTypes.COORDINATOR
      }

      return state.user?.type === AdminTypes.COORDINATOR
    },
    isAgentOrAbove (
      _: any,
      getters: {
        isCoordinator: boolean,
        isSuperAdmin: boolean,
        isAdmin: boolean,
        isAgent: boolean
      }
    ) {
      return (
        getters.isAgent ||
        getters.isCoordinator ||
        getters.isSuperAdmin ||
        getters.isAdmin
      )
    },
    isCoordinatorOrAbove (
      _: any,
      getters: {
        isCoordinator: boolean,
        isSuperAdmin: boolean,
        isAdmin: boolean
      }
    ) {
      return getters.isCoordinator || getters.isSuperAdmin || getters.isAdmin
    },
    isCoordinatorOrBelow (state, getters) {
      return getters.isAgent || getters.isCoordinator
    },
    isTrainer (state: { [key: string]: any }) {
      const app = state.selectedUserApp
      if (app) {
        return app.role === AdminTypes.TRAINER
      }

      return state.user?.type === AdminTypes.TRAINER
    },
    isAdminOrAbove (state: { [key: string]: any }, getters) {
      return getters.isSuperAdmin || getters.isAdmin
    },
    getUserType (state: { [key: string]: any }): string {
      const app = state.selectedUserApp
      return app?.role || state.user?.type || ''
    },
    getUser (state) {
      return state.user
    },
    getToken (state: {[key: string]: any}) {
      return state.token
    },
    getV4Token (state) {
      return state.v4Token
    },
    getPhone (state: any): string {
      return state.phone ?? ''
    },
    getCountry (state: any): string {
      return state.country ?? ''
    },
    getMetricsToken (state) {
      return state.v4Token
    },
    getSelectedHeaders (state) {
      return state.selectedPeopleHeaders
    },
    getAllHeaders (state) {
      return state.allHeaders
    },
    // has end conversation role
    hasEndConversationRole (state, getters) {
      return getters.isAgentOrAbove
    },
    hasTransferConversationRole (state, getters) {
      return getters.isAgentOrAbove
    },
    hasTakeConversationFromAgentRole (state, getters) {
      return getters.isCoordinatorOrAbove
    },
    hasTakeConversationFromBot (state, getters) {
      return getters.isCoordinatorOrAbove
    },
    hasRoleConversations (state, getters) {
      return getters.isAgentOrAbove
    },
    hasAllConversationsRole (state, getters) {
      return getters.isCoordinatorOrAbove || getters.isTrainer
    },
    hasSendAudioRole (state, getters) {
      return getters.isAgentOrAbove
    },
    hasSendImageRole (state, getters) {
      return getters.isAgentOrAbove
    },
    hasSendPdfRole (state, getters) {
      return getters.isAgentOrAbove
    },
    getDeviceToken (state) {
      return state.deviceToken
    },
    getIsLoggedIn (state) {
      return state.isLoggedIn
    },
    getDarkModeActive (state) {
      return state.darkMode
    },
    hasWaitingChatsRole (state, getters) {
      return getters.isAgentOrAbove
    },
    getUserExtradata (state) {
      return state.userExtradata
    }
  }
}
