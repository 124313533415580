import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions } from '@/shared/infrastructure/http/HttpClient'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { dataToAddUserToBrand, dataToGenerateCommission, dataToGetCommissionByUser, dataToGetInvoices, dataToGetPartnerCategories, dataToGetUserPartners, dataToPayCommissionByUser, dataToRemoveUserFromBrand } from '../../domain/FeesController'
import { TokenModel } from '@/shared/domain/TokenModel'
import { Params } from '@/modules/consumptions/domain/BillingController'

export default class FeesRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  async getUserPartners (data: dataToGetUserPartners): Promise<GenericResponse> {
    const { page, limit, search } = data

    const url = `${process.env.VUE_APP_FEES_URL}/user`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    let query = {}
    if (page && limit) {
      query = {
        page,
        limit
      }
    }

    if (search) {
      query = {
        ...query,
        search
      }
    }

    const requestOptions: getOptions = {
      headers,
      url,
      query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getPartnerCategories (data: dataToGetPartnerCategories): Promise<GenericResponse> {
    const { page, limit, search } = data

    const url = `${process.env.VUE_APP_FEES_URL}/category`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    let query = {}
    if (page && limit) {
      query = {
        page,
        limit,
        search
      }
    }

    const requestOptions: getOptions = {
      headers,
      url,
      query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async addUserToBrand (data: dataToAddUserToBrand): Promise<boolean> {
    try {
      const { updateInput, uuid } = data
      const url = `${process.env.VUE_APP_FEES_URL}/app/${uuid}/add`
      const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(updateInput),
        headers: headers || undefined,
        signal: this.getSuggestionsAbortController?.signal
      })
      await response
      return true
    } catch (e) {
      console.error(e)
    }
  }

  async removeUserFromBrand (data: dataToRemoveUserFromBrand): Promise<boolean> {
    const { uuid, category } = data
    const url = `${process.env.VUE_APP_FEES_URL}/app/${uuid}/user/${category}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const response = await fetch(url, {
      method: 'DELETE',
      headers: headers || undefined,
      signal: this.getSuggestionsAbortController?.signal
    })
    await response
    return true
  }

  async getFeesData (data: TokenModel): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_FEES_URL}/fees`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getInvoices (data: dataToGetInvoices): Promise<GenericResponse> {
    const { search, pagination, status, filterDate } = data

    const query: Params = {
      page: pagination.page,
      itemsPerPage: pagination.limit,
      search: search,
      descending: false,
      status: status,
      details: true
    }

    if (filterDate) {
      query.dateFrom = filterDate.dateFrom
      query.dateTo = filterDate.dateTo
    }

    const url = `${process.env.VUE_APP_BILLING_URL}invoices`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async getCommissionByUser (data: dataToGetCommissionByUser): Promise<GenericResponse> {
    const { userId, month } = data
    const url = `${process.env.VUE_APP_FEES_URL}/commissions?userId=${userId}&month=${month}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async payCommissionByUser (params: dataToPayCommissionByUser): Promise<GenericResponse> {
    const {
      state,
      commissionId
    } = params
    const url = `${process.env.VUE_APP_FEES_URL}/commissions/${commissionId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(params)
    const requestData = {
      url,
      data: {
        state
      },
      headers
    }
    return await this.client.put(requestData)
  }

  async generateCommission (data: dataToGenerateCommission): Promise<GenericResponse> {
    const { year, month } = data
    const url = `${process.env.VUE_APP_FEES_URL}/commissions/generate?month=${month}&year=${year}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
