<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.13311 8.98763C6.60856 8.38717 6.09069 7.79421 5.57059 7.20125C3.79424 5.17254 2.01678 3.14489 0.242653 1.11403C-0.0702964 0.755899 -0.0669554 0.421354 0.233743 0.171517C0.541124 -0.085825 0.875234 -0.0558017 1.18039 0.276599C1.57352 0.705502 1.95218 1.14513 2.33752 1.58047C4.19406 3.68424 6.05059 5.78694 7.90602 7.89071C7.93609 7.92395 7.96838 7.95505 8.01182 7.99901C8.76913 7.14228 9.52088 6.29305 10.2704 5.44274C11.7783 3.73356 13.283 2.02331 14.7931 0.316272C15.1684 -0.108342 15.6496 -0.0933308 15.9146 0.337718C16.0627 0.577904 16.0215 0.823451 15.7643 1.11939C14.8934 2.11874 14.0191 3.11487 13.146 4.11207C11.7293 5.73011 10.3116 7.34922 8.88496 8.97798C8.92282 9.03374 8.95178 9.08843 8.99076 9.13346C11.1847 11.6404 13.381 14.1473 15.5761 16.6543C15.6663 16.7572 15.762 16.8548 15.8411 16.9652C16.0795 17.2955 16.0427 17.6129 15.7476 17.8477C15.4558 18.0804 15.1272 18.0493 14.8444 17.733C14.0024 16.7915 13.1694 15.8447 12.3341 14.8979C10.8985 13.2713 9.46408 11.6436 8.00625 9.9902C7.55631 10.4995 7.11529 10.9981 6.67538 11.4967C4.86116 13.5522 3.04806 15.6099 1.2305 17.6633C0.868552 18.0718 0.511054 18.1051 0.191423 17.7823C-0.031317 17.5582 -0.0714101 17.2955 0.13351 17.0296C0.420845 16.6575 0.740476 16.3079 1.0512 15.953C3.03581 13.6841 5.02265 11.4174 7.00726 9.14847C7.04847 9.10237 7.08299 9.0509 7.13311 8.98763Z" fill="#000000"/>
  </svg>
</template>

<script>
export default {
  name: 'ClosedIcon'
}
</script>

<style>

</style>
