import { UploadFilesService } from '@/components/shared/services/UploadFilesService'
import QuickResponsesUseCases from '../../../application/QuickResponsesUseCases'
import { QuickResponse, QuickResponseTypeEnum } from '../../../domain/QuickResponse'
import { dataToCreateQuickResponse } from '../../../domain/QuickResponsesController'
import QuickResponsesController from '../../controllers/QuickResponses.controller'
import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import EmitToast from '@/utils/EmitToast'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import KeybeDropbox from '@/shared/infrastructure/ui/dropbox/KeybeDropbox.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'

export default {
  components: {
    KBTextarea,
    KeybeDropbox,
    KBButton
  },
  props: {
    quickResponses: {
      type: Array
    },
    userResponses: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly'])
  },
  data () {
    return {
      newResponse: '',
      loading: false,
      QuickResponseTypeEnum,
      quickResponsesController: new QuickResponsesController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    longFile (file): boolean {
      const limit = 16777216
      if (file.size > limit) {
        return true
      }
      return false
    },
    isImageFile (file): boolean {
      return /\.(jpe?g|png)$/i.test(file.name)
    },
    isVideoFile (file): boolean {
      return /\.(mp4)$/i.test(file.name)
    },
    isPDFFile (file): boolean {
      return /\.(pdf)$/i.test(file.name)
    },
    validateFiles (files: any[]): boolean {
      if (files.length === 0) return false

      if (files.length > 30) {
        EventBus.$emit('toast', 'error', this.$t('cantSendOver30Files'))
        return false
      }

      const fileTypes = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileType = file.type.split('/')[0]
        if (!fileTypes.includes(fileType)) {
          fileTypes.push(fileType)
        }
      }

      if (fileTypes.length > 1) {
        EventBus.$emit('toast', 'error', this.$t('cantSendDifferentTypes'))
        return false
      }

      for (let i = 0; i < files.length; i++) {
        if (this.longFile(files[i])) {
          EventBus.$emit('toast', 'error', this.$t('cantSendFilesOver16mbGeneral'))
          return false
        }
      }

      if (!this.isPDFFile(files[0]) && !this.isVideoFile(files[0]) && !this.isImageFile(files[0])) {
        EventBus.$emit('toast', 'error', this.$t('invalidFileType'))
        return false
      }

      return true
    },
    checkResponseExists (): boolean {
      const filteredTextResponses: QuickResponse[] = this.quickResponses.filter(
        (quick: QuickResponse) => quick.type === QuickResponseTypeEnum.TEXT
      )

      const foundResponse: QuickResponse = filteredTextResponses.find(
        (quick: QuickResponse) => quick.value === this.newResponse
      )
      if (foundResponse) return true
      return false
    },
    async createTextResponse (): Promise<void> {
      if (this.newResponse) {
        const responseExists: boolean = this.checkResponseExists()
        if (!responseExists) {
          this.loading = true
          const data: dataToCreateQuickResponse = {
            token: this.token,
            createInput: {
              appUUID: this.selectedApp?.uuid,
              value: this.newResponse,
              type: QuickResponseTypeEnum.TEXT,
              name: this.newResponse
            }
          }
          if (this.userResponses) {
            data.createInput.user = this.user?.userId
            data.createInput.userId = this.user?.userId
          }
          await this.createResponse(data)
          await this.writeActivityLog()
        } else EmitToast.emitErrors(this.$t('aQuickResponseWithThisValueAlreadyExists'))
      } else EmitToast.emitErrors(this.$t('quickResponseMustNotBeEmpty'))
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.QUICK_REPLIES,
          action: ActivityActionTypeEnum.QUICK_REPLY_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.newResponse,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async writeActivityLogMedia (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.QUICK_REPLIES,
          action: ActivityActionTypeEnum.QUICK_REPLY_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async createResponse (data: dataToCreateQuickResponse): Promise<void> {
      const response = await QuickResponsesUseCases.createQuickResponse(this.quickResponsesController, data)
      if (!response.status) {
        EmitToast.emitErrors(response.message)
      }
      this.loading = false
      await this.writeActivityLogMedia(data.createInput.name)
      this.$emit('getQuickResponses')
    },
    getQuickResponseType (file): string {
      if (this.isPDFFile(file)) {
        return QuickResponseTypeEnum.PDF.toString()
      }
      if (this.isVideoFile(file)) {
        return QuickResponseTypeEnum.VIDEO.toString()
      }
      if (this.isImageFile(file)) {
        return QuickResponseTypeEnum.IMAGE.toString()
      }
      return QuickResponseTypeEnum.TEXT.toString()
    },
    async dropQuickResponse (e: any): Promise<void> {
      if (this.isUserReadonly) return
      this.loading = true
      const files = e?.dataTransfer?.files
      if (!this.validateFiles(files)) return
      const response = await UploadFilesService.uploadFile({
        totalFiles: files,
        roomId: 'quick-responses',
        onUploadProgress: (e) => {
        }
      })
      if (!response.status) {
        EmitToast.emitErrors(this.$t('errorUpload'))
        return
      }
      const { urls, mimetype } = response?.data?.data
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i]
        const file = files[i]
        const data: dataToCreateQuickResponse = {
          token: this.token,
          createInput: {
            appUUID: this.selectedApp?.uuid,
            value: url,
            mimetype: mimetype,
            type: this.getQuickResponseType(file),
            name: file?.name
          }
        }

        if (this.userResponses) {
          data.createInput.user = this.user?.userId
          data.createInput.userId = this.user?.userId
        }

        await this.createResponse(data)
      }
    }
  },
  beforeDestroy () {
    QuickResponsesUseCases.abort(this.quickResponsesController)
  }
}
