import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import { formatCurrency } from '@/modules/consumptions/application/FormatCurrency'
import { EventBus } from '@/eventBus'
import { mapActions, mapState, mapGetters } from 'vuex'
import { PaymentService, CURRENCY_TYPES, PAYMENT_TYPES } from '@/components/configuration/Services/PaymentService'
import BillingService from '@/components/configuration/Services/BillingService'
import RocketSVG from '@/shared/assets/RocketSVG.vue'
import { loadStripe } from '@stripe/stripe-js'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBCashCard from '../KBCashCard.vue'
import BillingUseCase from '@/modules/consumptions/application/BillingUseCase'
import BillingController from '@/modules/consumptions/infrastructure/controllers/Billing.controller'
import { dataToGetIntentSetup } from '@/modules/consumptions/domain/BillingController'
import BillingPlansCardsInfo from '@/modules/consumptions/infrastructure/ui/components/BillingPlansCardsInfo/BillingPlansCardsInfo.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton'
import { appUpdater } from '@/modules/Apps/domain/AppsController'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import EmitToast from '@/utils/EmitToast'
import link from '@/utils/links'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'

export default {
  name: 'BillingAddCard',
  components: {
    BillingPlansCardsInfo,
    Loader,
    KeybeButton,
    KBCashCard,
    RocketSVG
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    selectedCard: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      appsController: new AppsController(),
      ConsumptionsTabs,
      billingController: new BillingController(),
      loading: false,
      stripe: null,
      elements: null,
      cardElement: null,
      paymentIntent: null,
      clientSecretSetup: null,
      stripeElementStyles: {
        base: {
          color: '#000000',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          backgroundColor: '#ffffff',
          padding: '20px',
          border: '1px solid #cccccc',
          borderRadius: '25px'
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    }
  },
  methods: {
    formatCurrency,
    ...mapActions('BillingStore', ['setAppConfig']),
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    startLoading () {
      this.loading = true
      this.$emit('start-loading')
    },
    stopLoading () {
      this.$emit('stop-loading')
      this.loading = false
    },
    async showSuccess () {
      await this.updateBillingInfo()
      this.stopLoading()
      this.paymentIntent = null
      this.$emit('close')
      this.setActiveApp()
    },

    async continueIntent () {
      this.startLoading()
      const appearance = {}
      const paymentOptions = {
        style: this.stripeElementStyles
      }
      const response = await PaymentService.getConfig()
      if (response.status) {
        const publicKey = response.data
        this.stripe = await loadStripe(publicKey)
        if (!this.stripe) this.$emit('close')

        const payment: dataToGetIntentSetup = {
          type: PAYMENT_TYPES.BALANCE,
          total: this.amount * 100,
          currency: CURRENCY_TYPES.USD,
          uuid: '',
          description: `buy ${this.amount}${CURRENCY_TYPES.USD} to register`,
          token: this.token
        }
        this.paymentIntent = payment
        const paymentIntent = await BillingUseCase.getIntentSetup(
          this.billingController,
          payment
        )
        if (paymentIntent.status) {
          const clientSecret = paymentIntent.data.client_secret
          this.elements = await this.stripe.elements({ clientSecret, appearance })
          this.cardElement = this.elements.create('card', paymentOptions)
          this.cardElement.mount('#payment-element')
          this.clientSecretSetup = clientSecret
          setTimeout(() => {
            this.stopLoading()
          }, 3000)
          return
        }
      }
      this.$emit('close')
    },
    goTo (link: string) {
      if (Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({ path: this.$route.path, query: null })
      }
      if (this.$route.path === link) return

      this.$router.push(link)
    },
    async setActiveApp () {
      try {
        const app = {
          status: 'active',
          status_reason: 'add new payment method',
          uuid: this.appUUID
        }
        const data = {
          app: app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
        } else {
          this.goTo(link.conversations)
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onSubmit (event) {
      this.startLoading()
      const response = await this.stripe.confirmCardSetup(this.clientSecretSetup, {
        payment_method: {
          card: this.cardElement
        }
      })

      if (response?.error) {
        EventBus.$emit('toast', 'error', response?.error?.message)
        this.paymentIntent = null
        this.stopLoading()
        this.$emit('close')
        return
      }
      setTimeout(() => {
        this.showSuccess()
      }, 2000)
    }
  },
  computed: {
    ...mapState('UserStore', ['token', 'user']),
    ...mapGetters('AppStore', ['getSelectedApp']),
    appUUID () {
      return this.getSelectedApp.uuid ? this.getSelectedApp.uuid : null
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    }
  },
  async mounted () {
    this.continueIntent()
  }
}
