<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
    <path d="M2.89286 3.09375V4.125H6.10714V3.09375C6.10714 2.14414 5.38795 1.375 4.5 1.375C3.61205 1.375 2.89286 2.14414 2.89286 3.09375ZM1.60714 4.125V3.09375C1.60714 1.38574 2.9029 0 4.5 0C6.0971 0 7.39286 1.38574 7.39286 3.09375V4.125H7.71429C8.42344 4.125 9 4.7416 9 5.5V9.625C9 10.3834 8.42344 11 7.71429 11H1.28571C0.576563 11 0 10.3834 0 9.625V5.5C0 4.7416 0.576563 4.125 1.28571 4.125H1.60714Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'AppPrivacyIcon',
  props: {
    width: {
      type: Number,
      default: 13
    },
    height: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: '#B6BEC7'
    }
  }

}
</script>
