export const SalesFiltersStore = {
  namespaced: true,
  state: {
    salesFilters: {
      responsible: [],
      createdAt: {
        from: '',
        to: ''
      },
      min: '',
      max: '',
      extendedData: [],
      utm: {
        value: '',
        source: '',
        partner: '',
        campaign: ''
      },
      quoteDate: '',
      contacts: {
        status: [],
        phoneEmpty: null,
        emailEmpty: null,
        habeasDataStatus: '',
        identifier: '',
        documentType: '',
        document: '',
        country: '',
        province: '',
        city: '',
        zone: '',
        category: '',
        subCategory: '',
        tags: [],
        gender: '',
        extendedData: []
      },
      reason: ''
    },
    salesFiltersButtons: [
      { name: 'responsible', value: [] },
      { name: 'createdAt', value: '' },
      { name: 'min', value: '' },
      { name: 'max', value: '' },
      { name: 'extendedData', value: [] },
      { name: 'utmValue', value: '' },
      { name: 'utmSource', value: '' },
      { name: 'utmPartner', value: '' },
      { name: 'quoteDate', value: '' },
      { name: 'status', value: [] },
      { name: 'emailEmpty', value: null },
      { name: 'phoneEmpty', value: null },
      { name: 'habeasDataStatus', value: '' },
      { name: 'identifier', value: '' },
      { name: 'documentType', value: '' },
      { name: 'document', value: '' },
      { name: 'country', value: '' },
      { name: 'province', value: '' },
      { name: 'city', value: '' },
      { name: 'zone', value: '' },
      { name: 'category', value: '' },
      { name: 'subCategory', value: '' },
      { name: 'tags', value: '' },
      { name: 'gender', value: '' },
      { name: 'reason', value: '' }
    ],
    responsible: [],
    min: '',
    max: '',
    createdAt: {
      from: '',
      to: ''
    },
    extendedField: [],
    utm: {
      value: '',
      source: '',
      partner: ''
    },
    quoteDate: {
      from: '',
      to: ''
    },
    contacts: {
      status: [],
      phoneEmpty: null,
      emailEmpty: null,
      habeasDataStatus: '',
      identifier: '',
      documentType: '',
      document: '',
      country: '',
      province: '',
      city: '',
      zone: '',
      category: '',
      subCategory: '',
      tags: [],
      gender: '',
      extendedData: []
    },
    filtersActiveSales: false
  },
  mutations: {
    SET_ACTIVE_FILTERS (state: any, filters: any) {
      state.salesFilters = filters
    },
    SET_ACTIVE_FILTERS_BUTTONS (state: any, buttons: any) {
      state.salesFiltersButtons = buttons
    },
    SET_FILTERS_ACTIVE (state: any, active: boolean) {
      state.filtersActiveSales = active
    },
    SET_RESPONSIBLE (state: any, responsible: any) {
      state.responsible = responsible
    },
    SET_CREATED_AT_DATE (state: any, createdAt: any) {
      state.createdAt = createdAt
    },
    SET_MIN_AMOUNT (state: any, min: any) {
      state.min = min
    },
    SET_MAX_AMOUNT (state: any, max: any) {
      state.max = max
    },
    SET_EXTENDED_DATA (state: any, extendedData: any) {
      state.extendedData = extendedData
    },
    SET_EXTENDED_FIELD (state: any, extendedField: any) {
      state.extendedField = extendedField
    },
    SET_UTM (
      state: any,
      utm: { source: string, value: string, partner: string}
    ) {
      state.utm = utm
    },
    SET_QUOTE_DATE (state: any, quoteDate: any) {
      state.quoteDate = quoteDate
    },
    SET_STATUS (state: any, status: string[]) {
      state.salesFilters.contacts.status = status
    },
    CLEAR_FILTERS (state: any) {
      state.salesFilters = {
        responsible: [],
        createdAt: {
          from: '',
          to: ''
        },
        min: '',
        max: '',
        utm: {
          value: '',
          source: '',
          partner: ''
        },
        extendedData: [],
        quoteDate: {
          from: '',
          to: ''
        },
        contacts: {
          status: [],
          phoneEmpty: null,
          emailEmpty: null,
          habeasDataStatus: '',
          identifier: '',
          documentType: '',
          document: '',
          country: '',
          province: '',
          city: '',
          zone: '',
          category: '',
          subCategory: '',
          tags: [],
          gender: '',
          extendedData: []
        },
        reason: ''
      }

      state.salesFiltersButtons = [
        { name: 'responsible', value: [] },
        { name: 'createdAt', value: '' },
        { name: 'min', value: '' },
        { name: 'max', value: '' },
        { name: 'extendedData', value: [] },
        { name: 'utmValue', value: '' },
        { name: 'utmSource', value: '' },
        { name: 'utmPartner', value: '' },
        { name: 'quoteDate', value: '' },
        { name: 'status', value: [] },
        { name: 'emailEmpty', value: null },
        { name: 'phoneEmpty', value: null },
        { name: 'habeasDataStatus', value: '' },
        { name: 'identifier', value: '' },
        { name: 'documentType', value: '' },
        { name: 'document', value: '' },
        { name: 'country', value: '' },
        { name: 'province', value: '' },
        { name: 'city', value: '' },
        { name: 'zone', value: '' },
        { name: 'category', value: '' },
        { name: 'subCategory', value: '' },
        { name: 'tags', value: '' },
        { name: 'gender', value: '' },
        { name: 'reason', value: '' }
      ]

      state.responsible = []
      state.createdAt = {
        from: '',
        to: ''
      }
      state.min = ''
      state.max = ''
      state.extendedField = []
      state.utm = {
        value: '',
        source: '',
        partner: ''
      }
      state.quoteDate = {
        from: '',
        to: ''
      }
      state.contacts = {
        status: [],
        phoneEmpty: null,
        emailEmpty: null,
        habeasDataStatus: '',
        identifier: '',
        documentType: '',
        document: '',
        country: '',
        province: '',
        city: '',
        zone: '',
        category: '',
        subCategory: '',
        tags: [],
        gender: '',
        extendedData: []
      }

      state.filtersActiveSales = false
    }
  },
  actions: {
    setActiveFiltersSales ({ commit }, filters: any) {
      commit('SET_ACTIVE_FILTERS', filters)
    },
    setFiltersActiveSales ({ commit }, active: boolean) {
      commit('SET_FILTERS_ACTIVE', active)
    },
    clearSalesFilters ({ commit }) {
      commit('CLEAR_FILTERS')
    }
  }
}
