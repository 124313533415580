
import { mapState } from 'vuex'
import moment from 'moment'
import { EventBus } from '@/eventBus'
import { ImportsStatusEnum } from '@/modules/imports/domain/enums/ImportsStatusEnum'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import ImporterUseCases from '@/modules/imports/application/ImporterUseCases'
import ImporterController from '../../controllers/Importer.controller'
import SubscriptionOnImport from '@/modules/imports/infrastructure/graphql/subscription-on-import.graphql'
import EventsApolloProvider from '@/modules/event/Providers/EventsApolloProvider'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import CustomPagination from '@/components/shared/components/CustomPagination.vue'
import { dataToGetImports } from '@/modules/imports/domain/ImporterController'

enum ImportSubscriptionStatusEnum {
  LOAD = 'load',
  PRELOAD = 'preload'
}
export default {
  components: {
    CustomPagination,
    KeybeButton,
    Loader
  },
  props: {
    tab: {
      type: String
    },
    typeEntity: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    headers () {
      return [
        { text: this.$t(CDPFieldsEnums.NAME), value: 'title' },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('size'), value: 'size' },
        { text: this.$t('imported'), value: 'imported' },
        { text: this.$t('responsible'), value: 'responsible' },
        { text: this.$t('startedAt'), value: 'startedAt' },
        { text: this.$t('finishedAt'), value: 'finishedAt' },
        { text: '', value: 'start' }
      ]
    }
  },
  mounted () {
    this.getImports()
  },
  data () {
    return {
      offset: 0,
      limit: 25,
      pagination: {},
      imports: [],
      hoverItem: {},
      count: null,
      type: '',
      loadingGet: false,
      loadingImportStart: false,
      loadingImportIds: [],
      rerender: null,
      lasImportedProgress: null,
      eventsApolloClient: EventsApolloProvider.getInstance().provider.defaultClient,
      importerController: new ImporterController(),
      activityLogsController: new ActivityLogsController(),
      ImportSubscriptionStatusEnum
    }
  },
  methods: {
    showLoader (item) {
      return this.loadingImportStart && this.loadingImportIds.includes(item?._id)
    },
    async startImport (item) {
      this.loadingImportStart = true
      const data = {
        app: this.selectedApp?.uuid,
        typeEntity: this.typeEntity,
        id: item?._id,
        token: this.token
      }
      const response = await ImporterUseCases.startImport(this.importerController, data)
      if (!response) EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
      else {
        this.loadingImportIds.push(response)
        this.setImportStatus()
        this.writeActivityLog(item?.file?.name)
      }
    },
    async writeActivityLog (name: string): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CDP,
          action: ActivityActionTypeEnum.IMPORT_STARTED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    buttonText (status) {
      switch (status) {
        case ImportsStatusEnum.CREATED:
          return this.$t('start')
        case ImportsStatusEnum.QUEUED:
          return this.$t('queued')
        case ImportsStatusEnum.PROCESSING:
          return this.$t('processing')
        case ImportsStatusEnum.ERROR:
          return this.$t('error')
        case ImportsStatusEnum.FINISHED:
          return this.$t('finished')
        case ImportsStatusEnum.EXPIRED:
          return this.$t('expired')
      }
    },
    setImportStatus () {
      this.lasImportedProgress = this.eventsApolloClient
        .subscribe({
          query: SubscriptionOnImport,
          variables: {
            appUuid: this.selectedApp?.uuid
          }
        })
        .subscribe((res) => {
          const importId = res?.data?.onImport?.id
          const foundImportIndex = this.imports.map(i => i?._id).indexOf(importId)
          const status = res?.data?.onImport?.progress?.state || ''
          if (status === ImportSubscriptionStatusEnum.LOAD && this.imports[foundImportIndex]) {
            this.imports[foundImportIndex].status = ImportsStatusEnum.FINISHED
            setTimeout(() => {
              this.loadingImportIds = this.loadingImportIds.filter(id => id !== importId)
              this.loadingImportStart = false
              this.rerender++
            }, 300)
          } else if (status === ImportSubscriptionStatusEnum.PRELOAD && this.imports[foundImportIndex]) {
            this.imports[foundImportIndex].status = ImportsStatusEnum.CREATED
            this.loadingImportStart = false
            this.rerender++
          }
        })
    },
    importResponsible (item) {
      return item?.responsible?.name || ' - '
    },
    startedAt (item) {
      if (item?.createdAt) return moment(item?.createdAt).locale('es').format('DD/MM/YY HH:mm')
      else return ''
    },
    finishedAt (item) {
      return item?.finishedAt ? moment(item?.finishedAt).locale('es').format('ll') : ' - '
    },
    importName (item) {
      return item?.file?.name || ''
    },
    importAmount (item) {
      return item?.records?.total || 0
    },
    importSize (item) {
      const k = 1024
      const bytes = item?.file?.size
      const decimals = 2
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    highlightClass (item) {
      if (this.$vuetify.theme.dark) return this.hoverItem._id === item._id ? 'offBlack text--text' : 'transparent'
      else return this.hoverItem._id === item._id ? 'hovered-row' : 'transparent'
    },
    async getImports () {
      try {
        this.loadingGet = true
        const data: dataToGetImports = {
          app: this.selectedApp?.uuid,
          token: this.token,
          typeEntity: this.typeEntity,
          offset: this.offset,
          limit: this.limit
        }
        const response = await ImporterUseCases.getImports(this.importerController, data)
        if (response?.status) {
          this.imports = response?.data?.data
          this.count = response?.data?.paginator?.totalDocs
          this.pagination = response?.data?.paginator
        } else EventBus.$emit('toast', 'error', response)
        if (this.imports.length > 0) {
          this.closeSubscriptions()
          this.setImportStatus()
        }
        this.loadingGet = false
      } catch (e) {
        console.error(e)
        this.loadingGet = false
      }
    },
    openDetails (item) {
      this.$emit('openImportDetail', item)
    },
    closeSubscriptions () {
      if (this.lasImportedProgress) {
        this.lasImportedProgress.unsubscribe()
      }
    },
    setPagination (e: any) {
      this.offset = e?.page
      this.limit = e?.items
      this.getImports()
    }
  },
  watch: {
    typeEntity () {
      this.getImports()
    }
  },
  beforeDestroy () {
    this.closeSubscriptions()
  }
}
