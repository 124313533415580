import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { Template, TemplateBody, TemplateButton, TemplateButtonTypesEnum, TemplateButtons, TemplateComponentsEnum, TemplateFooter, TemplateHeader, TemplateHeaderFormatEnum } from '../domain/Template'
import { ITemplatesController, dataToBuildTemplateBody, dataToBuildTemplateButton, dataToBuildTemplateButtons, dataToBuildTemplateFooter, dataToBuildTemplateHeader, dataToCreateTemplate, dataToGetTemplates, dataToDeleteTemplates, templateCreated } from '../domain/TemplateController'
import TemplateRepository from './Template.repository'

export default class TemplateController implements ITemplatesController {
  repository: TemplateRepository

  constructor () {
    this.repository = new TemplateRepository()
  }

  async createTemplate (data: dataToCreateTemplate): Promise<templateCreated> {
    const response: GenericResponse = await this.repository.createTemplate(data)
    return {
      status: response.status,
      message: response.data.message || response.message || ''
    }
  }

  buildTemplateHeader (data: dataToBuildTemplateHeader): TemplateHeader {
    const { format, text, example } = data
    if (format === TemplateHeaderFormatEnum.TEXT) {
      return {
        type: TemplateComponentsEnum.HEADER,
        format,
        text,
        example
      }
    }

    return {
      type: TemplateComponentsEnum.HEADER,
      format,
      example
    }
  }

  buildTemplateBody (data: dataToBuildTemplateBody): TemplateBody {
    return {
      type: TemplateComponentsEnum.BODY,
      text: data.text,
      example: data.example
    }
  }

  buildTemplateFooter (data: dataToBuildTemplateFooter): TemplateFooter {
    return {
      type: TemplateComponentsEnum.FOOTER,
      text: data.text
    }
  }

  buildTemplateButton (data: dataToBuildTemplateButton): TemplateButton {
    // eslint-disable-next-line camelcase
    const { type, text, url, phone_number } = data

    switch (type) {
      case TemplateButtonTypesEnum.PHONE_NUMBER:
        return {
          type,
          phone_number,
          text
        }
      case TemplateButtonTypesEnum.URL:
        return {
          type,
          url,
          text
        }
      default:
        return {
          type,
          text
        }
    }
  }

  buildTemplateButtons (data: dataToBuildTemplateButtons): TemplateButtons {
    return {
      type: TemplateComponentsEnum.BUTTONS,
      buttons: data.buttons
    }
  }

  async getTemplates (data: dataToGetTemplates): Promise<Template[]> {
    const response = await this.repository.getTemplates(data)

    return response?.data?.items || []
  }

  async deleteTemplate (data: dataToDeleteTemplates): Promise<boolean> {
    const response = await this.repository.deleteTemplate(data)
    console.log(response)

    return response?.data?.items || []
  }
}
