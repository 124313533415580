import { MicrositesTabs } from '@/modules/microsites/infrastructure/enums/MicrositesTabs'
import { mapState } from 'vuex'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'

import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'

export default {
  name: 'MicrositesSidebar',
  components: {
    KeybeAvatar,
    KeybeIcon
  },
  props: {
    selectedTab: {
      type: String,
      required: true
    },
    micrositeImg: {
      type: String,
      required: true,
      default: ''
    },
    micrositeUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  watch: {},
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    loading: {
      get () {
        return this.$store.state.MetricsStore.loading || false
      },
      set (value) {
        this.$store.commit('MetricsStore/SET_EDITING_OBJECT', {
          key: 'loading',
          value
        })
      }
    },
    appName () {
      return this.selectedApp?.name || ''
    }
  },
  methods: {
    isSelectedTab (tab: string) {
      return this.selectedTab === tab
    },
    selectTab (tab: string): void {
      if (this.selectedTab === tab) return
      if (tab === MicrositesTabs.ACTIVE_SITE) {
        window.open(this.micrositeUrl, '_blank')
        return
      }
      this.$emit('selectTab', tab)
    }
  },
  data () {
    return {
      MicrositesTabs,
      KeybeIconType
    }
  },
  mounted () {
    console.log('Receing profile photo', this.micrositeImg)
  }
}
