






















































































































































































import InvoicesDetails from './InvoicesDetails'
export default InvoicesDetails
