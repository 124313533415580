

















import Vue from 'vue'

export default Vue.extend({
  props: ['value', 'label', 'small', 'color', 'borderless', 'disabled'],
  data () {
    return {
      switchValue: this.value
    }
  },
  methods: {
    setValue () {
      this.$emit('input', !this.switchValue)
    }
  },
  watch: {
    value (val) {
      this.switchValue = val
    }
  }
})
