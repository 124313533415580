import { ICardsController, dataToCreateCard, dataToUpdateCard, dataToGetDeleteCard, dataToGetCards, cardsGetter, cardGetter, cardsUpdater, dataToGetCardsFromContact, cardsContactGetter, dataToMoveContacts, cardsDeleted, dataToGetEndReaseon, EndReadonGetter, dataToCheckCardExpiration } from '../../domain/models/CardsController'
import CardsRepository from '../repository/Cards.repository'

export default class CardsController implements ICardsController {
  repository: CardsRepository

  constructor () {
    this.repository = new CardsRepository()
  }

  async getCards (data: dataToGetCards) : Promise<cardsGetter> {
    const response = await this.repository.getCards(data)
    return {
      data: {
        data: response?.data?.data,
        paginator: response?.data?.paginator
      },
      message: response?.message,
      status: response?.status
    }
  }

  async getCardsFromContact (data: dataToGetCardsFromContact) : Promise<cardsContactGetter> {
    const response = await this.repository.getCardsFromContact(data)
    return response
  }

  async getCard (data: dataToGetDeleteCard) : Promise<cardGetter> {
    const response = await this.repository.getCard(data)
    return response
  }

  async createCard (data: dataToCreateCard): Promise<cardsUpdater> {
    const response = await this.repository.createCard(data)
    return response
  }

  async updateCard (data: dataToUpdateCard): Promise<cardsUpdater> {
    const response = await this.repository.updateCard(data)
    return response
  }

  async deleteCard (data: dataToGetDeleteCard): Promise<cardsDeleted> {
    const response = await this.repository.deleteCard(data)
    return response
  }

  async moveContacts (data: dataToMoveContacts): Promise<{ status: boolean; message: string; }> {
    const response = await this.repository.moveContacts(data)
    return { status: response?.status, message: response?.message }
  }

  isCardExpired (data: dataToCheckCardExpiration): boolean {
    const expireAfterDays = data.cardStepExpiration || 0
    const cardInStepSince = new Date(data.cardUpdatedAtStep)
    const currentDate = new Date()
    cardInStepSince.setDate(cardInStepSince.getDate() + expireAfterDays)
    return data.cardStepExpiration ? currentDate > cardInStepSince : false
  }

  async getTerminationReason (data: dataToGetEndReaseon): Promise<EndReadonGetter> {
    const response = await this.repository.getTerminationReason(data)
    return response
  }
}
