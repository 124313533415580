import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { IBillingController, billingDataGetter, billingIntentSetupGetter, dataToGetBillingReport, dataToGetIntentSetup } from '../../domain/BillingController'
import BillingRepository from '../repository/Billing.repository'

export default class BillingController implements IBillingController {
    repository: BillingRepository

    constructor () {
      this.repository = new BillingRepository()
    }

    abort (): void {
      this.repository.abort()
    }

    async getIntentSetup (data: dataToGetIntentSetup): Promise<billingIntentSetupGetter> {
      const response: GenericResponse = await this.repository.getIntentSetup(data)

      return {
        status: response?.data?.success || response?.status || false,
        data: response?.data || ''
      }
    }

    async getBillingReport (data: dataToGetBillingReport): Promise<billingDataGetter> {
      const response: GenericResponse = await this.repository.getBillingReport(data)

      return {
        success: response?.data?.success || response?.status || false,
        message: response?.data?.message || '',
        downloadUrl: response?.data?.downloadUrl || '',
        result: response?.data?.result || null
      }
    }
}
