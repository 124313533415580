import { EventBus } from '@/eventBus'
import MicrositesService from '@/services/MicrositesService'
import Colors from '@/shared/domain/colors/Color'
// components
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'

export default {
  components: {
    KeybeIcon,
    KeybeText
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    paginationDots () {
      return this.pictures.length < 6 ? 1 : Math.ceil(this.pictures.length / 6)
    },
    paginatedPictures () {
      return this.pictures.slice((this.selectedPage) * 6, (this.selectedPage + 1) * 6)
    },
    firstPicturesPage () {
      return this.pictures.slice(0, 6)
    }
  },
  data () {
    return {
      pictures: [],
      selectedPage: 0,
      hovered: null,
      Colors,
      description: ''
    }
  },
  mounted () {
    this.pictures = this.value
  },
  methods: {
    openFileInput () {
      if (!this.description) {
        EventBus.$emit('toast', 'error', this.$t('pleaseAddDescription'))
        return
      }
      this.$refs.imageInput.click()
    },
    async onFilePicked (event) {
      this.loading = true
      const imgs = event.target.files
      for (let i = 0; i < imgs.length; i++) {
        // validate size is less than 5MB
        if (imgs[i].size > 5000000) {
          EventBus.$emit('toast', 'error', this.$t('sizeOfFileCantExceed', { size: 5, units: 'mb' }))
          this.loading = false
          return
        }
        const response = await MicrositesService.uploadImage(imgs[i])
        if (response.status) {
          EventBus.$emit('toast', 'success', this.$t('successUploadingPicture'))
          this.pictures.push({
            url: response.data.url,
            description: this.description
          })
          this.description = ''
        } else {
          EventBus.$emit('toast', 'error', this.$t('errorUploadingPicture'))
        }
      }

      this.emitChange()

      this.loading = false
    },
    removeImage (index) {
      this.pictures.splice(index, 1)
      this.emitChange()
    },
    emitChange () {
      this.$emit('input', this.pictures)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.pictures = value
      },
      immediate: true
    }
  }
}
