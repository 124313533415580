import { IChannelsController, channelDisconnect, dataToDisconnectChannel } from '../../domain/ChannelsController'
import ChannelsRepository from '../repository/Channels.repository'

export default class ChannelsController implements IChannelsController {
  repository: ChannelsRepository

  constructor () {
    this.repository = new ChannelsRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async disconnectChannel (data: dataToDisconnectChannel) : Promise<channelDisconnect> {
    const response = await this.repository.disconnectChannel(data)
    return response
  }
}
