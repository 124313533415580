import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'
import { Money } from 'v-money'
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String
    }
  },
  components: {
    Money
  },
  mounted () {
    this.setValue()
    this.setCurrency()
    if (!this.currency) {
      this.money.prefix = `${this.getCurrencyApp} $`
    }
    if (this.getCurrencyApp === CurrencyEnum.COP || this.currency === CurrencyEnum.COP) {
      this.money.precision = 0
      this.money.thousands = '.'
      this.money.decimal = ''
    }

    if (this.getCurrencyApp === CurrencyEnum.CRC || this.currency === CurrencyEnum.CRC) {
      this.money.prefix = '₡ '
      this.money.thousands = '.'
      this.money.decimal = ','
    }
  },
  data () {
    return {
      text: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: 'USD $',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getCurrencyApp'])
  },
  methods: {
    setCurrency (): void {
      if (this.currency) {
        this.money.prefix = this.currency + ' $'
        if (this.currency === CurrencyEnum.COP) {
          this.money.precision = 0
          this.money.thousands = '.'
          this.money.decimal = ''
        }
        if (this.currency === CurrencyEnum.CRC) {
          this.money.prefix = '₡ '
          this.money.thousands = '.'
          this.money.decimal = ','
        }
      }
      if (this.getCurrencyApp === CurrencyEnum.COP) {
        this.money.precision = 0
        this.money.thousands = '.'
        this.money.decimal = ''
      }
      if (this.getCurrencyApp === CurrencyEnum.CRC) {
        this.money.prefix = '₡ '
        this.money.thousands = '.'
        this.money.decimal = ','
      }
    },
    setValue (): void {
      if (this.value) {
        if (!this.value.toString().includes('.') && this.getCurrencyApp !== CurrencyEnum.COP) {
          this.text = this.value.toString() + '.00'
          return
        }
        this.text = this.value.toString()
      }
    },
    input (): void {
      const formatted: number = this.formatValue()
      this.$emit('input', formatted)
    },
    formatValue (): number {
      // eslint-disable-next-line no-undef
      const text: string = structuredClone(this.text).toString()
      const splitted: string[] = text.split('.')
      if (splitted[1] === '00') text.replace('.00', '')
      return +text
    }
  },
  watch: {
    text (val: string) {
      if (val !== this.value) this.input()
    },
    value: {
      immediate: true,
      handler () {
        if (!this.text) this.setValue()
      }
    },
    currency (val: string) {
      if (!this.money.prefix.includes(val)) this.setCurrency()
    }
  }
}
