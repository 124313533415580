import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export default class FeesServices {
  static async isCommissionAgent () {
    try {
      const url = `${process.env.VUE_APP_FEES_URL}/user/check`
      const token = store.getters['UserStore/getV4Token']
      const user = store.getters['UserStore/getUser']
      const email = user?.email || null
      if (!email) return
      const response = await axios.post(url, { email: email },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async updateInactive (model) {
    try {
      const output = {
        inactive: model.inactive,
        reason: model.reason
      }
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${model.uuid}/inactive`
      const response = await axios.put(url, output, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return response
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async updateInactiveConfig (model) {
    try {
      const output = {
        inactive: model.inactive
      }
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${model.uuid}`
      const response = await axios.put(url, output, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return response
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getCompany (search: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            itemsPerPage: pagination.limit,
            search: search,
            descending: true
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getFeesCommision (search: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/fees/commisions`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            itemsPerPage: pagination.limit,
            search: search,
            descending: false
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getServices (search: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}charge-types/`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            itemsPerPage: pagination.limit,
            search: search
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async sendFeesEmail (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/user/request`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async sendReport (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/fees/report`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getCommission (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/fees`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getFees (search: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/app`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            limit: pagination.limit,
            search: search
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getCategoryFees (search: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/category`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            limit: pagination.limit
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async getSingleFees (uuid: string, pagination: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/app/${uuid}`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            page: pagination.page,
            limit: pagination.limit
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async addUserToApp (uuid: string, data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/app/${uuid}/add`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async postCreateApp (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/app/create`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async postCreateUser (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/user/create`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async postCategoryFees (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/category/create`
      const response = await axios.post(url,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      const errorResponse = new RestResponseFactory().process({ response: { staus: 500 } })
      errorResponse.data = e.response.data
      return errorResponse
    }
  }

  static async updateUser (model) {
    try {
      const output = {
        surname: model.surname || null,
        name: model.name || null,
        phone: model.phone || null,
        email: model.email || null
      }
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_FEES_URL}/user/${model._id}`
      const response = await axios.put(url, output, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      // store.commit('CompanyStore/SET_ADMINISTRATORS', response.data.data.users.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      return new RestResponseFactory().process({ response }).data.data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getFeesUser (pagination) {
    try {
      const url = `${process.env.VUE_APP_FEES_URL}/user`
      const token = store.getters['UserStore/getV4Token']

      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token
        },
        params: {
          page: pagination.page - 1,
          sortBy: pagination.sortBy,
          descending: pagination.sortDesc,
          limit: pagination.limit
        }
      })
      // store.commit('CompanyStore/SET_ADMINISTRATORS', response.data.data.users.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      return new RestResponseFactory().process({ response }).data.data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async getAdministratorsUser (appId, pagination) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}user/list/${appId}`
      const token = store.getters['UserStore/getV4Token']
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token
        },
        params: {
          page: pagination.page - 1,
          sortBy: pagination.sortBy,
          descending: pagination.sortDesc,
          limit: pagination.limit
        }
      })
      store.commit('CompanyStore/SET_ADMINISTRATORS', response.data.data.users.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      return new RestResponseFactory().process({ response }).data.data
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }
}
