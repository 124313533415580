import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import ShortenerUseCases from '@/shared/application/ShortenerUseCases'
import ShortenerController from '@/shared/infrastructure/controllers/Shortener.controller'
export default {
  components: {
    KBText,
    KBButton,
    KeybeIcon
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    this.createUrl()
  },
  data () {
    return {
      KeybeIconType,
      urlSingUp: 'https://v6.keybe.app/signup',
      utm_source: 'Referral-partner',
      utm_medium: '',
      utm_campaign: 'referral-ui',
      utm_term: '',
      utm_content: '',
      urlFormart: '',
      utm_partner: '',
      shortenerController: new ShortenerController()
    }
  },
  methods: {
    async createUrl (): Promise<void> {
      this.utm_medium = this.user?.name
      this.utm_term = this.user?.userId
      this.utm_content = this.selectedApp?.uuid
      this.utm_partner = this.selectedApp?.name.replace(/ /g, '_')
      const url = new URL(this.urlSingUp)
      const params: Object = {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
        utm_partner: this.utm_partner
      }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
      this.urlSingUp = url.toString()
      this.urlFormart = await this.getShortURL(this.urlSingUp)
    },
    async getShortURL (urlSingUp: string): Promise<string> {
      const response = await ShortenerUseCases.shortenUrl(this.shortenerController, urlSingUp)
      return response
    },
    copyToClipboard (): void {
      navigator.clipboard.writeText(this.urlSingUp).then(() => {
        EventBus.$emit('toast', 'success', this.$t('UrlCopied'))
      }).catch(() => {
        EventBus.$emit('toast', 'error', this.$t('errorCopying'))
      })
    }
  }
}
