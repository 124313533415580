import { EventBus } from '@/eventBus'
import { mapState } from 'vuex'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import ProductController from '../../controllers/Product.controller'
import { Catalog } from '@/modules/microsites/domain/Catalog'
import CatalogUseCases from '@/modules/microsites/application/CatalogUseCases'
import { dataToUpdateCatalog } from '@/modules/microsites/domain/CatalogController'
import { Product } from '@/modules/catalog/domain/models/Product'
import CatalogController from '@/modules/microsites/infrastructure/controllers/Catalog.controller'

// components
import Draggable from 'vuedraggable'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBExpansionPanel from '@/shared/infrastructure/ui/menus/KBExpansionPanel.vue'
import CatalogProduct from '@/modules/catalog/infrastructure/ui/CatalogProduct/CatalogProduct.vue'
import { Category } from '@/modules/catalog/domain/models/Category'
import CategoryUseCases from '@/modules/catalog/application/CategoryUseCases'
import CategoryController from '../../controllers/Category.controller'
import { dataToUpdateCategory } from '@/modules/catalog/domain/models/CategoryController'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'

export default {
  components: {
    // KBCheck,
    Draggable,
    KBButton,
    CatalogProduct,
    KBExpansionPanel,
    KeybeAutocomplete
  },
  data () {
    return {
      productController: null,
      catalogController: null,
      deleting: '',
      productsId: [],
      dragging: true,
      draggedItem: '',
      selectedCategory: {},
      categoryController: new CategoryController(),
      activityLogsController: new ActivityLogsController(),
      CDPFieldsEnums
    }
  },
  created () {
    this.productController = new ProductController()
    this.catalogController = new CatalogController()
  },
  beforeDestroy () {
    this.productController = null
  },
  computed: {
    ...mapState('UserStore', ['token', 'user']),
    ...mapState('AppStore', ['selectedApp'])
  },
  methods: {
    setCategory (): void {
      this.$emit('selectedCategory', this.selectedCategory)
    },
    categoryId (cat) {
      return cat?.id || ''
    },
    categoryName (cat) {
      return cat?.category || ''
    },
    productCategory (product) {
      return product?.category || ''
    },
    async updateCatalog () {
      const catalog: Catalog = await CatalogUseCases.getCatalog(this.catalogController, {
        token: this.token,
        appUUID: this.selectedApp?.uuid
      })
      const data: dataToUpdateCatalog = {
        token: this.token,
        data: {
          appUUID: this.selectedApp?.uuid,
          microsite: catalog?.microsite || false,
          whatsapp: catalog?.whatsapp || false,
          products: this.productsId,
          published: this.productsId.length > 0 || false,
          id: catalog?.id
        }
      }

      await CatalogUseCases.updateCatalog(this.catalogController, data)
    },
    async deleteProduct (product) {
      this.deleting = product?.id
      const deleted = await ProductUseCases.deleteProduct(this.productController, {
        appUUID: this.selectedApp?.uuid,
        id: product?.id,
        token: this.token
      })
      if (!deleted) {
        EventBus.$emit('toast', 'error', this.$t('errorDeletingProduct'))
        return
      }
      this.productsId = this.productsId.filter((id: string) => id !== product?.id)
      await this.writeActivityLog(product)
      await this.updateCatalog()
      this.$emit('refresh')
      this.deleting = false
    },
    async writeActivityLog (product): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.CATALOG_PRODUCT_DELETE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: product.product,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    startDrag (e: any): void {
      this.dragging = true
      this.draggedItem = e?.item?.id
    },
    async saveOrder (e): Promise<void> {
      let firstInOrder = false
      if (e.newIndex === 0) firstInOrder = true
      // eslint-disable-next-line no-undef
      const newCategories = structuredClone(this.categories)
      const foundIndex: number = newCategories.map((category: Category) => category.id).indexOf(this.draggedItem)
      const currentItem: Category = newCategories[foundIndex]
      const nextId: string = newCategories[foundIndex + 1].id
      const data: dataToUpdateCategory = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          category: currentItem.category,
          id: this.draggedItem,
          next: nextId,
          firstInOrder
        }
      }
      await CategoryUseCases.updateCategory(this.categoryController, data)
      this.$emit('refresh')
    }
  },
  props: {
    products: {
      type: Array
    },
    categories: {
      type: Array
    }
  },
  watch: {
    products: {
      immediate: true,
      handler (products) {
        this.productsId = products.map((product: Product) => product.id)
      }
    },
    categories: {
      immediate: true,
      handler (categories) {
        this.selectedCategory = categories[0] || {}
      }
    }
  }
}
