import { mapGetters, mapState, mapActions } from 'vuex'
import Colors from '@/utils/Colors'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { debounce } from 'lodash'
import UserService from '@/services/UserService'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import { ExtradataType } from '@/components/empathize/enums/extradataTypes'
import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
import { countriesGetter, dataToGetCountries, timezonesGetter } from '@/shared/infrastructure/ui/details/sections/Location/domain/LocationController'
import { Country } from '@/shared/infrastructure/ui/details/sections/Location/domain/Location'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import { appUpdater, dataToUpdateApp } from '@/modules/Apps/domain/AppsController'
import EmitToast from '@/utils/EmitToast'
import { EventBus } from '@/eventBus'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { currencyType } from '@/components/configuration/Billing/CurrencyEnum'
import { App } from '@/modules/Apps/domain/Apps'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'

import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { Languages } from '@/components/shared/enums/Languages'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import AdviserStatusController from '@/modules/conversations/infrastructure/controllers/AdviserStatus.controller'
import AuthService from '@/services/AuthService'
import link from '@/utils/links'
interface Field {
  name: string,
  input: any,
  value: string,
  type: ExtradataType,
  options?: string[],
}
export default {
  components: {
    KeybeAvatar,
    KeybeIcon,
    KBIconButton,
    SignInOnboardingTutorialBox,
    KeybeButton,
    KeybeText
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    ...mapGetters('AppStore', ['getCurrencyApp']),
    ...mapGetters('UserStore', ['isAgent']),
    appCategory (): string {
      const categories: string[] = this.selectedApp?.categories.map((category: string) => this.$t(category)) || []

      return categories?.length > 0 ? `KB:SYS ${categories?.toString()}` : ''
    },
    appName (): string {
      return this.selectedApp?.name || ''
    },
    appEmail (): string {
      return this.selectedApp?.email || ''
    },
    appCountryCode (): string {
      return this.selectedApp?.defaultCountryCode || ''
    },
    fields () {
      const items: Field[] = [
        {
          name: this.$t(CDPFieldsEnums.NAME),
          input: KeybeText,
          value: CDPFieldsEnums.NAME,
          type: ExtradataType.Text
        },
        {
          name: this.$t('email'),
          input: KeybeText,
          value: 'email',
          type: ExtradataType.Email
        },
        {
          name: this.$t(CDPFieldsEnums.DEFAULT_COUNTRY_CODE),
          input: KeybeAutocomplete,
          value: CDPFieldsEnums.DEFAULT_COUNTRY_CODE,
          type: ExtradataType.Select,
          options: this.countryCodes
        },
        {
          name: this.$t(CDPFieldsEnums.COUNTRY),
          input: KeybeAutocomplete,
          value: CDPFieldsEnums.COUNTRY,
          type: ExtradataType.Select,
          options: this.countries
        },
        {
          name: this.$t(CDPFieldsEnums.TIME_ZONE),
          input: KeybeAutocomplete,
          value: CDPFieldsEnums.TIME_ZONE,
          type: ExtradataType.Select,
          options: this.timezones
        },
        {
          name: this.$t('currencyConfig'),
          input: KeybeAutocomplete,
          value: CDPFieldsEnums.CURRENCY,
          type: ExtradataType.Select,
          options: this.currency
        },
        {
          name: this.$t(CDPFieldsEnums.LANGUAGE),
          input: KeybeSelect,
          value: CDPFieldsEnums.LANG,
          type: ExtradataType.Select,
          options: this.languages
        }
      ]
      return items
    },
    languages (): { name: string, value: string }[] {
      return [
        { name: this.$t(Languages.EN), value: Languages.EN },
        { name: this.$t(Languages.ES), value: Languages.ES },
        { name: this.$t(Languages.PT), value: Languages.PT }
      ]
    },
    fieldsFilled (): boolean {
      return this.app.name && this.app.email && this.app.defaultCountryCode && this.app.country && this.app.timeZone
    },
    isOwner (): boolean {
      return AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    this.app = structuredClone(this.selectedApp)
    this.countryCodeSearch = this.app?.defaultCountryCode ? `+${this.app.defaultCountryCode}` : ''
    this.countrySearch = this.app?.country || ''
    this.getCountries()
    this.getCountryCodes()
    this.getTimezones()
    this.getOnboardingStep()
  },
  data () {
    return {
      app: {},
      rerender: 0,
      onboardingStep: null,
      currency: currencyType,
      countries: [],
      countryCodes: [],
      timezones: [],
      countrySearch: '',
      countryCodeSearch: '',
      showTutorial: true,
      updatedValue: {},
      countryPage: 1,
      countryCodePage: 1,
      loadingCountries: false,
      loadingCountryCodes: false,
      locationController: new LocationController(),
      appsController: new AppsController(),
      onboardingController: new OnboardingController(),
      activityLogsController: new ActivityLogsController(),
      Colors,
      KeybeIconType,
      showInactiveReasonInput: false,
      inactiveReason: '',
      loadingInactive: false
    }
  },
  methods: {
    ...mapActions('UserStore', ['logout']),
    ...mapActions('LayoutStore', ['setListBoxWidth']),
    toggleInaciveReasonInput (): void {
      this.showInactiveReasonInput = !this.showInactiveReasonInput
    },
    isLoading (field: string): boolean {
      switch (field) {
        case CDPFieldsEnums.COUNTRY:
          return this.loadingCountries
        case CDPFieldsEnums.DEFAULT_COUNTRY_CODE:
          return this.loadingCountryCodes
        default:
          return false
      }
    },
    isCurrency (item) {
      return CDPFieldsEnums.CURRENCY === item
    },
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      if (this.onboardingStep === 7) {
        this.nextStep()
      }
      this.rerender++
    },
    async nextStep (): Promise<void> {
      // eslint-disable-next-line no-undef
      const newStep: number = structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
    },
    getNext (field: string): void {
      if (this.loadingCountries || this.loadingCountryCodes) return
      switch (field) {
        case CDPFieldsEnums.COUNTRY:
          this.countryPage++
          this.getCountries()
          break
        case CDPFieldsEnums.DEFAULT_COUNTRY_CODE:
          this.countryCodePage++
          this.getCountryCodes()
          break
      }
    },
    async getCountries (): Promise<void> {
      this.loadingCountries = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid,
        query: {
          offset: this.countryPage,
          limit: 20,
          search: this.countrySearch || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        const countries: Country[] = response?.countries
        if (this.countryPage === 1) this.countries = countries
        else this.countries = this.countries.concat(countries)
      }
      this.loadingCountries = false
    },
    async getCountryCodes (): Promise<void> {
      this.loadingCountryCodes = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid,
        query: {
          offset: this.countryCodePage,
          limit: 20,
          search: this.countryCodeSearch || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        const countries: Country[] = response?.countries
        countries.forEach((country: Country) => {
          if (country.phone_code.includes('and')) {
            const codes = country.phone_code.split('and')
            codes.forEach((code: string) => {
              const newCode = code.trim().replaceAll('+', '')
              this.countryCodes.push({
                name: country.name + ' +' + newCode,
                code: newCode
              })
            })
          } else {
            this.countryCodes.push({
              name: country.name + `${country.phone_code.includes('+') ? ' ' : ' +'}` + country.phone_code,
              code: country.phone_code
            })
          }
        })
      }
      this.loadingCountryCodes = false
    },
    setSearch: debounce(function (e: string, field: string): void {
      if (field === CDPFieldsEnums.CURRENCY) {
        return
      }
      if (field === CDPFieldsEnums.COUNTRY) {
        if (!e) this.app.country = ''
        this.countryPage = 1
        this.countrySearch = e
        this.getCountries()
      } else if (field === CDPFieldsEnums.DEFAULT_COUNTRY_CODE) {
        if (!e) this.app.defaultCountryCode = ''
        this.countryCodePage = 1
        this.countryCodeSearch = e
        this.getCountryCodes()
      }
    }, 100),
    async getTimezones (): Promise<void> {
      const response: timezonesGetter = await LocationUseCases.getTimezones(this.locationController)
      if (response?.status) {
        const timezones: string[] = response?.timezones
        this.timezones = timezones
      }
      this.rerender++
    },
    itemText (item: Field): string {
      return item.value === CDPFieldsEnums.TIME_ZONE ? '' : CDPFieldsEnums.NAME
    },
    itemValue (item: Field): string {
      switch (item.value) {
        case CDPFieldsEnums.DEFAULT_COUNTRY_CODE:
          return CDPFieldsEnums.CODE
        case CDPFieldsEnums.COUNTRY:
          return CDPFieldsEnums.NAME
        case CDPFieldsEnums.TIME_ZONE:
          return ''
        case CDPFieldsEnums.LANG:
          return CDPFieldsEnums.VALUE
      }
    },
    autosearch (item: Field): string {
      if (item.value === CDPFieldsEnums.CURRENCY) {
        return this.getCurrencyApp
      }
      if (item.value === CDPFieldsEnums.TIME_ZONE) return this.app.timeZone || ''
      else if (item.value === CDPFieldsEnums.DEFAULT_COUNTRY_CODE) {
        return this.countryCodeSearch
      } else {
        return this.countrySearch
      }
    },
    openFileInput (): void {
      if (!this.isUserReadonly) this.$refs.imageInput.click()
    },
    async onFilePicked (event): Promise<void> {
      const img = event.target.files[0]
      const response: GenericResponse = await UserService.uploadImage(img)
      if (response.status) {
        this.app.photo = response?.data?.data?.info?.location || ''
        this.saveAppInformation()
        this.rerender++
      }
    },
    setData: debounce(function (item: Field, e: any): void {
      if (item.value === CDPFieldsEnums.CURRENCY) {
        this.saveCurrency(e)
        return
      }
      this.updatedValue = {
        field: item.value,
        newValue: e,
        oldValue: this.selectedApp[item.value]
      }
      this.saveAppInformation()
    }, 2000),
    async saveAppInformation (): Promise<void> {
      try {
        const data = {
          app: this.app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
          this.updatedValue = {}
        } else {
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
          this.writeActivityLog()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.BRAND,
          action: ActivityActionTypeEnum.APP_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: this.updatedValue
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async updateAppInactivation (): Promise<void> {
      try {
        this.loadingInactive = true
        const data: dataToUpdateApp = {
          app: {
            uuid: this.app.uuid,
            inactive: true,
            inactive_reason: this.inactiveReason
          },
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
          this.app.inactive = false
          this.app.inactive_reason = ''
          this.updatedValue = {}
        } else {
          EventBus.$emit('toast', 'success', this.$t('appUpdated'))
          this.writeActivityLog()
          this._logout()
        }
        this.loadingInactive = false
      } catch (e) {
        console.error(e)
        this.loadingInactive = false
      }
    },
    async inactiveAndExit (): Promise<void> {
      this.app.inactive = true
      this.app.inactive_reason = this.inactiveReason
      await this.updateAppInactivation()
    },
    async _logout (): Promise<void> {
      await PushNotificationsController.setOneSignalStatus({ active: false })
      await AdviserStatusController.inactiveIfIsAdviser(this.isAgent)
      await AuthService.logout()
      this.setListBoxWidth(null)
      this.logout()
      await this.$router.push(link.signin)
    },
    async saveCurrency (currency) {
      if (!currency.code) return
      try {
        // eslint-disable-next-line no-undef
        const app: App = structuredClone(this.selectedApp)
        if (!app.additionalData) app.additionalData = []
        const newData = {
          field: 'app_currency',
          value: currency.code
        }
        const index = app.additionalData.findIndex(obj => obj.field === 'app_currency')
        if (newData.value === null || newData.value === '') {
          // Si newObj no tiene data, eliminar el objeto si existe
          if (index !== -1) {
            app.additionalData.splice(index, 1)
          }
        } else if (index !== -1) {
          app.additionalData[index].value = newData.value
        } else {
          app.additionalData.push(newData)
        }
        const data: dataToUpdateApp = {
          app: app,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
          return
        }
        EventBus.$emit('toast', 'success', this.$t('appUpdated'))
        this.writeActivityLog()
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    fieldsFilled () {
      if (this.fieldsFilled && this.onboardingStep === 8) {
        this.nextStep()
        EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.TEAM)
        EventBus.$emit('openInviteTeam--team-config')
      }
    }
  }
}
