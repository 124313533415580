import { Room } from '@/modules/conversations/domain/models/Room/Room'
import { ITemplatesController, TemplateCreated, dataToGetTemplates, dataToSendTemplates, dataToValidateUserGuest } from '../domain/TemplateController'
import { Template } from '../domain/Template'

export default class TemplateUseCases {
  static validate (controller: ITemplatesController, room: Room, userId: string): boolean {
    return controller.validate(room, userId)
  }

  static validateUserGuest (controller: ITemplatesController, data: dataToValidateUserGuest): Promise<Room> {
    return controller.validateUserGuest(data)
  }

  static getTemplates (controller: ITemplatesController, data: dataToGetTemplates): Promise<Template[]> {
    return controller.getTemplates(data)
  }

  static sendTemplate (controller: ITemplatesController, data: dataToSendTemplates): Promise<TemplateCreated> {
    return controller.sendTemplate(data)
  }
}
