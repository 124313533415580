import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import { dataToGetCountries } from '@/shared/infrastructure/ui/details/sections/Location/domain/LocationController'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
import { Country } from '@/shared/infrastructure/ui/details/sections/Location/domain/Location'
import { dataToParsePhone, defaultCountryGetter } from '@/modules/DesignSystem/domain/controllers/KeybePhoneController'
import KeybePhoneUseCases from '@/modules/DesignSystem/application/KeybePhoneUseCases'
import KeybePhoneController from '../../controllers/KeybePhone.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    placeholder: {
      type: String
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    itemStyle (): string {
      return this.$vuetify.theme.dark
        ? `background-color: ${Colors.morningCoffee}; border-color: ${Colors.grey}`
        : `background-color: ${Colors.white}; border-color: ${Colors.light4}`
    },
    selectedCountryClass (): string {
      return this.phone.code?.toLowerCase()
    },
    phoneInvalid (): boolean {
      return this.phone.phone && !this.phone.valid
    }
  },
  mounted () {
    this.getCountries()
    this.getDefaultCountry()
    if (this.value) {
      this.setPhoneValue(this.value)
    }
  },
  data () {
    return {
      phone: {
        code: '',
        number: '',
        phone: '',
        countryName: '',
        valid: false
      },
      countrySearch: '',
      countries: [],
      locationController: new LocationController(),
      keybePhoneController: new KeybePhoneController(),
      menu: false,
      loading: false,
      Colors
    }
  },
  methods: {
    checkPrevent (e: any): void {
      if (e?.key === '+') {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    async getDefaultCountry () {
      const response: defaultCountryGetter = await KeybePhoneUseCases.getDefaultCountry(this.keybePhoneController)
      this.phone.code = response.code
      this.phone.number = response.number
      this.phone.countryName = response.country
    },
    async getCountries () {
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 50,
          offset: 0,
          search: this.countrySearch
        }
      }
      const response = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        this.countries = response?.countries
      }
    },
    selectCountry (country: Country): void {
      this.phone.code = country.iso2
      this.phone.number = country.phone_code
      this.phone.countryName = country.name
      this.menu = false
      this.countrySearch = ''
    },
    countryLabel (country: Country): string {
      if (country?.phone_code.includes('+')) return `(${country?.phone_code}) ${country.name}`
      else return `(+${country?.phone_code}) ${country.name}`
    },
    countryClass (country: Country): string {
      return country?.iso2?.toLowerCase()
    },
    async setPhoneValue (e: string) {
      if (!this.loading) {
        this.phone = await KeybePhoneUseCases.setPhoneFromString(this.keybePhoneController, e)
      }
    },
    async setPhoneNumber (e: any): Promise<void> {
      if (!this.loading) {
        const inputValue = e?.target?.value || null
        const data: dataToParsePhone = {
          phone: inputValue,
          currentPhone: this.phone
        }
        this.phone = await KeybePhoneUseCases.parsePhone(this.keybePhoneController, data)
      }
      if (this.phone.phone) this.$emit('input', this.phone)
    },
    async setPhonePaste (e: any) {
      if (!this.loading) {
        this.loading = true
        const phone: string = (e.clipboardData).getData(CDPFieldsEnums.TEXT).replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
        const data: dataToParsePhone = {
          phone: phone,
          currentPhone: this.phone
        }
        this.phone = await KeybePhoneUseCases.parsePhone(this.keybePhoneController, data)
        this.loading = false
      }
      if (this.phone.phone) this.$emit('input', this.phone)
    }
  },
  watch: {
    value (e: string) {
      if (e !== this.phone.phone) this.setPhoneValue(e)
    }
  }
}
