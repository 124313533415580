import {
  IContactsController,
  contactCreator,
  contactDeletor,
  contactGetter,
  contactsGetter,
  contactsUpdater,
  dataToCreateContact,
  dataToCreateTransactionalData,
  dataToDeleteContact,
  dataToGetContact,
  dataToGetContacts,
  dataToUpdateContact,
  dataToGetTransactionalData,
  dataToUpdateTransactionalData,
  transactionalCreator,
  transactionalGetter,
  dataToGetContactRoom,
  dataToBlacklistContact,
  contactBlacklister,
  dataToValidatePhone,
  dataToGetVerificationLogs,
  verificationLogsGetter,
  dataToGetContactLists,
  listsGetter,
  dataToMergeContacts,
  contactMerge,
  dataToGetHabeasDataPDF
} from '@/modules/CDP/shared/contacts/domain/ContactsController'
import { Paginator } from '../domain/Contacts'
import { Contact } from '../domain/Contact'

export default class ContactsUseCases {
  static abortAll (controller: IContactsController) : void {
    controller.abortAll()
  }

  static canAskMorePeople (
    controller: IContactsController,
    data: Contact[],
    paginator: Paginator,
    offset: number
  ): boolean {
    return controller.canAskMorePeople(data, paginator, offset)
  }

  static getContacts (controller: IContactsController, data: dataToGetContacts) : Promise<contactsGetter> {
    return controller.getContacts(data)
  }

  static getContactsElastic (controller: IContactsController, data: dataToGetContacts) : Promise<contactsGetter> {
    return controller.getContactsElastic(data)
  }

  static updateContact (controller: IContactsController, data: dataToUpdateContact) : Promise<contactsUpdater> {
    return controller.updateContact(data)
  }

  static getContact (controller: IContactsController, data: dataToGetContact) : Promise<contactGetter> {
    return controller.getContact(data)
  }

  static deleteContact (controller: IContactsController, data: dataToDeleteContact) : Promise<contactDeletor> {
    return controller.deleteContact(data)
  }

  static createContact (controller: IContactsController, data: dataToCreateContact) : Promise<contactCreator> {
    return controller.createContact(data)
  }

  static getContactRoom (controller: IContactsController, data: dataToGetContactRoom) : Promise<any> {
    return controller.getContactRoom(data)
  }

  static getTransactionalData (controller: IContactsController, data: dataToGetTransactionalData) : Promise<transactionalGetter> {
    return controller.getTransactionalData(data)
  }

  static createTransactionalData (controller: IContactsController, data: dataToCreateTransactionalData) : Promise<transactionalCreator> {
    return controller.createTransactionalData(data)
  }

  static updateTransactionalData (controller: IContactsController, data: dataToUpdateTransactionalData) : Promise<transactionalCreator> {
    return controller.updateTransactionalData(data)
  }

  static blacklistContact (controller: IContactsController, data: dataToBlacklistContact) : Promise<contactBlacklister> {
    return controller.blacklistContact(data)
  }

  static validatePhone (controller: IContactsController, data: dataToValidatePhone) : Promise<boolean> {
    return controller.validatePhone(data)
  }

  static getVerificationLogs (controller: IContactsController, data: dataToGetVerificationLogs) : Promise<verificationLogsGetter> {
    return controller.getVerificationLogs(data)
  }

  static getContactLists (controller: IContactsController, data: dataToGetContactLists) : Promise<listsGetter> {
    return controller.getContactLists(data)
  }

  static mergeContacts (controller: IContactsController, data: dataToMergeContacts) : Promise<contactMerge> {
    return controller.mergeContacts(data)
  }

  static getHabeasDataPDF (controller: IContactsController, data: dataToGetHabeasDataPDF) : Promise<contactsGetter> {
    return controller.getHabeasDataPDF(data)
  }
}
