<template>

  <div
    class="KBExpansionPanel"
    :class="{ 'KBExpansionPanel--elevated': elevated }">
    <div class="KBExpansionPanel__header handle"
         :class="{ 'KBExpansionPanel__header--elevated--dark': $vuetify.theme.dark,
                   'KBExpansionPanel__header--elevated': elevated,
                   'KBExpansionPanel__header--open': open,
                   'KBExpansionPanel__header--open--elevated': open && elevated,
                   'KBExpansionPanel__header--open-dark': open && $vuetify.theme.dark,
                   'KBExpansionPanel__header--open-dark--elevated': open && $vuetify.theme.dark && elevated,
                   'KBExpansionPanel__header--outlined': outlinedClass }"
         @click="openPanel"
         :style="{ background: headerBackgroundColor, marginTop: marginTop, borderTop: borderTop, borderRadius: borderRadius, height: height, marginBottom: marginBottom }">
      <slot name="title"></slot>
      <span
        class="mdi mdi-menu-down"
        v-if="showSelector"/>
    </div>
    <div class="KBExpansionPanel__content"
         :class="{ 'KBExpansionPanel__content--open': open,
                   'KBExpansionPanel__content--open--elevated': open && elevated,
                   'KBExpansionPanel__content--open-dark': open && $vuetify.theme.dark,
                   'KBExpansionPanel__content--open-dark--elevated': open && $vuetify.theme.dark && elevated }"
         :style="{ background: contentBackgroundColor,position: position, zIndex: zIndex, overflow: overflow, height: heightContent, borderRadius: borderRadius}">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    forceOpen: {
      type: Boolean
    },
    forceClose: {
      type: Boolean
    },
    elevated: {
      type: Boolean
    },
    showSelector: {
      type: Boolean,
      default: true
    },
    headerBackgroundColor: {
      type: String
    },
    contentBackgroundColor: {
      type: String
    },
    borderTop: {
      type: String
    },
    marginTop: {
      type: String
    },
    borderRadius: {
      type: String
    },
    outlinedClass: {
      type: Boolean,
      default: false
    },
    height: {
      type: String
    },
    marginBottom: {
      type: String
    },
    zIndex: {
      type: String
    },
    position: {
      type: String
    },
    overflow: {
      type: String
    },
    heightContent: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  mounted () {
    if (this.forceOpen) this.open = true
    if (this.forceClose) this.open = false
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    openPanel () {
      if (!this.disabled) {
        this.open = !this.open
        this.$emit('open')
      }
    }
  },
  watch: {
    forceOpen () {
      if (this.forceOpen) this.open = true
    },
    forceClose () {
      if (this.forceClose) this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.KBExpansionPanel {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 30px;
    cursor: pointer;
    transition: all 0.4s ease;
    border-top: 0.5px solid rgba(182, 190, 199, 0.2);

    &--elevated {
      border-radius: 5px;
      border-top: none;
      background: #fff;
      height: 44px;
      padding: 0 15px;
      &--dark {
        background: #0e0e0e ;
        &:hover {
          background: #0e0e0e ;
        }
      }
      &:hover {
        background: #F8F9FB;
      }
    }
  }
  &__header--outlined {
    border: 1px solid $kb-green;
  }
  &__header--open {
    background: rgba(182, 190, 199, 0.18);
    &--elevated {
      border-radius: 5px 5px 0 0;
      background: #F8F9FB;
    }
  }
  &__header--open-dark {
    background: rgb(0 207 228 / 10%) ;
    &--elevated {
      background: #0e0e0e ;
    }
  }
  &__header:hover {
    background: rgba(182, 190, 199, 0.2);
  }
  &__content {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height height 0.3s ease-in-out;
  }
  &__content--open {
    max-height: 9999999px;
    padding-top: 10px;
    padding-bottom: 20px;
    background: rgba(182, 190, 199, 0.18);
    &--elevated {
      border-radius: 0 0 5px 5px;
      background: #F8F9FB ;
    }
  }
  &__content--open-dark {
    background: rgb(0 207 228 / 10%) ;
    &--elevated {
      background: #0e0e0e ;
    }
  }
  &__content--closed {
    height: 0;
  }

  &--elevated {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
  }
}
</style>
