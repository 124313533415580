import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'
import { Product, ProductCreate, RelatedProducts } from './Product'
import { TokenModel } from '@/shared/domain/TokenModel'

export enum Paginator {
  NEXT = 'next',
  PREV = 'prev'
}
export interface dataToGetProducts {
  token: string;
  appUUID: string;
  limit?: number;
  get?: Paginator.NEXT | Paginator.PREV;
  product?: string;
  products?: Product[];
  category?: string;
  getUncategorized?: boolean;
}

export interface dataToGetProductsPaginator {
  token: string;
  appUUID: string;
  limit?: number;
  category?: string,
  getUncategorized?: boolean,
  nextProduct?: {
    sk: string,
    gsi1sk: string,
    pk: string,
    gsi1pk: string
  };
  prevProduct?: {
    gsi1pk: string,
    gsi1sk: string,
    pk: string,
    sk: string
  };
  product?: string;
}

export interface ProductDTOObject extends ProductCreate {
  appUUID: string,
  category: string[],
  product: string,
  checkColor: boolean,
  description: string,
  currency: CurrencyEnum,
  price: number,
  relatedProducts?: RelatedProducts[]
}

export interface productDTO {
  token: string;
  product: ProductDTOObject
}

export interface dataToDeleteProduct {
  id: string;
  token: string;
  appUUID: string;
}

export interface dataToChangeProductStatus {
  id: string;
  status: boolean;
  token: string;
  appUUID: string;
}

export interface dataToSyncWithShopify extends TokenModel {
  appUUID: string;
  shopifyDomain: string;
  apiKey: string;
  updateIfExists: boolean;
  onlyActive: boolean;
}

export interface SyncWithShopifyGetter {
  status: boolean;
  message: string;
}

export interface productGetter {
  products: Product[];
  hasNext?: boolean;
  hasPrev?: boolean;
  status?: boolean;
}

export interface dataToGetProductVariations extends TokenModel {
  productId: string;
  appUUID: string;
}

export interface dataToGenerateLongDescription extends TokenModel {
  longDescription: string,
  appId: string,
  productId: string
}
export interface IProductController {
  getProducts(data: dataToGetProducts): Promise<productGetter>;
  getProductVariations(data: dataToGetProductVariations): Promise<Product>;
  createProduct(data: productDTO): Promise<Product>;
  updateProduct(data: productDTO): Promise<Product>;
  deleteProduct(data: dataToDeleteProduct): Promise<boolean>;
  changeProductStatus(data: dataToChangeProductStatus): Promise<Product>;
  syncWithShopify(data: dataToSyncWithShopify): Promise<SyncWithShopifyGetter>;
  generateLongDescription(data: dataToGenerateLongDescription): Promise<SyncWithShopifyGetter>;
  abortAll: () => void;
}
