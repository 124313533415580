import countries from '@/utils/countries'
import { IKeybePhoneController, dataToParsePhone, defaultCountryGetter, formattedPhone } from '../../domain/controllers/KeybePhoneController'
import store from '@/store'
import timezoneWithCountry from '@/utils/timezoneWithCountry'
import ContactsUseCases from '@/modules/CDP/shared/contacts/application/ContactsUseCases'
import ContactsController from '@/modules/CDP/shared/contacts/infrastructure/controllers/Contacts.controller'
import { dataToValidatePhone } from '@/modules/CDP/shared/contacts/domain/ContactsController'
import { parsePhoneNumber } from 'libphonenumber-js'
import { KeybePhoneSpecialCountriesEnum } from '@/modules/DesignSystem/domain/models/KeybePhoneSpecialCountriesEnum'
export default class KeybePhoneController implements IKeybePhoneController {
  getDefaultCountry () : defaultCountryGetter {
    const defaultCountryCode: string = store.getters['AppStore/getSelectedApp']?.defaultCountryCode

    if (defaultCountryCode) {
      const country = countries.find((country: {
        country: string,
        code: string,
        number: string,
        mask: string
      }) => country.number === defaultCountryCode)
      return {
        code: country?.code.toUpperCase() || 'US',
        number: country?.number || '1',
        country: country?.country || 'United States'
      }
    }

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (currentTimezone) {
      let zone: defaultCountryGetter = {
        code: 'US',
        number: '1',
        country: 'United States'
      }
      Object.entries(timezoneWithCountry).forEach(([key, value]: [string, any]) => {
        const found = value.zones.find((timezone: string) => timezone === currentTimezone)
        if (found) {
          const foundCountry = countries.find((country) => country.code.toLowerCase() === value.abbr.toLowerCase())
          zone = {
            code: value?.abbr || '',
            number: foundCountry?.number || '',
            country: value?.name || ''
          }
        }
      })
      return zone
    } else {
      return {
        code: 'US',
        number: '1',
        country: 'United States'
      }
    }
  }

  async setPhoneFromString (phone: string) : Promise<formattedPhone> {
    const contactsController = new ContactsController()
    const data: dataToValidatePhone = {
      phone,
      token: store.getters['UserStore/getToken']
    }
    let formatted: formattedPhone = {
      code: '',
      number: '',
      phone: '',
      countryName: '',
      valid: false
    }
    const valid = await ContactsUseCases.validatePhone(contactsController, data)

    const parsed: {
      countryCallingCode?: string,
      country?: string,
      nationalNumber?: string,
      number?: string,
      carrierCode?: string,
      ext?: string
    } = parsePhoneNumber(phone)
    formatted = {
      code: parsed?.country || '',
      number: parsed?.countryCallingCode || '',
      phone: parsed?.nationalNumber || '',
      countryName: parsed?.country || '',
      valid: valid
    }
    return formatted
  }

  checkIfContainsPlus (phone: string) : boolean {
    return phone && phone.includes('+')
  }

  checkIfContainsCountryCode (data: dataToParsePhone) : boolean {
    const firstCharacters = data.phone.substring(0, data?.currentPhone?.number?.length)
    return firstCharacters === data?.currentPhone?.number || false
  }

  async isPhoneValid (phone: string) : Promise<boolean> {
    const data: dataToValidatePhone = {
      phone,
      token: store.getters['UserStore/getToken']
    }
    const contactsController = new ContactsController()
    const valid: boolean = await ContactsUseCases.validatePhone(contactsController, data)
    return valid
  }

  async parsePhone (data: dataToParsePhone) : Promise<formattedPhone> {
    if (data.phone && data.phone.length > 6) {
      let phone = ''
      if (!this.checkIfContainsPlus(data.phone)) {
        if (this.checkIfContainsCountryCode(data)) {
          phone = `+${data.phone}`
        } else {
          phone = `+${data.currentPhone.number}${data.phone}`
        }
      } else {
        phone = data.phone
      }
      let phoneObject = await this.setPhoneFromString(phone)
      phoneObject = this.validateSpecial(phoneObject)
      return phoneObject
    } else {
      return {
        code: data?.currentPhone?.code || '',
        number: data?.currentPhone?.number || '',
        phone: data?.phone || '',
        countryName: data?.currentPhone?.countryName || '',
        valid: false
      }
    }
  }

  validateSpecial (phone: formattedPhone): formattedPhone {
    // eslint-disable-next-line no-undef
    const newPhone: formattedPhone = structuredClone(phone)
    const specialCountry: string[] = Object.values(KeybePhoneSpecialCountriesEnum)
    const formattedNumber = '+' + newPhone.number + newPhone.phone
    if (newPhone.countryName && specialCountry.includes(newPhone.countryName.toLowerCase()) && formattedNumber.length === 10) {
      if (newPhone.number === '54' || newPhone.number === '52') {
        if (newPhone.number === '54' && formattedNumber.substr(0, 1) !== '9') newPhone.phone = '9' + newPhone.phone
        if (newPhone.number === '52' && formattedNumber.substr(0, 1) !== '1') newPhone.phone = '1' + newPhone.phone
      }
    }
    return newPhone
  }
}
