import { ValueMultiple } from '@/modules/Apps/domain/ExtendedFields'
import { Creator } from '@/modules/Sales/funnels/domain/Funnels'
import { Priority } from '@/modules/Sales/cards/domain/models/Cards'
import { Paginator as gPaginator } from '@/shared/domain/Paginator'
import { Address, PersonalityData } from './Contact'

export enum CDPDetailsButtonsEnum {
  RESPONSIBLE = 'responsible',
  CHAT = 'chat',
  REMINDER = 'reminder',
  SALES = 'sales',
  VERIFY = 'verify',
  CREDIT = 'credit',
  LISTS = 'lists',
}

export enum EntityTypeEnum {
  PEOPLE = 'people',
  BUSINESS = 'business',
  OPPORTUNITY = 'opportunity',
  OFFICE = 'office'
}

export enum TreatmentTypeEnum {
  FORMAL = 'Formal',
  INFORMAL = 'Informal',
  CLOSE = 'Close',
  RESPECTFUL = 'Respectful',
  FRIENDLY = 'Friendly',
  AUTHORITATIVE = 'Authoritative',
  DEMANDING = 'Demanding',
  CORDIAL = 'Cordial',
  COLLABORATIVE = 'Collaborative',
  IMPATIENT = 'Impatient',
  DISCONSEDERATE = 'Disconsederate',
  RUDE = 'Rude'
}

export enum PreferredChannelEnum {
  WHATSAPP = 'Whatsapp',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  WEBCHAT = 'Webchat',
  PHONE = 'Phone',
  EMAIL = 'Email',
  SMS = 'SMS',
  CALENDAR = 'Calendar',
  VIDEO_CALL = 'Video_call',
  IN_PERSON = 'In_person',
}

export type EntityType = EntityTypeEnum.PEOPLE | EntityTypeEnum.BUSINESS | EntityTypeEnum.OPPORTUNITY | EntityTypeEnum.OFFICE;

export type Channel =
'instagram' |
'gmail' |
'linkedin' |
'spotify' |
'facebook' |
'whatsapp' |
'google' |
'other' |
'api' |
'form' |
'auth' |
'email' |
'phone' |
'events' |
'triggers' |
'social' |
'apple' |
'facebookMessenger' |
'facebookAdds' |
'chatWeb' |
'import' |
'vtex' |
'salesforce' |
'outlook' |
'yahoo' |
'twitter' |
'dashboard' |
'wizard' |
'rdStation';

export interface ExtendedData {
  category: string,
  field: string,
  value?: string,
  valueArray: string[],
  valueMultiple: ValueMultiple[][]
}

export interface BankAccount {
  value: string,
  name: string,
  type: string
}

export interface PersonalReference {
  value: string,
  parentage: string,
  telephone: string
}
export interface TransactionalData {
  app: string,
  typeEntity: EntityType,
  contactUUID: string,
  creditScore: string,
  plan: string,
  category: string,
  priority: Priority,
  products: string[],
  abandonedCart: string[],
  orders: string,
  payments: string,
  bankAccount: BankAccount[],
  personalReference: PersonalReference[],
  proofOfAddress: string,
  proofOfBankAccount: string,
  proofOfBusiness: string,
  proofOfLocation: string,
  createdBy: Creator,
  createdAt: string,
  updatedBy: Creator,
  updatedAt: string,
  _id?: string
}

export interface RegisterChannel {
  channel: Channel,
  data: string
}

export interface CreatedAt {
  end: string,
  init: string
}

export interface Paginator extends gPaginator {}

export interface Source {
  utmSource?: string,
  utmMedium?: string,
  utmCampaign?: string,
  utmTerm?: string,
  utmContent?: string,
  utmPartner?: string,
  channel?: string,
  channelOther?: string
}
export interface Filters {
  registerChannel?: string[],
  responsible?: string[],
  createdAt?: CreatedAt,
  authRoles?: string[],
  documentType?: string,
  document?: string,
  taxCode?: string,
  identifier?: string,
  priority?: string,
  taxGroup?: string,
  description?: string,
  disambiguatingDescription?: string,
  alias?: string,
  country?: string,
  province?: string,
  city?: string,
  zone?: string,
  birthDate?: string,
  birthPlaceCountry?: string,
  birthPlaceProvince?: string,
  birthPlaceCity?: string,
  deathDate?: string,
  deathPlaceCountry?: string,
  deathPlaceProvince?: string,
  deathPlaceCity?: string,
  nationality?: string,
  childrenNumber?: string,
  colleage?: string,
  affiliation?: string,
  award?: string,
  duns?: string,
  urlPublic?: string,
  publicSocialmedia?: string,
  establishmentDate?: string,
  establishmentCountry?: string,
  establishmentProvince?: string,
  establishmentCity?: string,
  nps?: string,
  timeZone?: string,
  category?: string,
  subCategory?: string,
  profession?: string[],
  tags?: string[],
  knows?: string[],
  hearAbout?: string,
  preferredStore?: string,
  preferredProduct?: string,
  leadGenId?: string,
  formId?: string,
  source?: Source,
  habeasDataStatus?: string,
  headquarters?: string,
  gender?: string,
  occupations?: string[],
  educationalLevel?: string,
  annualIncome?: string,
  monthlyIncome?: string,
  ethnicity?: string,
  civilStatus?: string,
  religion?: string[],
  socioeconomicStatus?: string,
  preferredLanguage?: string,
  credentials?: string[],
  status?: string[],
  extendedData?: string[],
  personalityData?: PersonalityData,
  funnelStep?: string,
  emailEmpty?: number,
  phoneEmpty?: number,
  inactive?: number,
  inactiveAt?: CreatedAt,
  inactiveReason?: string,
  businessType?: string,
  office?: string,
  business?: string,
  updateStatusForContacts?: boolean,
  isVerify?: 0 | 1,
  address?: Address,
  salesCards?: {
    funnel?: string,
    step?: string,
    responsible?: string[],
    business?: string,
    priority?: string,
    utmValue?: string,
    utmPartner?: string,
    utmSource?: string,
    formId?: string,
    leadGenId?: string,
    quoteStatus?: string,
    products?: string[],
    financing?: string,
    financingDateInit?: string,
    financingDateEnd?: string,
    reason?: string,
    createdAtInit?: string,
    createdAtEnd?: string,
    extendedData?: any[],
    utm?: {
      value?: string,
      source?: string,
      partner?: string
    },
    financingDate?: {
      init?: string,
      end?: string
    }
  }
}
