import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import KBColorPicker from '@/shared/infrastructure/ui/inputs/KBColorPicker.vue'
import FieldFormOptions from './FieldFormOptions/FieldFormOptions.vue'
import DataInputForm from './DataInputForm/DataInputForm.vue'
import KeybeAutocomplete from '@/modules/DesignSystem/infrastructure/components/KeybeAutocomplete/KeybeAutocomplete/KeybeAutocomplete.vue'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'

import {
  typeField,
  sizeTextOptions,
  modeOptions,
  FormField,
  OptionsLocation
} from '@/modules/Config/WidgetTesseract/domain/WidgetTesseract'
import { AuthFormFields } from '@/components/configuration/conversations/AuthFormFields'
export default {
  name: 'FieldsForm',
  components: {
    KeybeSelect,
    KeybeText,
    KBSwitch,
    KBColorPicker,
    KeybeButton,
    KBIconButton,
    KBCheck,
    KeybeAutocomplete,
    FieldFormOptions,
    DataInputForm
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      appsController: new AppsController(),
      modeOptions: [
        { name: this.$t('dark'), value: modeOptions.Dark },
        { name: this.$t('light'), value: modeOptions.Light }
      ],
      sizeTextOptions: [
        { name: this.$t('small'), value: sizeTextOptions.Small },
        { name: this.$t('medium'), value: sizeTextOptions.Medium },
        { name: this.$t('large'), value: sizeTextOptions.Large }
      ],
      formFieldsOptions: [
        {
          id: 0,
          label: this.$t('authName'),
          name: AuthFormFields.Name,
          typeField: typeField.TEXT,
          isRequired: true,
          isCustomizable: false
        },
        {
          id: 1,
          label: this.$t('authEmail'),
          name: AuthFormFields.Email,
          typeField: typeField.EMAIL,
          isRequired: true,
          isCustomizable: false
        },
        {
          id: 2,
          label: this.$t('authPhone'),
          name: AuthFormFields.Phone,
          typeField: typeField.PHONE,
          isRequired: true,
          isCustomizable: false
        }
      ],
      keys: [],
      showOptions: false,
      input: {
        id: '',
        name: '',
        label: '',
        typeField: '',
        category: this.category,
        options: [],
        isRequired: false,
        isCustomizable: true
      },
      KeybeIconType,
      Colors,
      customFields: []
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    formFieldColor: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formFieldColor
      },
      set (value) {
        this.$emit('update:formFieldColor', value)
      }
    },
    formTextColor: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formTextColor
      },
      set (value) {
        this.$emit('update:formTextColor', value)
      }
    },
    formButtonColor: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formButtonColor
      },
      set (value) {
        this.$emit('update:formButtonColor', value)
      }
    },
    formBackgroundColor: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formBackgroundColor
      },
      set (value) {
        this.$emit('update:formBackgroundColor', value)
      }
    },
    formButtonText: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formButtonText
      },
      set (value) {
        this.$emit('update:formButtonText', value)
      }
    },
    formLabelColor: {
      get () {
        return this.dataTocreate?.landingConfig?.desktop?.formLabelColor
      },
      set (value) {
        this.$emit('update:formLabelColor', value)
      }
    }
  },
  async mounted () {
    this.initialFields()
  },
  methods: {
    initialFields (): void {
      this.formFieldsOptions.forEach(option => {
        const customFieldIndex = this.customFields.findIndex(item => item?.id === option?.id)
        if (customFieldIndex === -1) {
          this.customFields.push(option)
        }
      })
      this.updateFormFields()
    },

    updateField (index: number, updatedField: FormField): void {
      this.customFields[index] = updatedField
      this.updateFormFields()
    },
    AddCustomField (): void {
      // eslint-disable-next-line no-undef
      const newInput = structuredClone(this.input)
      const uniqueId = Math.floor(Math.random() * 1000)
      const newField = { ...newInput, id: uniqueId }
      newField.category = this.selectedApp?.categories[0]
      this.customFields.push(newField)
    },
    handleUpdateFieldname (index: number, newName: string): void {
      this.customFields[index].name = newName
      this.updateFormFields()
    },
    handleUpdateFieldType (index: number, newType: string): void {
      this.customFields[index].typeField = newType
      this.updateFormFields()
    },
    handleupdateFieldLabel (index: number, newLabel: string): void {
      this.customFields[index].label = newLabel
      this.updateFormFields()
    },
    handleupdateOptions (index:number, value: Array<any>): void {
      this.customFields[index].options = value
      this.updateFormFields()
    },
    handleupdateFieldRequired (index: number, value: boolean): void {
      this.customFields[index].isRequired = value
      this.updateFormFields()
    },
    handleupdateLocations (index, locations:OptionsLocation): void {
      this.customFields[index].options = locations
      this.updateFormFields()
    },
    removeField (index: number): void {
      this.customFields.splice(index, 1)
      this.updateFormFields()
    },
    updateFormFields (): void {
      this.$emit('updateFormFields', this.customFields)
    }
  }
}
