import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToCreateTeamMember, dataToDeleteTeamMember, dataToGetTeamMember, dataToGetTeamMembers, dataToInviteTeamMember, dataToTransferOwnership, dataToUpdateTeamMember, dataToUpdateUserApps } from '../../domain/TeamController'

export default class AppsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController
  getTeamMembersAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
    this.getTeamMembersAbortController = new AbortController()
  }

  async transferOwnership (data: dataToTransferOwnership): Promise<GenericResponse> {
    const { userIdNew, appId } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/transfer-owner/${appId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: {
        userIdNew: userIdNew
      },
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async getTeamMembers (data: dataToGetTeamMembers): Promise<GenericResponse> {
    const { appId, search } = data
    // eslint-disable-next-line no-undef
    const query = structuredClone(data)
    if (!search) delete query.search
    delete query.token
    delete query.appId
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/list/${appId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      query,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async inviteTeamMember (data: dataToInviteTeamMember): Promise<GenericResponse> {
    const { appId } = data

    const url = `${process.env.VUE_APP_AUTH_SERVICE}invite/${appId}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async createTeamMember (data: dataToCreateTeamMember): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: data.createInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async deleteTeamMember (data: dataToDeleteTeamMember) : Promise<GenericResponse> {
    const { uuid, appId } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${uuid}/app-delete/${appId}`

    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async updateTeamMember (data: dataToUpdateTeamMember): Promise<GenericResponse> {
    const { updateInput, uuid } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${uuid}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: updateInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.put(requestOptions)
  }

  async updateUserApps (data: dataToUpdateUserApps): Promise<GenericResponse> {
    const { appUUID, id, updateInput } = data
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user-app/${appUUID}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: updateInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.patch(requestOptions)
  }

  async getTeamMember (data: dataToGetTeamMember): Promise<GenericResponse> {
    const { id } = data

    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${id}}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetTeamMembers () {
    this.getTeamMembersAbortController.abort()
    this.getTeamMembersAbortController = new AbortController()
  }

  abortAll () {
    this.abortGetSuggestions()
    this.abortGetTeamMembers()
  }
}
