export default class Link {
    static home: string = '/'

    static panel: string = '/panel'

    static signin: string = '/signin'

    static login: string = '/login'

    static assistedSignup: string = '/assisted-signup'

    static signup: string = '/signup'

    static register: string = '/register'

    static resetPassword: string = '/reset-password'

    static gql: string = '/gql'

    static people: string = '/people'

    static person: string = '/connect/person/:id'

    static segments: string = '/connect/segments'

    static segment: string = '/segment/:id'

    static businesses: string = '/businesses'

    static business: string = '/business/:id'

    static editBusiness: string = '/business/edit/:id'

    static forms: string = '/connect/forms'

    static form: string = '/connect/form/:id'

    static formCreate: string = '/connect/form/create'

    static editForm: string = '/connect/form/edit/:id'

    static auth: string = '/connect/authenticator'

    static imports: string = '/connect/imports'

    static sales: string = '/sales'

    static agreements: string = '/connect/agreements'

    static support: string = '/connect/support'

    static conversations: string = '/conversations'

    static campaigns: string = '/campaigns'

    static campaignsList: string = '/campaignsList'

    static campaignDetail: string = '/campaignDetail'

    static templateCreator: string = '/campaigns/templates/template-creator'

    static emailCreator: string = '/campaigns/templates/email-creator'

    static newCampaign: string = '/campaigns/new'

    static templates: string = '/templates'

    static automate: string = '/automate'

    static triggers: string = '/triggers'

    static actions: string = '/triggers/actions'

    static editTrigger: string = '/triggers/actions/edit/:id'

    static trigger: string = '/triggers/trigger/:id'

    static actionsList: string = '/triggers/list'

    static generalConfig: string = '/configurations/general'

    static config: string = '/configuration'

    static appConfig: string = '/configurations/app'

    static adminConfig: string = '/configurations/administrators'

    static billingConfig: string = '/configurations/consumptions'

    static chatConfig: string = '/configurations/chat'

    static rolesConfig: string = '/configurations/roles'

    static journeyConfig: string = '/configurations/journey'

    static domainConfig: string = '/configurations/domain'

    static flowPropertiesConfig: string = '/configurations/flow-properties'

    static instagramChannel: string = '/configurations/channels/instagram'

    static channelConfig: string = '/configurations/channels'

    static newChannelsConfig: string = '/configurations/new-channels'

    static autoassignConfig: string = '/configurations/autoassign'

    static billingConfigs: string = '/configurations/billing'

    static billingHistory: string = '/configurations/billing/history'

    static formPreview: string = '/form-preview'

    static brandsConfig: string = '/configurations/brands'

    static domainsConfig: string = '/configurations/domains'

    static conversationBillingReport: string = '/billing-conversations-report'

    static onboarding: string = '/onboarding'

    static clientDashboard: string = '/dashboard'

    static inviteTeam: string = '/invite-team'

    static invitedWizard: string = '/invited-wizard'

    static extensions: string = '/extensions'

    static instagramMobileConfig: string = '/instagram-mobile-config'

    static facebookMobileConfig: string = '/facebook-mobile-config'

    static extensionPath: string = '/company/:companyUUID/app/:appUUID'

    static extensionPage: string = '/page/:pageId'

    static extensionInstagram: string = '/instagram/:instagramId'

    static customizeAdminPermissions: string = '/customize-admin-permissions'

    static micrositeHome: string = '/microsites'

    static micrositeWelcome: string = '/microsites/welcome'

    static micrositeNew: string = '/microsites/create'

    static micrositeEdit: string = '/microsites/edit'

    static catalogConfig: string = '/configurations/catalog'

    static connectionsConfig: string = '/configutations/logs'

    static activeDevices: string = '/configutations/active'

    static pipeline: string = '/pipeline/:id'

    static events:string = '/events'

    static eventsList:string = '/eventsList'

    static newEvent: string = '/events/new'

    static createEvent: string = '/events/create'

    static resumeEvent: string = '/event/resume/:id'

    static editEvent: string = '/event/edit/:id'

    static statusConfig: string = '/configuration/statuses'

    static notifications: string = '/configutations/notifications'

    static createNotifications: string = '/configutations/notifications/create'

    static editNotifications: string = '/configurations/notifications/edit/:id'

    static tasks: string = '/configutations/tasks'

    static opportunities: string = '/opportunities'

    static funnels: string = '/funnels'

    static payment: string = '/payment'

    static whatsappLink: string = 'https://wa.me/'

    static firstLogin: string = '/first-signin'
}
