var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hasInstagram)?_c('div',[_c('div',[(_vm.pages.length === 0)?_c('facebook-login',{attrs:{"label":_vm.$t('addNewInstagramAccount'),"appId":_vm.instagramAppId,"loginParams":{
          scope: _vm.instagramPermissions.join(','),
          auth_type: 'rerequest',
          enable_profile_selector: true
        },"buttonText":_vm.$t('addNewInstagramAccount')},on:{"gotAccessToken":_vm.getPages}}):_vm._e()],1),(_vm.pages.length > 0)?_c('div',{staticClass:"d-flex align-start flex-column pb-2"},[_c('div',{staticClass:"d-flex align-start flex-column pb-2",staticStyle:{"width":"230px"}},[_c('span',{staticClass:"AutoConnectConfig__item--label mr-5 pb-2"},[_vm._v(_vm._s(_vm.$t('selectPage')))]),_c('KBSmallSelector',{staticStyle:{"border-radius":"5px","border":"1px solid gray"},attrs:{"items":_vm.pages,"itemText":_vm.CDPFieldsEnums.NAME,"seamlessTransparent":true,"return-object":true},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}})],1),_c('KBButton',{staticStyle:{"background-color":"#01cfe4","width":"80%"},attrs:{"text":_vm.$t('continue'),"width":"100px","type":"flat"},nativeOn:{"click":function($event){return _vm.connect()}}})],1):_vm._e()]):_vm._e(),(_vm.hasInstagram)?_c('div',[_c('facebook-login',{attrs:{"tokenPercentage":_vm.tokenPercentage,"appId":_vm.instagramAppId,"loginParams":{
        scope: _vm.instagramPermissions.join(','),
        auth_type: 'reauthorize',
        enable_profile_selector: true
      },"buttonText":_vm.$t('extendConnection'),"extend":true,"type":_vm.ChannelsEnum.INSTAGRAM,"config":_vm.config},on:{"gotAccessToken":_vm.refreshToken,"refresh":function($event){return _vm.$emit('refreshedToken')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }