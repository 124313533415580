import { TokenModel } from '@/shared/domain/TokenModel'
import { IFeesController, commissionByUserGetter, dataToAddUserToBrand, dataToGenerateCommission, dataToGetCommissionByUser, dataToGetInvoices, dataToGetPartnerCategories, dataToGetUserPartners, dataToPayCommissionByUser, dataToRemoveUserFromBrand, invoicesrGetter, partnerCategoriesGetter, userPartnerGetter } from '../../domain/FeesController'
import FeesRepository from '../repository/Fees.repository'
// import { commissionByUserGetter } from '../../domain/models/CommissionByUser'

export default class FeesController implements IFeesController {
  repository: FeesRepository

  constructor () {
    this.repository = new FeesRepository()
  }

  async getUserPartners (data: dataToGetUserPartners): Promise<userPartnerGetter> {
    const response = await this.repository.getUserPartners(data)
    return { data: response?.data, status: response?.status }
  }

  async getPartnerCategories (data: dataToGetPartnerCategories): Promise<partnerCategoriesGetter> {
    const response = await this.repository.getPartnerCategories(data)
    return { data: response?.data, status: response?.status }
  }

  async addUserToBrand (data: dataToAddUserToBrand): Promise<boolean> {
    const response = await this.repository.addUserToBrand(data)
    return response
  }

  async removeUserFromBrand (data: dataToRemoveUserFromBrand): Promise<boolean> {
    const response = await this.repository.removeUserFromBrand(data)
    return response
  }

  async getFeesData (data: TokenModel): Promise<userPartnerGetter> {
    const response = await this.repository.getFeesData(data)
    return { data: response?.data, status: response?.status }
  }

  async getInvoices (data: dataToGetInvoices): Promise<invoicesrGetter> {
    const response = await this.repository.getInvoices(data)
    return { data: response?.data, status: response?.status }
  }

  async getCommissionByUser (data: dataToGetCommissionByUser): Promise<commissionByUserGetter> {
    const response = await this.repository.getCommissionByUser(data)
    return response
  }

  async payCommissionByUser (data: dataToPayCommissionByUser): Promise<commissionByUserGetter> {
    const response = await this.repository.payCommissionByUser(data)
    return response
  }

  async generateCommission (data: dataToGenerateCommission): Promise<commissionByUserGetter> {
    const response = await this.repository.generateCommission(data)
    return response
  }
}
