import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import { UploadFilesService } from '@/components/shared/services/UploadFilesService'
export default {
  name: 'MediaSelector',
  props: ['accept', 'type'],
  data () {
    return {
      uploadProgress: 0,
      Colors,
      url: ''
    }
  },
  methods: {
    async onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) {
        return
      }
      const response = await UploadFilesService.uploadFile({
        totalFiles: [file],
        onUploadProgress: this.updateProgress,
        roomId: 'media_templates'
      })

      if (!response.status) {
        EventBus.$emit('toast', 'error', this.$t('errorUploadingFile'))
        return
      }

      this.uploadProgress = 100

      const url = response.data.data.urls[0]
      this.$emit('fileUploaded', url)
      this.url = url
    },
    pickFile () {
      if (this.uploadProgress > 0 && this.uploadProgress < 100) {
        return
      }
      this.$refs.MediaSelector__input.click()
    },
    updateProgress (progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100 - 4
      )
      if (this.uploadProgress < 0) {
        this.uploadProgress = 0
      }
    }
  },
  computed: {
    showUploadProgress () {
      return this.uploadProgress > 0 && this.uploadProgress < 100
    }
  }
}
