







export default {
  name: 'WarningIcon'
}
