import {
  IBillingController,
  billingDataGetter,
  billingIntentSetupGetter,
  dataToGetIntentSetup,
  dataToGetBillingReport
} from '../domain/BillingController'

export default class BillingUseCase {
  static abort (controller: IBillingController): void {
    controller.abort()
  }

  static async getIntentSetup (
    controller: IBillingController,
    data: dataToGetIntentSetup
  ): Promise<billingIntentSetupGetter> {
    return await controller.getIntentSetup(data)
  }

  static async getBillingReport (
    controller: IBillingController,
    data: dataToGetBillingReport
  ): Promise<billingDataGetter> {
    return await controller.getBillingReport(data)
  }
}
