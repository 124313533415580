import TemplateUseCases from '@/modules/templates/application/TemplateUseCases'
import TemplateController from '@/modules/templates/infrastructure/Template.controller'
import TemplateComposer from '@/modules/templates/infrastructure/ui/TemplateComposer/TemplateComposer.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { mapState } from 'vuex'
export default {
  name: 'Template',
  components: {
    TemplateComposer,
    KeybeSelect
  },
  props: {
    dataTocreate: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('ConversationStore', ['wpProviders', 'inboxes']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token'])
  },
  data () {
    return {
      templateController: new TemplateController(),
      templates: [],
      selectedTemplate: {}
    }
  },
  mounted () {
    this.getTemplates()
  },
  methods: {
    async getTemplates () {
      const userHost = this.dataTocreate?.templateData?.userHost?.userHost || this.wpProviders[0]?.userHost
      this.templates = await TemplateUseCases.getTemplates(
        this.templateController,
        {
          token: this.token,
          appId: this.selectedApp?.uuid,
          userHost: userHost,
          forceRefresh: 0
        }
      )
    },

    onInput (value) {
      this.templateVariables = value
      this.dataTocreate.templateData.templateVariables = this.templateVariables
    },
    onSelectTemplate (template) {
      this.dataTocreate.templateData.templateMessage = template?.templateMessage
      this.dataTocreate.templateData.templateId = template?.templateId
      this.selectedTemplate = template
      this.$emit('update:dataTocreate', this.dataTocreate)
    }
  }
}
