<template>
  <div class="KBCheck"
       @click="select">
    <KeybeIcon style="width: 15px"
               :icon="checked ? KeybeIconType.CHECKED_BOX : KeybeIconType.UNCHECKED_BOX"/>
    <span class="KBCheck__label"
          v-if="itemLabel">{{itemLabel}}</span>
  </div>
</template>
<script>
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
export default {
  components: { KeybeIcon },
  props: ['value', 'itemValue', 'itemLabel', 'disabled'],
  computed: {
    checked () {
      if (this.itemValue) return this.value.includes(this.itemValue)
      else return this.value
    }
  },
  data () {
    return {
      KeybeIconType
    }
  },
  methods: {
    select () {
      if (!this.disabled) {
        let selected = this.value
        if (this.itemValue) {
          if (this.value.includes(this.itemValue)) {
            const index = selected.indexOf(this.itemValue)
            selected.splice(index, 1)
          } else {
            selected.push(this.itemValue)
          }
        } else {
          selected = !selected
        }
        this.$emit('input', selected)
        this.$emit('save', selected)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.KBCheck {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__checkbox {
    width: 13px;
    height: 13px;
    background: #B6BEC7;
    transition: background 0.4s;
  }

  &__label {
    color: #B6BEC7;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
