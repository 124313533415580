import { KeybeTextTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeTextTypeEnum'

export default {
  props: {
    type: {
      type: String,
      default: KeybeTextTypeEnum.TEXT
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.text = this.value
  },
  data () {
    return {
      text: null,
      valid: true
    }
  },
  methods: {
    input (): void {
      if (this.type === KeybeTextTypeEnum.EMAIL) this.validateEmail()
      this.$emit('input', this.text)
    },
    validateEmail (): void {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.valid = this.text.length && re.test(this.text)
      if (this.valid) this.$emit('input', this.text)
    },
    save (): void {
      this.$emit('save', this.text)
    }
  },
  watch: {
    value (val: string) {
      this.text = val
    }
  }
}
