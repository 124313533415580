import { Room, RoomStateEnum } from '@/modules/conversations/domain/models/Room/Room'
import { Template } from '../domain/Template'
import { ITemplatesController, TemplateCreated, dataToGetTemplates, dataToSendTemplates, dataToValidateUserGuest } from '../domain/TemplateController'
import TemplateRepository from './Template.repository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'

export default class TemplateController implements ITemplatesController {
  repository: TemplateRepository

  constructor () {
    this.repository = new TemplateRepository()
  }

  dispose (): void {
    this.repository.dispose()
  }

  validate (room: Room, userId: string): boolean {
    if (!room?._id || room.state !== RoomStateEnum.WITH_ADVISOR) {
      return true
    }

    if (room?.advisorId === userId || room?.adviser?.userId === userId) {
      return true
    }

    return false
  }

  async sendTemplate (data: dataToSendTemplates): Promise<TemplateCreated> {
    const response = await this.repository.sendTemplate(data)
    return response?.data || {}
  }

  async validateUserGuest (data: dataToValidateUserGuest): Promise<Room> {
    const response: GenericResponse = await this.repository.validateUserGuest(data)
    return response?.data?.room || {}
  }

  async getTemplates (data: dataToGetTemplates): Promise<Template[]> {
    const response = await this.repository.getTemplates(data)

    return response?.data?.items || []
  }
}
