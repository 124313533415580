import { IChannelsController, channelDisconnect, dataToDisconnectChannel } from '../domain/ChannelsController'

export default class ChannelsUseCases {
  static abort (controller: IChannelsController) : void {
    controller.abort()
  }

  static async disconnectChannel (controller: IChannelsController, data: dataToDisconnectChannel) : Promise<channelDisconnect> {
    return await controller.disconnectChannel(data)
  }
}
