


































































import TemplateTextEditor from './TemplateTextEditor'

export default TemplateTextEditor
