import { Plan } from '@/modules/consumptions/domain/models/Plan'
import BillingPlansBikyCards from '../BillingPlansBikyCards/BillingPlansBikyCards.vue'
import PlanHeader from '../PlanHeader/PlanHeader.vue'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import { EventBus } from '@/eventBus'
import ClosedIcon from '@/shared/infrastructure/ui/icons/SvgComponents/ClosedIcon.vue'
import { mapState } from 'vuex'

export default {
  name: 'BikyPlanSelector',
  components: {
    BillingPlansBikyCards,
    PlanHeader,
    KBButton,
    ClosedIcon
  },
  props: {
    activePlan: {
      type: String,
      required: true
    },
    bookingPlan: {
      type: String,
      required: true
    },
    plans: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      itemDetail: null,
      openDetail: false,
      open: false,
      keybePlan: {
        title: '',
        cost: ''
      },
      BikyPlan: null,
      BikyColor: null,
      cards: {
        cardEssential: {
          code: 'essential',
          cost: '100',
          color: 'cardEssential',
          active: false
        },
        cardCortex: {
          code: 'cortex',
          cost: '100',
          color: 'cardCortex',
          active: false
        },
        cardTesseract: {
          code: 'tesseract',
          cost: '100',
          color: 'cardTesseract',
          active: false
        },
        cardTesseractPlus: {
          code: 'tesseract-plus',
          cost: '100',
          color: 'cardTesseractPlus',
          active: false
        }
      }
    }
  },
  mounted () {
    this.keybePlan = this.firstPlan
  },
  computed: {
    ...mapState('UserStore', ['isUserReadonly']),
    firstPlan () {
      if (!this.plans) {
        return {
          title: '',
          cost: ''
        }
      }
      return this.plans[1]
    },
    title (): string {
      return this.$t('bikySmartLevel')
    },
    plansList (): Plan[] {
      return this.removeItemByCode(this.plans, 'on_demand')
    },
    color (): string {
      const lists: {
      [key: string]: string
      } = {
        essential: '#75FBD2',
        cortex: '#3AF69B',
        tesseract: '#29494B',
        'tesseract-plus': '#19425F'
      }

      return lists[this.itemDetail]
    },
    descriptionList (): string[] {
      const lists: {
      [key: string]: string[]
      } = {
        essential: [
          this.$t('essentialAutomaticContactCreation'),
          this.$t('essentialAutomaticAssisted'),
          this.$t('essentialAutomaticDetection'),
          this.$t('essentialQuestionsProspecting'),
          this.$t('essentialAutomacticChatsAssignment')
        ],
        cortex: [
          this.$t('cortexIn'),
          this.$t('cortexSentiment'),
          this.$t('cortexAdvanced')
        ],
        tesseract: [
          this.$t('tesseractIn'),
          this.$t('tesseractAutonomous'),
          this.$t('tesseractLearning'),
          this.$t('tesseractAutomatic'),
          this.$t('tesseractConfigure')
        ],
        'tesseract-plus': [
          this.$t('tesseractPlusIn'),
          this.$t('tesseractPlusFoundational'),
          this.$t('tesseractPlusBiky'),
          this.$t('tesseractPlusVoice'),
          this.$t('tesseractPlusCustomize')
        ]
      }

      return lists[this.itemDetail]
    }
  },
  methods: {
    setActiveCard (code) {
      Object.keys(this.cards).forEach(key => {
        this.cards[key].active = this.cards[key].code === code
      })
    },
    seeMore (code) {
      this.openDetail = true
      this.itemDetail = code
    },
    setNewBiky ({ code, color }) {
      if (this.isUserReadonly) return
      this.BikyPlan = code
      this.BikyColor = color
      this.setActiveCard(code)
    },
    goToPay () {
      if (this.isUserReadonly) return
      if (!this.BikyPlan) {
        EventBus.$emit('toast', 'error', this.$t('msgSelectKeybePlanError'))
        return
      }
      this.selectTier(this.BikyPlan)
    },
    setFirstPlan (item) {
      if (this.isUserReadonly) return
      this.keybePlan = item
    },
    removeItemByCode (plans: Plan[], codeToRemove: string): Plan[] {
      if (!plans) return []
      return plans.filter(plan => plan.code !== codeToRemove)
    },
    formatNumberWithDots (input: number): string {
      if (!input) return ''
      const inputAsString = input.toString()

      const reversed = inputAsString.split('').reverse().join('')
      const result = []

      for (let i = 0; i < reversed.length; i += 3) {
        result.push(reversed.substring(i, Math.min(i + 3, reversed.length)))
      }

      return result.join('.').split('').reverse().join('')
    },
    selectTier (tier: string): void {
      this.$emit('selectTier', {
        tier: tier,
        plan: this.keybePlan,
        color: this.BikyColor
      })
    }
  }
}
