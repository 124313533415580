import { render, staticRenderFns } from "./AutoConnectFacebookConfig.vue?vue&type=template&id=4d0e8ee4&scoped=true&"
import script from "./AutoConnectFacebookConfig.vue?vue&type=script&lang=ts&"
export * from "./AutoConnectFacebookConfig.vue?vue&type=script&lang=ts&"
import style0 from "./AutoConnectFacebookConfig.vue?vue&type=style&index=0&id=4d0e8ee4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0e8ee4",
  null
  
)

export default component.exports