export enum typeFormEnum {
    LandingPage = 'Landing Page',
    WhatsappForm = 'Whatsapp Form',
    WebWidget = 'Web Widget'
}

export enum formModeEnum {
    Dark = 'dark',
    Light = 'light'
}

export enum positionOptions {
    Left = 'Left',
    Right = 'Right',
    Center = 'Center',
    Top = 'Top',
    Bottom = 'Bottom'
}

export enum textAlingOptions {
    Left = 'Left',
    Right = 'Right',
    Center = 'Center',
    Top = 'Top',
    Bottom = 'Bottom'
}

export enum sizeTextOptions {
    Small = '12px',
    Medium = '14px',
    Large = '16px'
}

export enum typographyOptions {
    Roboto = 'Roboto',
    Helvetica = 'Helvetica',
    OpenSans = 'Open Sans',
    Arial = 'Arial',
    Poppins = 'Poppins'
}

export enum horizontalPositionOptions {
    Left = 'Left',
    Right = 'Right',
    Center = 'Center'
}

export enum verticalPositionOptions {
    Top = 'Top',
    Bottom = 'Bottom',
    Center = 'Center'
}

export enum modeOptions {
    Dark = 'Dark',
    Light = 'Light'
}

export enum lenguageOptions {
    English = 'English',
    Spanish = 'Spanish'
}

export enum typeField {
    SELECT = 'select',
    TEXT = 'text',
    PHONE = 'phone',
    EMAIL = 'email',
    LOCATION = 'location',
    TEXTTAG = 'textTag'
}
export interface Option {
    label: string,
    value: string,
}

export interface OptionsLocation extends Option {
    id: number,
    state: string,
    country: string,
    city: string
}

export interface FormField {
    id: number;
    typeField?: typeField;
    label?: string;
    name?: string;
    isRequired?: boolean,
    isCustomizable?: boolean,
    options: []
}

interface SalesCardData {
    funelId: string;
    stepId: string;
    description: string;
    amount: number;
}

interface TemplateData {
    templateMessage: string;
    templateId: string;
    userHost: string;
    inbox: string;
}

interface WidgetConfig {
    backgroundColor: string;
    textColor: string;
    textSize: string;
    language: string;
    fieldColor: string;
    buttonColor: string;
    buttonTextColor: string;
    buttonText: string;
}

interface Desktop {
    title: string;
    message: string;
    backgroundColor: string;
    backgroundImage: string;
    titleColor: string;
    messageColor: string;
    titlePosition: string;
    titleTextAlign: string;
    typography: string;
    logo: string;
    logoPositionX: string;
    logoPositionY: string;
    formMode: string;
    formBackgroundColor: string;
    formTextColor: string;
    formFieldColor: string;
    formTextSize: string;
    formButtonColor: string;
    formButtonTextColor: string;
    formButtonText: string;
    formTitle: string;
}

interface Mobile {
    title: string;
    message: string;
    backgroundColor: string;
    backgroundImage: string;
    titleColor: string;
    messageColor: string;
    titlePosition: string;
    titleTextAlign: string;
    typography: string;
    logo: string;
    logoPositionX: string;
    logoPositionY: string;
    formMode: string;
    formBackgroundColor: string;
    formTextColor: string;
    formFieldColor: string;
    formTextSize: string;
    formButtonColor: string;
    formButtonTextColor: string;
    formButtonText: string;
    formTitle: string;
}

interface LandingConfig {
    desktop: Desktop;
    mobile: Mobile;
}

export interface ConfigFormTesseract {
    apiKey: string;
    name: string;
    appUUID: string;
    companyUUID: string;
    active: boolean;
    type: string;
    url: string;
    sourceUTM: string;
    title: string;
    formFields: FormField[];
    salesCardData: SalesCardData;
    templateData: TemplateData;
    widgetConfig: WidgetConfig;
    landingConfig: LandingConfig;
    createdAt: string;
    updatedAt: string;
    script: string;
}

export interface WidgetTesseract {
    formsTesseract: ConfigFormTesseract[]
}
