import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToDisconnectChannel } from '../../domain/ChannelsController'

export default class ChannelsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async disconnectChannel (data: dataToDisconnectChannel): Promise<GenericResponse> {
    const { appUUID, type, id } = data

    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/channels/app/${appUUID}/${type}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }
}
